import React from 'react';

export default class BreakingNews extends React.Component {
    render() {
        return (
            <div className="breaking-news">
                <h3>{this.props.message}</h3>
            </div>
        );
    }
}
