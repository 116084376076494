import React, { Component } from 'react';

import AppContext from '../app/AppContext';
import Settings from '../app/settings';

export class Category extends Component {
    render() {
        const { gameStatus } = this.props;
        const { question } = this.props.gameStatus;

        if (!question) return null;

        const { category } = question;

        const scale = (category && category.size > 0 && category.size < 10) ? category.Size : 1;
        const defaultHeight = 6; // em

        const categoryStyle = {
            backgroundColor: category.color,
        };

        const imgStyle = {
            height: `${defaultHeight * scale}em`
        };

        return (
            <div className="category" style={categoryStyle}>
                {gameStatus.playedQuestionsCount >= 0 && <p>{AppContext.r["question"]} {gameStatus.playedQuestionsCount + 1}</p>}

                <img style={imgStyle}
                    alt={category.category} src={Settings.apiUrl + "/categories/" + category.id + "/image/thumb"} />

                <h1>{category.category}</h1>

                {question.fixedScore > 0 && <span className="fixed-score">{question.fixedScore} {AppContext.r["points"]}</span>}

                {question.type === "Video" && <i className="fas fa-video question-type"></i>}
                {question.type === "Image" && <i className="far fa-image question-type"></i>}
            </div>
        );
    }
}