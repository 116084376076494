import React from 'react';
import { Link } from "react-router-dom";
import Icon from '@mui/material/Icon';
import CheckIcon from '@mui/icons-material/Check';

import Settings from '../../settingsOld';

export default function loadClientResources(resources) {
    resources['tag-line'] = <>IQ Space is the new <b>live quiz</b> that will skyrocket your entertainment business.</>;
    resources["settings"] = "Settings";

    resources['game-hosted-by'] = "This game is hosted by";
    resources['access-the-game'] = "Access the game";
    resources['ready-for-challenge'] = "Ready for a challenge?";
    resources['wanna-change-name'] = "Wanna change game name?";
    resources['whats-game-name'] = "So, what's your game name?";
    resources['more-account-settings'] = "Choose a profile picture and more settings";
    resources['ranking'] = "Leaderboard";
    resources['partial-ranking'] = "Last Question Leaderboard";
    resources['access-instructions'] = "To play the game, access ";
    resources['access-instructions-qr'] = "or scan the QR code.";
    resources['last-events'] = "Last Events";
    resources['upcoming-event'] = "UPCOMING EVENT";
    resources['game-started'] = "GAME STARTED";
    resources['game-completed'] = "GAME COMPLETED";
    resources['new-game-starting-soon'] = "A new game is starting soon.";
    resources['congrats-winners'] = "Congrats Winners";
    resources['join-the-game'] = "JOIN THE GAME";
    resources['game-not-existing'] = "This game does not exist or has not been started yet.";
    resources['event-not-existing'] = "This event does not exist. :/";
    resources['event-not-published'] = "This event is not published and you are just seeing a preview visible only to you.";
    resources['could-not-fetch-event'] = <>Couldn't fetch this event.<br />Please check your internet connection and try again.</>;
    resources['game-expired'] = "This game has expired.";
    resources['game-stopped'] = "This game has been stopped by the quizmaster.";
    resources['game-stopped'] = "";
    resources['ranking-position'] = "You finished in position {0} with a score of {1}.";
    resources['click-here'] = "click here";
    resources['browse-to-event-page'] = " to see the final leaderboard and the event page.";
    resources['show-leaderboard'] = "Show Leaderboard";
    resources['share-leaderboard'] = "Share Leaderboard";

    resources["keep-up-to-date"] = "Keep your profile's public information up-to-date";
    resources["upcoming-events"] = "Upcoming events";
    resources['upcoming-event'] = "Upcoming event";
    resources["no-upcoming-events"] = "No upcoming events";
    resources["playing-now"] = "Playing NOW";

    resources["events"] = "Events";
    resources["event"] = "Event";
    resources["title"] = "Title";
    resources["datetime"] = "Date & Time";
    resources["timezone"] = "Timezone";
    resources["youtube-url"] = "YouTube Url";
    resources["facebook-url"] = "Facebook Url";
    resources["instagram-url"] = "Instagram Url";
    resources["twitter-url"] = "Twitter Url";
    resources["event-link"] = "Event Link";

    resources["packages"] = "Packages";
    resources["package"] = "Package";

    resources["games"] = "Games";
    resources["game"] = "Game";
    resources["event-id"] = "Event Id";
    resources["started"] = "Started";
    resources["stopped"] = "Stopped";
    resources["duration"] = "Duration";
    resources["paused"] = "Paused";
    resources["status"] = "Status";

    resources["categories"] = "Categories";
    resources["category"] = "Category";
    resources["color"] = "Color";
    resources["points"] = "Points";

    resources["questions"] = "Questions";
    resources["question"] = "Question";
    resources["text"] = "Text";
    resources["note"] = "Note";
    resources["answers"] = "Answers";
    resources["answer"] = "Answer";
    resources["image-duration"] = "Image Duration";
    resources["collection-not-editable"] = "This collection is not editable.";
    resources["empty-answer"] = "This answer is empty.";

    resources['exact-one'] = "Exact";
    resources['exact'] = "Exact";
    resources['wrong'] = "Wrong";
    resources['not-answered'] = "Not Answered";
    resources['player'] = "Player";
    resources['players'] = "Players";
    resources['ranking'] = "Leaderboard";
    resources['final-ranking'] = "Final Leaderboard";
    resources['sorry'] = "Sorry!";

    resources['game-started-full'] = (
        <div>
            <h1>Take a seat</h1>
            <p>The game is about to start</p>
        </div>);

    resources['play'] = "Play";
    resources['join-the-game'] = "JOIN THE GAME";
    resources['cookie-terms'] = "By joining the game you authorize us to the usage of cookies, which are necessary for the game functionality.";
    resources['quit'] = "Quit Game";
    resources['insert-name'] = "Enter your name";

    resources['welcome-enabled'] = (<div>
        <p>Welcome on board, you joined the game.</p>
        <p>You are gonna play since next question.</p>
        <p className='internet-connection-box'>This game needs an internet connection. To reduce data usage, connect your phone to Wi-Fi.</p>
        <p>Have fun!"</p>
    </div>);
    resources['welcome-pending'] = "<p>Hey <span className=\"teamName\">&nbsp;</span>, we are almost done!</p><p>Please inform the quizmaster that you are keen to play to get approved.</p>";
    resources['name-taken'] = "Couldn't join this game with the given team name since it is already taken.";
    resources['max-players-count-reached'] = <>Couldn't join this game because <br />the <b>maximum number</b> of players has been reached.</>;
    resources['name-error'] = "In order to join the game you need to specify your name.";
    resources['you-scored'] = "You scored ";
    resources['confirm-quit-game'] = "Exiting the game you are gonna lose all your progress and your score. Are you sure you wanna leave?";

    resources['quiz-rules'] = "Quiz Rules";
    resources['on-air-in'] = "On air in...";
    resources['logged-on-other-device'] = <>IqSpace is open in another window.<br />Click \"play\" to rejoin the game in this window.</>;
    resources['kicked-out'] = "You have been removed from this game by the administration.";

    resources["user-area"] = "User Area";
    resources["quizmaster-portal"] = "IQ Space Portal";

    resources["use-promo-code"] = <>Use promo code <b>PRO100</b> to get a Pro licence for free for 3 months.</>;
    resources["already-joined"] = <>
        <p>It looks like you are already a member of our community.
            <br />Go to your page to manage your game events.</p></>
    resources["wants-to-join-community"] = "Would you like to join our community?";
    resources["account-needed"] = "At first, you need an account with us. For free, of course!";
    resources["fill-the-form-to-join"] = "Fill the form to become an " + Settings["project-name"] + " star.";
    resources["joined-congrats"] = "That was great, congrats 🥳";
    resources["joined-description"] = <>
        <p>Awesome!</p>
        <p>You have successfully joined our community.</p>
        <p>It's time to create your first game event.</p>
    </>
    resources["joined-failed"] = "Request Failed";
    resources["subdomain-taken"] = "The space address you have specified is already taken or reserved. Please, try a different one.";
    resources["invalid-promo-code"] = "The promo code is not valid.";
    resources["joined-failed-description"] = <><p>Something went work when submitting your request.</p><p><Link to="/contact">Contact us</Link> and we will be happy to help you and set up your account with us.</p></>;
    resources["submit-request"] = "Submit Request";
    resources["pagename"] = "Page Name";
    resources["promo-code"] = "Promo Code";
    resources["can-be-creative"] = "You can be creative! 💡";
    resources["your-space"] = "Your Space";
    resources["your-space-desc"] = <><small>As an affiliated entertainer, you'll have your own page on IQ Space, displaying your events and other nice info about you.</small>
        <br /><small>How cool is that? 😎</small></>;
    // resources["another-country"] = "";
    resources["your-events-will-be"] = <>Yep! That works!<br />Your events will be presented at the page</>;
    resources["nickname-validation"] = "The page name must be at least 6 characters and can contain only lowercase letters, numbers and dashes.";
    resources["gamename-validation"] = "Your game name must be at least 3 characters.";
    resources["organization-name"] = "Name of your organization";
    resources["must-reconnect"] = "There has been a problem and we couldn't connect to the game.";
    resources["reload-game"] = "Reload the game";
    resources["login-to-play"] = "to play.";
    resources["game-option-quit-tooltip"] = "Quit The Game";
    resources["game-option-sounds-on-tooltip"] = "Switch Sounds On";
    resources["game-option-sounds-off-tooltip"] = "Switch Sounds Off";
    resources["game-option-fullscreen-on-tooltip"] = "Switch Fullscreen On";
    resources["game-option-fullscreen-off-tooltip"] = "Switch Fullscreen Off";
    resources["game-option-players-tooltip"] = "Show Players";

    resources["past-events"] = "Past Events";
    resources["no-past-events"] = "No Past Events";
    resources["enable-audio"] = "Enable Audio";
    resources["disable-audio"] = "Disable Audio";
    resources["powered-by"] = "powered by";
    resources["like-us-desc"] = "We can't do this without you 🤝 Follow us to show your support!";
    resources["like-us-desc-short"] = <>Follow us to keep up with<br />our next events.</>;
    resources["like-this-game"] = <><p>Did you like this game?</p><p>{resources["like-us-desc"]}</p></>;
    resources["no-chat-messages"] = <>Be the first to type something in the chat. 😉</>;
    resources["access-to-chat"] = "Access the game to chat";
    resources["game-name"] = "Game Name";
    resources["game-name-desc"] = "This is your public name to play with.";

    resources["home-banner"] =
        (<>
            <p>Dear entertainers...</p>
            <h1>IQ Space is coming</h1>
            <p>IQ Space is the new <b>live quiz</b> to skyrocket your entertainment business.</p> {/*🚀 */}
            <Icon className="fas fa-rocket" />
            <p>IQ Space embeds <b>Zoom</b> for real-time face-to-face engagement with the players and a <b>social chat</b> for instant interaction.</p>
            <p>Start to sell your <b>event tickets</b> directly from the IQ Space ticketing system.</p>
            <p><Link to="workwithus">Start to <s>work</s> play with us now.</Link></p>
        </>);
    resources["buy-ticket"] = "Buy Ticket";
    resources["your-tickets"] = "My Tickets";
    resources["on"] = "On";
    resources["purchased-on"] = "Purchased On";
    resources["ticket-id"] = "Ticket Id";
    resources["voucher-code"] = "Voucher";
    resources["paid"] = "Paid";
    resources["refunded"] = "Refunded";
    resources["refunds"] = "Refunds";
    resources["start-button"] = <span>Start (for free) to <s>work</s> play with us now</span>;
    resources["not-found"] = <>Page not found.<br />Please check you are browsing the correct url.</>;
    resources["you-already-own"] = "You already own this ticket.";
    resources["voucher"] = "Voucher";
    resources["have-voucher"] = "Do you have a voucher?";
    resources["apply"] = "Apply";
    resources["voucher-used"] = "This voucher has already been used.";
    resources["voucher-not-valid"] = "This voucher code is not valid.";
    resources["got-free-ticket"] = "Congrats! You got a free ticket!";
    resources["following"] = "Following";
    resources["follow"] = "Follow";
    resources["unfollow"] = "Unfollow";
    resources["interested"] = "I'm interested";
    resources["not-interested"] = "Not interested";
    resources["not-following-anyone"] = "You are not following anyone yet.";
    resources["work-from-x"] = ["the pub", "home", "the bar", "the beach (why not?)", "your TV studio", "anywhere 😍"];
    resources["allow-discoverable"] = <>Would you like to be sitewide promoted or contacted by<br />IQ Space and its close partners for work opportunities?</>;
    resources["go-to-event"] = "Go To Event";

    resources["meet-the-team"] = "Meet The Team";
    resources["iqspace-for-business"] = "IQ Space for companies and NPOs";
    resources["iqspace-tagline"] = "Entertainment is our priority, fun is our mission. 🔥";

    resources["automate-iqspace"] = "Automate IQ Space";
    resources["automate-iqspace-desc"] = (
        <>
            <p className="text-center">Entertain with fun, interactive and <b>completely automated</b> real-time games.</p>
            <br /><i className="fas fa-magic icon large light-grey"></i>
            <br /><br /><br />
        </>
    );
    resources["players"] = "Players";
    resources["assistance"] = "Assistance";
    resources["get-it-now"] = "Get it now";
    resources["manage-playlist-yourself"] = <>Manage the automated<br />playlist yourself</>;
    resources["month"] = "month";
    resources["for-whole"] = "For whole";
    resources["if-order-by"] = "if you order by";
    resources["localization-options"] = "Localization";
    resources['game-language'] = "Game Language";
    resources['currency'] = "Currency";
    resources['play-the-demo'] = "Play the demo";
    resources['features'] = "Features";

    resources["payments"] = "Payments";
    resources["stripe-partners"] = <>
        <p>Uderly partners with Stripe for simplified payments.</p>
        <p>Connect with Stripe to receive payments to your account.</p>
        <p><small>It will redirect you to the Stripe Uderly affiliates form.</small></p>
    </>;
    resources["connect-stripe"] = "Connect Stripe";
    resources["stripe-charges-not-enabled"] = <>You haven't successfully provided your payout details.</>
    resources["stripe-required"] = <>You are required to setup Stripe's payout in order to be enabled to sell by {Settings["project-name"]} and receive funds.</>;
    // <br />If you don't feel ready just yet, you could try a game attendance by providing the free tickets generated. You could see them in the event's view.</>;
    resources["stripe-payouts-not-enabled"] =
        <>You have successfully provided the required information to take payments but there's still something to do about your payout details.
            <br />In order to receive funds, please check your Stripe account.
            <br />If you believe you have provided all the required legal information, please do not worry and consider it might take a couple of days for Stripe to verify your profile and enable your payouts.
            <br />You can already start with {Settings["project-name"]}!</>
    resources["stripe-successfully-connected"] = "You have successfully connected your account with Stripe.";
    resources["view-stripe-account"] = "View Stripe Account";
    resources["view-stripe-dashboard"] = <p>You can manage your payments directly from your <a href="https://dashboard.stripe.com/" target="_blank" rel="noopener noreferrer">Stripe's dashboard</a>.<br />If you wish to change or remove the payment account, please get in touch with us.</p>;




    // FROM PORTAL


    resources["active"] = "Active";
    resources["pricing"] = "Pricing";
    resources["amount"] = "Amount";
    resources["you-earn"] = "You earn";
    resources["ticket-price"] = "Ticket Price";
    resources["net-income"] = "Net Income";
    resources["transaction-fee"] = "Transaction Fee";
    resources["no-tickets"] = "It looks like you haven't purchased any tickets yet.";
    resources["tickets"] = "Tickets";
    resources["free-tickets-desc"] = <>
        <p>Wanna give it a try? Just use these <b>welcome vouchers</b> to get free trial tickets.</p>
        <small><p>Hint: you could award your top players.</p></small></>;
    resources["ticket-min-price"] = "Minimum price {currency} 0.50";
    resources["attendances"] = "Attendances";
    resources["purchased-on"] = "Purchased on";
    resources["fullname"] = "Full Name";
    resources["user"] = "User";
    resources["username"] = "Username";
    resources["voucher"] = "Voucher";
    resources["generate-free-tickets"] = "Generate Free Tickets";
    resources["attendance_status"] = "Status";
    resources["attendance_statuses"] = {
        1: "Interested",
        2: "Acquired",
        3: "Payment Intent",
        4: "Refunded",
    };
    resources["no-attendances"] = "There are not confirmed attendances nor interested users for this event yet.";
    resources["cant-delete-event"] = <>
        <p>You can't delete this event, because there are other resources associated, such as a game or purchased tickets.</p>
        <p>You can unpublish this event if you don't want to keep it available for your audience.</p>
    </>;
    resources["refund"] = "Refund";
    resources["refund-ticket"] = "Refund Ticket";
    resources["refund-confirm"] = "Would you like to refund {user}?";
    resources["played-questions"] = "Played Questions";
    resources["no-played-questions"] = "No questions have been played yet.";
    resources["total-players"] = "Players";
    resources["correct-responses-count"] = "Correct";
    resources["unanswered-responses-count"] = "Unanswered";
    resources["incorrect-responses-count"] = "Wrong";

    resources["event-page"] = "Event Page";
    resources["events"] = "Events";
    resources["event"] = "Event";
    resources["title"] = "Title";
    resources["youtube-url"] = "YouTube Url";
    resources["facebook-url"] = "Facebook Url";
    resources["instagram-url"] = "Instagram Url";
    resources["twitter-url"] = "Twitter Url";
    resources["event-link"] = "Event Link";
    resources["event-planned"] = "Event planned to start in";
    resources["game-expired"] = <>This game has expired.<br />Create a new event to play again.</>;

    resources["actions"] = "Actions";
    resources["follower"] = "Follower";
    resources["followers"] = "Followers";
    resources["gamename"] = "Game Name";
    resources["packages"] = "Question Albums";
    resources["package"] = "Questions Album";
    resources["playlists"] = "Playlists";
    resources["playlist"] = "Playlist";
    resources["select-playlist"] = "Select a Playlist";
    resources["collections"] = "Collections";
    resources["collection"] = "Collection";
    resources["clear-playlist"] = "Clear Playlist";
    resources["clear-playlist-confirm"] = "Are you sure you want to remove all the questions from the playlist?";
    resources["populate-playlist"] = "Populate Playlist";
    resources["injest-json"] = "Injest JSON";
    resources["add-to"] = "Add To";
    resources["add-to-new-playlist"] = "Add To New Playlist";
    resources["new-playlist"] = "New Playlist";
    resources["add-to-playlist"] = "Add To Playlist";
    resources["add-all-to-playlist"] = "Add All To Playlist";
    resources["remove-from-playlist"] = "Remove from playlist";
    resources["move-to-playlist"] = "Move to playlist";
    resources["copy-to-playlist"] = "Copy to playlist";
    resources["remove-question"] = "Remove Question";
    resources["remove-question-confirm"] = "Are you sure you want to remove the selected question from the playlist?";
    resources["confirm-delete-question"] = "Are you sure you want to remove the selected question?";
    resources["drag-questions"] = "Drag and drop the items to reorder the list";
    resources["quiz"] = "Quiz";
    resources["options"] = "Options";
    resources["insert-question"] = "Insert Question";
    resources["question-options"] = "Question Options";
    resources["add-to-collection"] = "Add to your collection";
    resources["remove-from-collection"] = "Remove from your collection";

    resources["games"] = "Games";
    resources["game"] = "Game";
    resources["event-id"] = "Event Id";
    resources["started"] = "Started";
    resources["stopped"] = "Stopped";
    resources["duration"] = "Duration";
    resources["max-players-count"] = "Max Players Number";
    resources["paused"] = "Paused";
    resources["status"] = "Status";

    resources["categories"] = "Categories";
    resources["category"] = "Category";
    resources["color"] = "Color";

    resources["questions-count"] = "Questions Count";
    resources["questions"] = "Questions";
    resources["question"] = "Question";
    resources["text"] = "Text";
    resources["note"] = "Note";
    resources["answers"] = "Answers";
    resources["answer"] = "Answer";
    resources["image-duration"] = "Image Duration";
    resources["collection-not-editable"] = "This collection is not editable.";

    resources["start-when"] = "Start when you are ready";
    resources["events-today"] = "Events today";
    resources["no-events-today"] = "Nothing planned today.";
    resources["start-new-game"] = "Start a new game";
    resources["start-game"] = "Start game";
    resources["stop-game"] = "Stop game";
    resources["plan-an-event"] = "schedule an event";

    resources["this-is-a-free-event"] = "This is a free event.";
    resources["this-is-a-paid-event"] = "This is a paid event.";
    resources["licence"] = "Licence";
    resources["free-not-possible"] = <>
        <p>It is not possible anymore to set this event as free because you have already sold tickets or started the game.</p>
        <p>If you wish to host a free game you could refund your players and create a new event.</p>
    </>;

    resources["video-url"] = "Video URL";
    resources["video-support"] = "Support YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, and DailyMotion.";
    resources["video-url-disabled-desc"] = <p><small>The video can't be shown if an image has been selected.</small><br /><small>Either choose to show an image or a video.</small></p>;
    resources["fixed-score"] = "Fixed Score";
    resources["fixed-score-default"] = <small>Use default algorithm</small>;
    resources["fixed-score-desc"] = <small>Choose whether using a fixed score or the default progressive algorithm.</small>;

    // Squizzer Real Time Server Messages
    resources["loading-event"] = "Loading Event";
    resources["rts-connecting"] = "Connecting";
    resources["rts-connected"] = "Connected. I'm subscribing to the server...";
    resources["rts-disconnected"] = "Disconnected. :/ Attempting reconnection...";
    resources["rts-couldnt-connect"] = "The game is not available right now due to a connection issue to the server. Please make sure you are connected to the internet and try refreshing the page in a bit.";
    resources["rts-subscription-failed"] = "Subscription to the server failed. Error: ";

    resources["cancel-by"] = "Cancel by";
    resources["invite-players"] = <span>Invite<br />Players</span>;
    resources["cancel-game"] = "Cancel Game";
    resources["cancel-game-confirm"] = (
        <div>
            <p>Would you like to cancel this game?</p>
            <p>All the information related to this game, including players scores, will be <strong>destroyed</strong> and it won't be recoverable.</p>
            <p>The players will lose the connection with the game so they will have to refresh the game page.</p>
        </div>);
    resources["last-response"] = "Last Response";
    resources["last-time"] = "Last Time";
    resources["last-score"] = "Last Score";
    resources["score"] = "Score";
    resources["delete-player"] = "Delete Player";
    resources["delete-player-confirm"] = (
        <div>
            <p>Would you like to delete this player?</p>
            <p>Their score will be <strong>erased</strong> and won't be recoverable.</p>
            <p>The player will be kicked out of the game and will need to join it again to play.</p>
        </div>);
    resources["reset-scores"] = "Reset Scores";
    resources["reset-scores-confirm"] = (
        <div>
            <p>Would you like to <b>permanently</b> reset all players' scores?</p>
        </div>);
    resources["wave"] = "Wave";
    resources["players"] = "Players";
    resources["viewer"] = "Viewer";
    resources["viewers"] = "Viewers";

    resources["final-leaderboard-confirmation"] = "Do you want to show the final leaderboard?";
    resources["pause-tooltip"] = "Intermission";
    resources["stop-tooltip"] = "Stop Game";
    resources["play-tooltip"] = "Start Game";
    resources["forward-tooltip"] = "Forward";
    resources["skip-tooltip"] = "Skip Forward";
    resources["show-invite-players-tooltip"] = "Show Start View";
    resources["hide-invite-players-tooltip"] = "Hide Start View";
    resources["show-teams-tooltip"] = "Show Players Board";
    resources["hide-teams-tooltip"] = "Hide Players Board";
    resources["show-leaderboard-tooltip"] = "Show Leaderboard";
    resources["hide-leaderboard-tooltip"] = "Hide Leaderboard";
    resources["show-final-leaderboard-tooltip"] = "Show Final Leaderboard";
    resources["hide-final-leaderboard-tooltip"] = "Hide Final Leaderboard";
    resources["enable-partial-leaderboard-tooltip"] = "Enable Question Leaderboard";
    resources["disable-partial-leaderboard-tooltip"] = "Disable Question Leaderboard";
    resources["enable-category"] = "Enable Category";
    resources["disable-category"] = "Disable Category";
    resources["partial-leaderboard-on-off"] = <span>Question<br />Leaderboard</span>;
    resources["play-question-tooltip"] = "Play this Question";
    resources["ticket-id"] = "Ticket Id";
    resources["page-at"] = "Your page is online at";
    resources["wish-change-subdomain"] = "If you wish to change it, please get in touch with us.";
    resources["game-language"] = "Game Language";
    resources["notifications"] = "Notifications";
    resources["notifications-desc"] = "Send a notification to all the event's viewers.";
    resources["message"] = "Message";
    resources["only-premium-feature"] = "This feature is available only for premium accounts.";
    resources["info"] = "Info";
    resources["success"] = "Success";
    resources["warning"] = "Warning";
    resources["error"] = "Error";
    resources["your-store"] = "Your Organisation";
    resources["your-space"] = "Your Space";
    resources["current-games"] = "Current Games";
    resources["your-company-logo"] = "Your company's logo";
    resources["edit-store-information"] = "Edit your organisation";
    resources["game-link"] = "Game Link";
    resources["play-desc"] = "Direct link to access the game.";
    resources["project"] = "Project";
    resources["project-desc"] = "Link to project to maxi-screen(s).";
    resources["removed-from-playlist"] = "Question removed from playlist";
    resources["removed-from"] = "Question deleted from ";
    resources["all-questions-added"] = "Questions added to playlist";
    resources["questions-added-to-playlist"] = " questions added to playlist";
    resources["questions-removed-from-playlist"] = "Questions removed from playlist.";
    resources["max-playlist-questions"] = "Maximum number of questions reached.";
    resources["confirm-remove-questions"] = "Are you sure you would like to remove these questions from the selected playlist?";
    resources["added-to-playlist"] = "Added to playlist";
    resources["moved-to-playlist"] = "Moved to playlist";
    resources["already-in-playlist"] = "The question is already in the playlist";
    resources["socials"] = "Socials";
    resources["team-members"] = "Team Members";
    resources["your-socials"] = "Your socials";
    resources["your-intro"] = "Your intro";
    resources["your-team"] = "Your team";

    // Play Page
    resources["quizmaster-intro"] = (user) => <>
        <p className='text-center'>That's great. <b>You are a quizmaster</b>.</p>
        <p className='text-center'>Go to your space <Link to={"/" + user.stores[0].subdomain}>{user.stores[0].name}</Link> to edit your information and start successful games.</p>
    </>;
    resources["play-the-quizmaster"] = <>
        <h2>Play the quizmaster</h2>

        <p>Be the entertainer and master IQ Space for your friends.</p>
        <p>Share your game’s link and let them be your guests.</p>
    </>;
    resources["fancy-some-fun"] = "Fancy some fun? Rock the party with IQ Space!";
    resources["join-as-player"] = <>
        <h2>Join as a player</h2>

        <p>Sign up now and get ready to play IQ Space.</p>
        <p>Your quizmaster will send you the invitation to start the game.</p>
    </>;
    resources["already-registered"] = "You are already a player.";

    resources["price"] = "Price";
    resources["published"] = "Published";
    resources["free"] = "Free";
    resources["cant-delete-default-playlist"] = "It is not possible to delete your default playlist.";
    resources["event-locked"] = "It's not possible to delete this event, because the game has already started or some tickets have already been purchased.";
    resources["premium-feature"] = <small>Find out with a premium account.</small>;
    resources["welcome-back-entertainer"] = <><b>Welcome back!</b> Access your space to manage your games.</>;
    resources["ticket-fees"] = "Ticket Fees";
    resources["ticket-fees-desc"] = (<>
        <p>Do you want to make money from the events you host?</p>
        <p>IQ Space charges a service fee for every paid ticket you sell.</p>
        <p>If your tickets are free, there won't be any ticketing fees.</p>
        <p>Otherwise, for paid events:</p>
        <p><CheckIcon /> You just pay a <b>{Settings["pricing"].perc}% + {Settings["pricing"].fixed} £</b> card transaction for tickets you sell.</p>
        <p><CheckIcon /> Your players will pay a booking fee of £ <b>{Settings["pricing"].bookingStartsFrom}</b> for every ticket they purchase.</p>
        <p>For paid events, host up to <b>90 players</b>.</p>
    </>);
    resources["must-setup-stripe"] = "In order to sell tickets, you must, at first, set up your payout information to receive funds.";
    resources["event-schedule-auto-start"] = "Automate the game start?"
    resources["event-must-be-published"] = "The event must be published to be set to start automatically.";
    resources["pro-licence-required"] = "This feature comes with a pro licence."
    resources["upgrade"] = "Upgrade";
    resources["pay"] = "Pay";
    resources["pay-by-card"] = "Pay by card.";
    resources["select-a-card"] = "Select a card.";
    resources["delete-payment-method-confirm"] = "Would you like to delete this payment method?";
    resources["pay-by-new-card"] = "Pay with another card";
    resources["event-online-at"] = "Your event is online at";
    resources["event-location-type-where"] = "Where will this event be hosted?";
    resources["unspecified"] = "Unspecified";
    resources["online"] = "Online";
    resources["on-site"] = "On-site";
    resources["both-online-on-site"] = "Both online & on-site";
    resources["become-a-quizmaster"] = <>Become a professional quizmaster.<br />Join us now and create your own live game at</>;
}