import React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { Link } from "react-router-dom";

import Settings from '../settings';

export default class StoreAvatar extends React.Component {

    render() {
        const { store, onClick } = this.props;

        return (
            <Link to={"/" + store.subdomain} className="store-avatar"
                onClick={onClick}>
                <Chip
                    avatar={<Avatar src={Settings.apiUrl + "/stores/" + store.id + "/image/thumb?t=" + Date.now()}>{store.name && store.name.substr(0,1)}</Avatar>}
                    label={store.name}
                    variant="outlined" />
            </Link>
        );
    }
}