import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

import AppContext from '../AppContext';
import IqSpaceApi from '../../api/IqSpace';
import ActionButton from '../../framework/ActionButton';

export interface InjestQuestionsProps {
    packageId: number;
    onCompleted: (questions: []) => void;
}

const InjestQuestions = ({ packageId, onCompleted }: InjestQuestionsProps) => {
    const [json, setJson] = useState<string>("");

    return (
        <div className='injest-questions-form'>
            <TextField
                label="Multiline"
                multiline
                rows={3}
                value={json}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setJson(event.target.value);
                }} />

            <ActionButton tooltip={AppContext.r["injest-json"]}
                disabled={json.length === 0}
                action={async () => {
                    const response = await IqSpaceApi.InjestQuestions(packageId, json);

                    if (response && response.status === 202) {
                        setJson("");

                        const packagesResponse = await IqSpaceApi.Package(packageId);

                        if (packagesResponse && packagesResponse.status === 200)
                            onCompleted(packagesResponse.data.data.questions);
                    }
                }} icon="fas fa-code" />
        </div>);
}

export default InjestQuestions;