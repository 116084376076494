import React, { useContext } from 'react';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import StopIcon from '@mui/icons-material/Stop';

import { LayoutContext, ILayoutContext, DialogModesEnum } from '@uderly/react-uderly-ui';
import AppContext from '../../AppContext';
import ActionButton from '../../../framework/ActionButton';
import IqSpaceApi from '../../../api/IqSpace';
import GameStatusEnum from '../../../api/QuizStatusEnum.js';

export interface MainControlsProps {
  event: any;
  gameStatus: any;
  onForward: (id: number) => Promise<void>;
}

const MainControls = ({ event, gameStatus, onForward }: MainControlsProps) => {
  const layoutContext = useContext(LayoutContext) as ILayoutContext;

  const stopGame = async () => {
    await IqSpaceApi.StopGame(event.id);
  }

  const onStopGame = async () => {
    if (gameStatus.gameStatus !== GameStatusEnum.FinalRanking)
      layoutContext.dialog.show({
        mode: DialogModesEnum.confirmation,
        content: AppContext.r["final-leaderboard-confirmation"],
        onConfirm: async () => {
          await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.CorrectAnswer);
          await IqSpaceApi.SubmitStatusChange(event.id, GameStatusEnum.FinalRanking);
        },
        onCancel: async () => await stopGame()
      });
    else await stopGame();
  }

  if (gameStatus && gameStatus.expired)
    return null;

  return <div className="main-controls">
    {(gameStatus && !gameStatus.completed && !gameStatus.isPaused)
      && <ActionButton className="pause" tooltip={AppContext.r["pause-tooltip"]}
        action={async () => await IqSpaceApi.PauseGame(event.id)} icon="fas fa-clock" />}
    {(gameStatus && !gameStatus.completed && gameStatus.isPaused)
      && <ActionButton className="pause" tooltip={AppContext.r["pause-tooltip"]} selected
        action={async () => await IqSpaceApi.PauseGame(event.id, false)} icon="fas fa-clock" />}

    {(!gameStatus || (gameStatus.completed && !gameStatus.expired))
      && <ActionButton className="start-game outline-icon" big tooltip={AppContext.r["start-when"]}
        action={async () => await IqSpaceApi.StartGame(event.id)}>
        <PlayCircleOutlineIcon />&nbsp;
        <div className="mobile-hidden">{AppContext.r["start-game"]}</div>
        <div className="mobile-only">START</div>
      </ActionButton>}

    {(gameStatus && !gameStatus.completed)
      && <ActionButton className="stop-game outline-icon" big
        tooltip={AppContext.r["stop-tooltip"]} action={onStopGame}>
        <StopIcon />&nbsp;
        <div className="mobile-hidden">{AppContext.r["stop-game"]}</div>
        <div className="mobile-only">STOP</div>

      </ActionButton>}

    {/* Force Move Forward */}
    {(gameStatus && !gameStatus.completed
      && (gameStatus.gameStatus >= GameStatusEnum.Category && gameStatus.gameStatus < GameStatusEnum.CorrectAnswer))
      && <ActionButton className="forward" tooltip={AppContext.r["skip-tooltip"]}
        action={async () => await onForward(event.id)} icon="fas fa-forward" />}

    {/* Force Move Forward */}
    {(gameStatus && !gameStatus.completed
      && (gameStatus.gameStatus < GameStatusEnum.Category || gameStatus.gameStatus >= GameStatusEnum.CorrectAnswer))
      && <ActionButton className="forward" tooltip={AppContext.r["forward-tooltip"]}
        action={async () => await onForward(event.id)} icon="fas fa-forward" />}

  </div>;
}

export default MainControls;