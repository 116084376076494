import { useEffect } from 'react';

import { useUderlyStore } from '@uderly/react-uderly-ui/zustand';
import { useNavigate } from 'react-router-dom';

const SignOutPage = () => {
  const signOut = useUderlyStore((state) => state.signOut);

  const navigate = useNavigate();

  useEffect(() => {
    signOut();

    navigate("/");
  }, [navigate, signOut]);

  return null;
};

export default SignOutPage;
