import Axios from 'axios';

import Settings from '../app/settings';

import { errorLog } from '@uderly/react-uderly-ui/api';

export default class Core {
    // static errorLog(error) {
    //     if (error.response) {
    //         // Request made and server responded
    //         console.log(error.response);
    //         console.log(error.response.data);
    //         console.log(error.response.status);
    //         console.log(error.response.headers);
    //     } else if (error.request) {
    //         // The request was made but no response was received
    //         console.log(error.request);
    //     } else {
    //         // Something happened in setting up the request that triggered an Error
    //         console.log('Error', error.message);
    //     }

    //     return (error.response) ? error.response : null;
    // }

    static async fetchAsync(url, fieldToMap = null, getResponse = false) {
        const res = await Axios.get(url)
            .catch(function (error) {
                console.log('error ' + error);
                errorLog(error);
            });

        const data = (res.data && !getResponse) ? ((res.data.data) ? res.data.data : res.data) : res;

        return this.mapData(data, fieldToMap);
    };

    static mapData(data, fieldToMap) {
        if (fieldToMap && fieldToMap.length > 0) {
            const mappedData = [];
            for (const x of data) {
                mappedData[x[fieldToMap]] = x;
            }
            return mappedData;
        }
        return data;
    }

    static async fetchItem(url, callback, cancelToken = null) {
        console.log("Fetch " + url)
        const config = {};

        if (cancelToken)
            config.cancelToken = cancelToken;

        Axios.get(url, config)
            .then(response => {
                if (callback)
                    callback(response.data.data);
            })
            .catch((error) => {
                if (Axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.log('error ' + error);
                }
            });
    }

    static async fetchItemAsync(url, cancelToken = null) {
        const config = {};

        if (cancelToken)
            config.cancelToken = cancelToken;

        return await Axios.get(url, config)
            .catch(function (error) {
                if (Axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.log('error ' + error);
                }

                errorLog(error);
            });
    }

    static async Locales() {
        return await Axios.get(Settings.apiUrl + "/locales")
            .catch((error) => {
                console.log('error ' + error);
                return error.response;
            });
    }
}