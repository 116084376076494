import React from 'react';
import ScrollIntoView from 'react-scroll-into-view';
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Button from '@mui/material/Button';
import { connect } from 'react-redux';

import AppContext from "../AppContext";
import { ContactForm } from "@uderly/react-uderly-ui";
// import ContactForm from '../../uderly/components/ContactForm';
import Separator from '../components/Separator';
import Socials from "../components/Socials";
import Game, { ViewModeStatesEnum } from "quiz-components/Game";
import { setAlert, hideAlert } from '../../redux/layoutSlice';
import StoreAvatar from '../components/StoreAvatar';
import Settings from '../settings';

const TechCard = (props) => {
    return (
        <div className="tech-card">
            <div className="content">
                <h1>{props.title}</h1>

                {props.icon &&
                    <img src={props.icon} alt={props.title} />}

                <p>{props.desc}</p>

                {props.to &&
                    <Link to={props.to}>{AppContext.r["learn-more"]}</Link>}
            </div>
        </div>
    );
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className='slick-arrow-container right'>
            <div className={className} onClick={onClick}
                style={{ ...style, display: "block", right: "15px", zIndex: 1200 }}>
                <i className="fas fa-chevron-circle-right"></i>
            </div>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className='slick-arrow-container left'>
            <div className={className} onClick={onClick}
                style={{ ...style, display: "block", left: "15px", zIndex: 1200 }}>
                <i className="fas fa-chevron-circle-left"></i>
            </div>
        </div>
    );
}

class Home extends React.Component {
    state = {
        show3DScene: false,
        auth: this.props.auth
    };

    sliderSettings = {
        dots: true,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    constructor(props) {
        super(props);

        const params = new URLSearchParams(window.location.search);
        const initialSlide = params.get('slide');

        if (initialSlide)
            this.sliderSettings.initialSlide = initialSlide;
    }

    async componentDidMount() {
        if (this.props.updateLayout)
            this.props.updateLayout();

        if (this.props.token) {
            // const response = await AuthApi.verify(this.props.token);

            // if(response && response.status === 200) {
            //     this.props.setAlert({ open: true, message: AppContext.r["account-verified"], severity: "success" });
            //     // AppContext.history.push("/");
            // }
        }

        this.welcomeBackEntertainer();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth !== this.state.auth) {
            console.log("LOGIN CHANGED")
            this.welcomeBackEntertainer(nextProps.auth);

            this.setState({
                auth: nextProps.auth
            });
        }
    }

    welcomeBackEntertainer = (auth) => {
        // It shows a popup as a quick access to the space (if any)
        if (auth && auth.user && auth.user.stores && auth.user.stores.length > 0) {
            const store = auth.user.stores[0];

            this.props.setAlert({
                open: true,
                message: <>
                    {AppContext.r["welcome-back-entertainer"]}

                    <StoreAvatar store={store} onClick={() => this.props.hideAlert()} />
                </>,
                autoHideDuration: 10000,
                severity: "info",
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }
            });
        }
    }

    onInitSlider = () => {
        setTimeout(() => {
            this.setState({
                show3DScene: true
            });
        }, 100);
    }

    render() {
        const techSliderSettings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            swipe: true,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };

        const technologies =
            <Slider className="tech-carousel" {...techSliderSettings}>
                {AppContext.r["iqspace-features"].map((x, i) =>
                    <TechCard key={"tech-card" + i} title={x.title} desc={x.desc} icon={x.icon} to={x.to} />)}
            </Slider>;

        const playNowAction = (
            <Link to="/play">
                <Button variant="outlined" className="start-now-button">
                    {AppContext.r["play-now"]}
                </Button>
            </Link>
        );

        // Business
        const startNowAction = (
            <Link to="/business">
                <Button variant="outlined" className="start-now-button">
                    {AppContext.r["start-now"]}
                </Button>
            </Link>
        );

        return (
            <div className="page home">
                <div className="gallery fullscreen" id="home">
                    <Slider ref={slider => (this.slider = slider)} {...this.sliderSettings}
                        onInit={this.onInitSlider}>
                        <div className='slide iqspace-slide iqspace-slide-0'>
                            <img alt="IQ Space" src={AppContext.s["iqspace-logo-light"]} className="img-fluid logo" />

                            <p className="tagline">{AppContext.r["iqspace-tagline-0"]}</p>

                            {playNowAction}
                        </div>

                        <div className='slide iqspace-bg-slide iqspace-slide-1'>
                            <div className='fullscreen-overlay'></div>

                            <img alt="IQ Space" src={AppContext.s["iqspace-logo-light"]} className="img-fluid logo" />

                            <p className="tagline">{AppContext.r["iqspace-tagline-1"]}</p>

                            {playNowAction}
                        </div>

                        <div className='slide iqspace-bg-slide iqspace-slide-2'>
                            <div className='fullscreen-overlay'></div>

                            <img alt="IQ Space" src={AppContext.s["iqspace-logo-light"]} className="img-fluid logo" />

                            <p className="tagline">{AppContext.r["iqspace-tagline-2"]}</p>

                            <Link to="/business">
                                <Button variant="outlined" className="start-now-button">
                                    {AppContext.r["learn-more"]}
                                </Button>
                            </Link>
                        </div>

                        <div>
                            <div className="slide socials-slide">
                                <a href="https://www.uderly.com" target="_blank" rel="noopener noreferrer" className="slide-uderly-logo">
                                    <img src={"assets/images/uderly-payoff-light.png"} alt="logo" />
                                </a>

                                <p>{AppContext.r["socials-tagline"]}</p>
                                <h6>{AppContext.r["connect-with-us-on"]}</h6>

                                <Socials />
                            </div>
                        </div>
                    </Slider>

                    <ScrollIntoView selector={"#anchor0"} className="fade-out-scrolling-25">
                        <i className="slide-down fas fa-chevron-circle-down"></i>
                    </ScrollIntoView>
                </div>

                <div>
                    <div id="anchor0" className="anchor"></div>

                    <div id="mission" className="block-container mission">
                        <div className="block">
                            <h1 className="text-center">Iq Space</h1>
                            <Separator />

                            <Row>
                                <Col md={5} className="flex-centered">
                                    <div className="embedded-quiz-container">
                                        <Game viewMode={ViewModeStatesEnum.Widget} id={(this.props.layout.language === "it") ? 3 : 2} />
                                    </div>
                                </Col>

                                <Col md={7} className="paragraphs-col">
                                    {AppContext.r["home-iqspace-desc"]}

                                    <Link className="play-the-demo-button iq-button-link" to={"/event/" + ((this.props.layout.language === "it") ? 3 : 2)}>
                                        <Button variant="outlined" className="call-to-action">
                                            <img src={AppContext.s["mini-logo-dark"]} alt="Mini Logo" />
                                            {AppContext.r["play-the-demo"]}</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div id="features" className="block-container technologies">
                        <div className="block">
                            <h1 className="text-center">{AppContext.r['features']}</h1>
                            <Separator />

                            {technologies}
                        </div>
                    </div>
                    {/* 
                    <div className="block-container">
                        <div className="color-banner who-are-we">
                            <Row>
                                <Col xs={12}>
                                    {AppContext.r["who-are-we-desc"]}
                                </Col>
                            </Row>
                        </div>
                    </div> */}

                    {/* <div className="black-banner">&nbsp;</div> */}
                    <div id="playwithfriends"></div>

                    <div className="block-container">
                        <Row className="block">
                            <Col md={7} className="paragraphs-col">
                                {AppContext.r["home-playwithfriends-desc"]}

                            </Col>

                            <Col md={5} className="flex-end">
                                <img className="img-fluid image-frame" src="/assets/images/iq-space-quiz-play-with-friends2.jpg" alt="Mission" />
                            </Col>
                        </Row>
                    </div>

                    <div id="selltickets" className="anchor"></div>

                    <div className="block-container extra-fun">
                        <Row className="block">
                            <Col md={5} className="flex-start mobile-hidden">
                                <img className="img-fluid image-frame" src="/assets/images/iq-space-quiz-play-with-friends.jpg" alt="Mission" />
                            </Col>

                            <Col md={7} className="paragraphs-col">
                                {AppContext.r["home-fun-desc"]}
                            </Col>

                            <Col md={5} className="flex-start mobile-only">
                                <img className="img-fluid image-frame" src="/assets/images/iq-space-quiz-play-with-friends.jpg" alt="Mission" />
                            </Col>
                        </Row>
                    </div>
                    {/* 
                    <div className="white-banner how-we-see-the-world">
                        <Row>
                            <Col xs={12}>
                                <i className="pe-7s-like"></i>

                                {AppContext.r["how-we-do-desc"]}
                            </Col>
                        </Row>
                    </div> */}

                    <div id="businnesBanner" className="block-container">
                        <div className="block golf-banner">
                            <div className="text-center">
                                {AppContext.r["home-business-desc"]}

                                {startNowAction}
                            </div>
                        </div>
                    </div>

                    {/* <div id="friends" className="block-container friends">
                        <div className="block">
                            <h1 className="text-center">{AppContext.r['friends']}</h1>
                            <Separator />

                            <div className="logos">
                                <img src="assets/images/partners/rotaract2041.png" />
                                <img src="assets/images/partners/sicily_eat.png" />
                                <img src="assets/images/partners/trancio_sz_sfondo.png" />
                                <img src="assets/images/partners/villa_pizza.png" />
                                <img src="assets/images/partners/woolsafe.png" />
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="block-container video">
                        <h1>Video</h1>
                        <Separator />

                        <ReactPlayer url={"https://www.youtube.com/watch?v=XgSk2ABiWmU"} className="player"
                            width={640} height={360} controls={true}
                            muted={true} playing={true} loop={true} />
                    </div> */}

                    <div id="contact" className="block-container work-with-us">
                        <h1>{AppContext.r['contact']}</h1>
                        <Separator />

                        {/* <p className="text-center">{AppContext.r["tell-your-idea"]}</p>

                        <div className="can-help-with">
                            <span>{AppContext.r["can-help-with"]}&nbsp;</span>
                            <b>
                                <Typewriter
                                    options={{
                                        strings: AppContext.r["can-help-with-x"],
                                        autoStart: true,
                                        loop: true,
                                        delay: 40
                                    }}/>
                            </b>
                        </div> */}
{console.log(Settings.apiUrl)}
                        <ContactForm title={null} />
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = state => ({
    layout: state.layout,
    auth: state.auth
});

const mapDispatchToProps = () => ({
    setAlert,
    hideAlert
});

export default connect(mapStateToProps, mapDispatchToProps())(Home);