import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements } from "@stripe/react-stripe-js";
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import { Formik, Form, Field } from 'formik';
import { LinearProgress } from '@mui/material';
import { TextField } from 'formik-mui';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from "react-router-dom";
import { t } from 'i18next';

import { MustBeLogged } from '@uderly/react-uderly-ui';
import AppContext from '../AppContext';
import StripeCheckout from "framework/StripeCheckout";
import IqSpaceApi from "api/IqSpace";
import withUderlyStore from "../../zustand/withUderlyStore";
import Settings from "../../app/settings";
import GameStatusEnum from 'quiz-components/GameStatusEnum';
import { LayoutContext } from "../../uderly/components/LayoutTools";

const yup = require('yup');

export class EventAttendanceStatusesEnum {
    static get interested() { return 1; }
    static get paid() { return 2; }
    static get paymentIntent() { return 3; }
    static get refunded() { return 4; }
    static get notInterested() { return 5; }
}

class BuyTicket extends React.Component {
    static contextType = LayoutContext;

    state = {
        user: this.props.zustand.user,
        attendance: null,
        isCheckoutVoucherCollapsed: true
    };

    onCheckedOut = () => {
        this.props.modal.setFree();
        this.props.modal.hide();
    }

    onResetVoucher = () => {
        this.props.modal.setFree();
    }

    checkout = () => {
        const modalMessage = (
            <div className="checked-out-message">
                <i className="far fa-check-circle icon"></i>
                <p className="message">
                    {t('order-placed')}<br />
                    {t('ticket-email-info')}

                    <p><Link to="/tickets">{t('your-tickets')}</Link></p>
                </p>

                <Button onClick={this.onCheckedOut}>{AppContext.r['close']}</Button>
            </div>);

        this.props.modal.setMessage(modalMessage, false);
    }

    fetchAttendance = async () => {
        const response = await IqSpaceApi.AttendEvent(this.props.event.id, EventAttendanceStatusesEnum.paymentIntent);

        if (response && response.status === 200) {
            const attendance = response.data.data;

            this.setState({
                attendance: attendance
            });
        }
    }

    async componentDidMount() {
        const { store } = this.props;
        const { user } = this.state;

        if (user)
            await this.fetchAttendance();

        const key = store.stripe_test ? Settings.stripe.testKey : Settings.stripe.key;

        this.setState({
            promise: loadStripe(key, store.stripe_id)
        })
    }

    async componentWillReceiveProps(nextProps) {
        if (!this.state.attendance)
            await this.fetchAttendance();

        if (nextProps.zustand.user !== this.state.user) {
            this.setState({
                user: nextProps.zustand.user
            });
        }
    }

    applyVoucherCode = async (values) => {
        const { attendance } = this.state;

        const response = await IqSpaceApi.ApplyVoucher(attendance, values.voucher_code);

        let message = null;

        if (response.status === 410) {
            message = AppContext.r["voucher-used"];
        } else if (response.status !== 202) {
            message = AppContext.r["voucher-not-valid"];
        }

        const modalMessage = message ? (
            <>
                <i className="icon far fa-frown"></i>
                <p className="message">{message}</p>
                <Button onClick={this.onResetVoucher}>{AppContext.r['close']}</Button>
            </>) : (
            <>
                <i className="icon far fa-check-circle"></i>
                <p className="message">{AppContext.r["got-free-ticket"]}</p>
                <Button onClick={this.onCheckedOut}>{AppContext.r['close']}</Button>
            </>);

        this.props.modal.setMessage(modalMessage, false);
    }

    render() {
        const { event, store } = this.props;
        const { user, attendance, isCheckoutVoucherCollapsed } = this.state;

        const total = event.price + store.licence.ticket_booking_fee;

        let view = (
            <Row>
                <Col xs={12} className="display-flex justify-content-center">
                    <MustBeLogged />
                </Col>

                <Col xs={12} className="display-flex justify-content-center align-items-center">
                    <Button onClick={this.props.authActions.onLogin}>{AppContext.r["login"]}</Button>
                    {/* <Button onClick={this.props.authActions.onRegister}>{AppContext.r["signup"]}</Button>{AppContext.r["or"]}<Button onClick={this.props.authActions.onLogin}>{AppContext.r["login"]}</Button> */}
                </Col>
            </Row>
        );

        if (user && !attendance)
            view = AppContext.r["preloader"];

        if (user && attendance) {
            const request = {
                event: event.id,
                quantity: 1,
                event_attendance_id: attendance.id
            }

            const imageUrl = Settings.apiUrl + "/events/" + event.id + "/image";

            view = (
                <>
                    <div className="image-box">
                        {event.image &&
                            <CardMedia component="img" alt={event.title} height="140"
                                image={imageUrl}
                                title={event.title} />}
                    </div>

                    {(attendance.status_id === EventAttendanceStatusesEnum.paid) ?
                        <h3 className="text-center">{AppContext.r["you-already-own"]}</h3>
                        : <>
                            <p className="price">{store.locale.currency_symbol + " " + event.price.toFixed(2) + " + Booking Fee " + store.locale.currency_symbol + " " + store.licence.ticket_booking_fee.toFixed(2)}</p>

                            {/* Apply Discount Code */}
                            <div className="toggle-voucher-checkout" onClick={() => {
                                this.setState({ isCheckoutVoucherCollapsed: !isCheckoutVoucherCollapsed });
                            }}>
                                {AppContext.r["have-voucher"]}
                                {!isCheckoutVoucherCollapsed ? <ExpandLess /> : <ExpandMore />}
                            </div>

                            <Collapse in={!isCheckoutVoucherCollapsed} timeout="auto" unmountOnExit>
                                <Formik
                                    initialValues={{ voucher_code: "" }}
                                    validationSchema={yup.object({
                                        voucher_code: yup.string().required().min(6)
                                    })}
                                    onSubmit={this.applyVoucherCode}>

                                    {({ isSubmitting, isValid }) => (
                                        <Form className="voucher-application-form">
                                            <Field className="field" component={TextField} name="voucher_code" type="text"
                                                label={AppContext.r["voucher"]} />

                                            <Button type="submit" variant="outlined" disabled={isSubmitting || !isValid}>{AppContext.r["apply"]}</Button>

                                            {isSubmitting && <LinearProgress className="linear-progress" />}
                                        </Form>
                                    )}
                                </Formik>
                            </Collapse>

                            <hr />

                            {store.has_stripe_setup && this.state.promise &&
                                <div className="stripe">
                                    <Elements stripe={this.state.promise}>
                                        <StripeCheckout
                                            store={store}
                                            testMode={store.stripe_test}
                                            request={request}
                                            user={user}
                                            amount={AppContext.s['currency'] + " " + total.toFixed(2)}
                                            onSucceeded={this.checkout} />
                                    </Elements>
                                </div>}
                        </>}
                </>
            )
        }

        return (
            <div className="ticket-card">
                <h2>{event.title}</h2>

                {view}
            </div>);
    }
}

const StoredBuyTicket = withUderlyStore(BuyTicket);

export const BuyTicketButton = (props) => {
    // const layoutContext = useContext(LayoutContext);

    const { store, event, game, authActions, modal } = props;

    const buyTicket = (event) => {
        // layoutContext.dialog.show({
        //     title: AppContext.r["buy-ticket"],
        //     content: <StoredBuyTicket event={event} store={store} authActions={authActions} />,
        //     modal: false
        // });
        modal.setView(AppContext.r["buy-ticket"],
            <StoredBuyTicket event={event} store={store}
                authActions={authActions} modal={modal} />, true);
    }

    if (!event || event.is_free)
        return null;

    return <>
        {(!game || game.game_status < GameStatusEnum.Completed) && event.tickets_left > 0 ?
            <div className="iq-button-link">
                <Button className="buyticket-button " color="primary" variant="contained"
                    onClick={() => buyTicket(event)}>{AppContext.r["buy-ticket"]}</Button>
            </div>
            : <h3>SOLD OUT</h3>}
    </>
}

export default StoredBuyTicket;