import React from 'react';

import AppContext from "../app/AppContext";
import RankingItem from "./RankingItem"

export default class FinalRanking extends React.Component {
    state = {
        players: []
    };

    minInterval = 500;
    maxInterval = 2000;

    interval = null;

    startInterval = (ms = 1000) => {
        this.interval = setInterval(this.pushNextPlayer, ms);
        console.log("Interval STARTED, id: " + this.interval);
    }

    setIntervalMs = (ms) => {
        clearInterval(this.interval);
        this.startInterval(ms);
    }

    componentDidMount() {
        console.log("Final Ranking", this.props.players);

        this.position = this.props.players.length;

        if (this.interval === null) {
            this.interval = -1;

            setTimeout(() => {
                this.startInterval(this.minInterval);
                console.log(this.interval);
            }, 2500);
        }
    }

    pushNextPlayer = () => {
        const revealedPlayers = [...this.state.players];

        // this.position = this.props.players.length - revealedPlayers.length;
        const percent = 1 - (this.position / this.props.players.length);

        console.log(this.position, percent);

        if (this.position > 0) {
            const p = this.props.players[this.position - 1];
            const style = { fontSize: "1em" };

            if (this.position === 1) style.fontSize = "1.3em";
            if (this.position === 2) style.fontSize = "1.1em";
            if (this.position === 3) style.fontSize = "1.1em";

            revealedPlayers.unshift(<RankingItem style={style} position={this.position} key={p.id} player={p} showMedal={true} showScore={true} isPartial={false} />);

            this.setState({
                players: revealedPlayers
            });

            if (this.position >= 0) {
                if (this.position > 2)
                    this.setIntervalMs((this.maxInterval - this.minInterval) * percent + this.minInterval);
                else
                    this.setIntervalMs(300);

                this.position--;
            }
        } else {
            clearInterval(this.interval);
        }
    }

    render() {
        return (
            <div className="ranking-container">
                <div className="final ranking">
                    <h1>{AppContext.r["final-ranking"]}</h1>

                    {this.state.players}
                </div>
            </div>
        );
    }
}
