// Version 1.0

import React, { Component } from 'react';

// https://css-tricks.com/building-progress-ring-quickly/

export default class ProgressRing extends Component {
    constructor(props) {
        super(props);

        const { radius, stroke } = this.props;

        this.normalizedRadius = radius - stroke * 2;
        this.circumference = this.normalizedRadius * 2 * Math.PI;
    }

    render() {
        const { radius, stroke, progress } = this.props;

        const strokeDashoffset = this.circumference - progress / 100 * this.circumference;

        return (
            <svg className="timer-progress-bar"
                height={radius * 2}
                width={radius * 2}>
                <circle
                    stroke="white"
                    fill="transparent"
                    strokeWidth={stroke}
                    strokeDasharray={this.circumference + ' ' + this.circumference}
                    style={{ strokeDashoffset }}
                    r={this.normalizedRadius}
                    cx={radius}
                    cy={radius} />
            </svg>
        );
    }
}