/*
    1  Standard
    2  Uderly
    3  NoProfit
    4  Agreement
*/

export default class LicencesEnum {
    static get Standard() { return 1; }
    static get Uderly() { return 2; }
    static get NoProfit() { return 3; }
    static get Agreement() { return 4; }
}
