import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import BusinessIcon from '@mui/icons-material/Business';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { useUderlyStore } from '@uderly/react-uderly-ui/zustand';
import withUderlyStore from "../../zustand/withUderlyStore";
import AppContext from 'app/AppContext.js';
import ContactForm from '../../uderly/components/ContactForm';
import Countdown from '../../framework/Countdown';
// import Game, {ViewModeStatesEnum} from "quiz-components/Game";
import CheckList from './_CheckList';
import Separator from "app/components/Separator";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const StartButton = () => {
    const { language } = useUderlyStore((state) => {
        return { language: state.language };
    });

    return (<Link className="iq-button-link" to="/play">
        <Button variant="outlined"><img src={AppContext.s["mini-logo-dark"]} alt="Mini Logo" /> {AppContext.r['try-it-now']}</Button>

        {language === "it" ?
            <p className="small">Puoi iniziare subito con IQ Space nella sua licenza standard.<br />Contattaci per richieste speciali o se desideri ulteriori informazioni.</p>
            : <p className="small">You can start now with IQ Space in its standard licence.<br />Contact us for any special requests or additional info.</p>}
    </Link>);
}

class Business extends React.Component {
    endDate = Date.parse("2021-09-19 23:59:59");

    state = {
        timespan: 0,
        expirationDate: "",
        showAlert: null,
        language: this.props.zustand.language
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.zustand.language !== this.state.language) {
            this.setState({
                language: nextProps.zustand.language
            });
        }
    }

    async componentDidMount() {
        if (this.props.updateLayout)
            this.props.updateLayout();

        if (!AppContext.isLocalhost) {
            const body = document.querySelector('#root');

            body.scrollIntoView({
                behavior: 'smooth'
            }, 500);
        }

        this.remaining = 24;

        const m = "07";
        let d = 19;

        if (new Date() > Date.parse(`2021-${m}-${d} 10:34:00`)) { this.remaining = 22; } d += 2;
        if (new Date() > Date.parse(`2021-${m}-${d} 12:34:00`)) { this.remaining = 16; } d += 1;
        if (new Date() > Date.parse(`2021-${m}-${d} 16:34:00`)) { this.remaining = 13; } d += 3;
        if (new Date() > Date.parse(`2021-${m}-${d} 11:34:00`)) { this.remaining = 10; } d += 0;
        if (new Date() > Date.parse(`2021-${m}-${d} 15:34:00`)) { this.remaining = 7; } d += 2;
        // if (new Date() > Date.parse(`2021-${m}-${d} 12:34:00`)) { this.remaining = 15;}
        // if (new Date() > Date.parse(`2021-${m}-${d} 16:34:00`)) { this.remaining = 10;}
        // // if (new Date() > Date.parse(`2021-${m}-${d} 00:34:00`)) { this.remaining = 10;}
        // if (new Date() > Date.parse(`2021-${m}-${d} 00:34:00`)) { this.remaining = 9;}
        // if (new Date() > Date.parse(`2021-${m}-${d} 00:34:00`)) { this.remaining = 5;}
        if (new Date() > Date.parse(`2021-${m}-${d} 00:34:00`)) { this.remaining = 2; }

        //const response = await CoreApi.Now();
        //console.log(response);

        // if(response && response.data) {
        var timestamp = this.endDate,
            date = new Date(timestamp);
        // datevalues = [
        //     date.getFullYear(),
        //     date.getMonth()+1,
        //     date.getDate(),
        //     date.getHours(),
        //     date.getMinutes(),
        //     date.getSeconds(),
        // ];
        const now = new Date(); // Date.parse(response.data.utc_datetime)  / 1000;

        this.setState({
            timespan: Math.round((this.endDate - now) / 1000),
            expirationDate: date.getDate() + " " + AppContext.r['months'][date.getMonth()] + " " + date.getFullYear() + " at " + date.getHours() + ":" + date.getMinutes()
        });
        // }

        window.addEventListener("scroll", this.onScroll);

        this.onScroll();
    }

    onScroll = () => {
        // console.log(document.documentElement.scrollTop, window.innerHeight);
        const countdownPromo = document.querySelector("#CountdownPromo");

        if (countdownPromo) {
            const r = countdownPromo.getBoundingClientRect();
            if ((window.innerHeight - r.y) > r.height)
                if (this.state.showAlert === null)
                    setTimeout(() => this.setState({
                        showAlert: true
                    }), 3000);
        }
        // console.log(document.documentElement.scrollTop, window.innerHeight, countdownPromo.getBoundingClientRect().y, (window.innerHeight - countdownPromo.getBoundingClientRect().y));
    }

    onCountdownExpired = () => {
        this.setState({
            timespan: 0
        });
    }

    render() {
        const { timespan, expirationDate, language } = this.state;

        let view = "";

        view = (
            <>
                <Row className="row-0 justify-content-md-center">
                    <Col md={6} className="col-0">
                        <BusinessIcon className="mui-icon large" />

                        <h1>Iq Space for companies <br />and other organizations</h1>

                        <p>The ultimate virtual entertainment platform<br /> for your <b>business</b>.</p>
                    </Col>

                    <Col md={6}>
                        <img className="team-cover" src="/assets/images/people.jpg" alt="team" />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p className="text-center">Are you a business owner or an entrepreneur looking to skyrocket the business?</p>
                        
                        <StartButton />
                    </Col>
                </Row>

                <Row className="row-1">
                    <Col sm={4}>
                        <h3>Our Tech</h3>
                        <img className="icon" src="/assets/iqspace/images/live-blue-mic.png" alt="live" />

                        <div className="texts-block">
                            <p>The ultimate <b>live quiz</b> for your business entertainment, development and promotion.</p>
                            <p>Engage your own crowd, entertaining either live or remotely.</p>
                        </div>
                    </Col>

                    <Col sm={4}>
                        <h3>Team Building</h3>
                        <img className="icon" src="/assets/iqspace/images/network.png" alt="live" />

                        <div className="texts-block">
                            <p>The ultimate <b>team-bulding</b> tool.</p>
                            <p>Get your team together, let them chat, enjoy and feel the competition.</p>
                            <p>Connect people through the web.</p>
                        </div>
                    </Col>

                    <Col sm={4}>
                        <h3>Promote</h3>
                        <img className="icon" src="/assets/iqspace/images/promote.png" alt="live" />

                        <div className="texts-block">
                            <p>The ultimate customizable <b>promotional</b> tool.</p>
                            <p>Play custom multimedia content to question.</p>
                            <p>Promote and inform about your business.</p>
                        </div>
                    </Col>
                </Row>

                <Separator />

                <Row className="bond-night-images">
                    <Col>
                        <img src="/assets/images/bond-night-console.jpg" alt="Bond Night Console" />
                    </Col>

                    <Col>
                        <img src="/assets/images/bond-night-lounge.jpg" alt="Bond Night Lounge" />
                    </Col>
                </Row>

                {/* <ReactPlayer url={"https://www.youtube.com/watch?v=XgSk2ABiWmU"} className="player"
                            width={640} height={360} controls={true}
                            muted={true} playing={true} loop={true} />
                
                <Row className="row-7">
                    <Col>
                        <h1>AUTOMATION</h1>
                        <p>Would you like to entertain with fun, interactive and <b>completely automated</b> real-time games?</p>
                        <i className="fas fa-magic"></i>
                        <h3>We can automate IQ Space for you!</h3>
                        
                        <div className="embedded-quiz-container">
                            <Game viewMode={ViewModeStatesEnum.Widget} id={3} />
                        </div>

                        <Button href="/event/3" target="_blank">Play the demo</Button>
                        <p>Starting from £ 29 a month. | <Link to="/automation">{AppContext.r["learn-more"]}</Link></p>
                    </Col>
                </Row>

                <Row className="row-3 justify-content-md-center">
                    <Col xs={12}>
                        <h1>IQ SPACE FOR NON-PROFIT</h1>
                    </Col>

                    <Col md={8}>
                        <h3>Are you a non-profit organization?</h3>
                        <p>IQ Space will spice up your events and you might get it at special conditions.</p>
                        <br />
                        <p className="small">Some of our non-profit partners:</p>
                    </Col>

                    <Col xs={12} md={10} className="partners">
                        <a href="http://www.rotaract2041.com" target="_blank" rel="noopener noreferrer"
                            className="rotaract">
                            <img src="/assets/images/partners/rotaract2041.png" alt="Rotaract 2041" />
                        </a>
                        <a href="https://www.aegee.org/" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/images/partners/aegee-europe.gif" alt="AEGEE Europe" />
                        </a>
                        <a href="https://aegeemalaga.org/" target="_blank" rel="noopener noreferrer">
                            <img src="/assets/images/partners/aegee-malaga.png" alt="AEGEE Malaga" />
                        </a>
                    </Col>
                </Row> */}

                <hr className="separator" />

                {timespan > 0 && expirationDate &&
                    <>
                        <div id="CountdownPromo" className="promo-banner">
                            <h3 className="promo-title">Grand Opening Promotion</h3>

                            <div className="promo-balloon">
                                {/* <p>You might qualify for a <strong>ZERO</strong> initial set up* fee if you request it by</p> */}
                                <p>To the first 25 choosing our service {/* this year */}we will offer a completely <strong>FREE</strong> fully-assisted setup and a custom <b>category</b> of your choice.</p>
                                <Countdown showLabel={false} timespan={timespan} onExpired={this.onCountdownExpired} />

                                <p className="expiration-date"><b>Hurry up!</b> It expires on {expirationDate}</p>

                                <Button href="#contactUs">Contact us</Button>
                            </div>
                            {/* <p className="text-center">*according to our <a href="/terms#freefee">Terms & Conditions</a></p> */}
                        </div>

                        <Snackbar open={this.state.showAlert} autoHideDuration={null} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                            <Alert onClose={() => this.setState({ showAlert: false })} severity="warning">
                                Only {this.remaining} left.
                            </Alert>
                        </Snackbar>
                    </>}

                <Row className="row-2 justify-content-md-center">
                    <Col md={6} lg={7}>
                        <h3>Not convinced yet?</h3>

                        <CheckList business />
                    </Col>

                    <Col md={6} lg={5}>
                        <h3>Ready?</h3>

                        <div className="ready-list">
                            <div className="image-box">
                                <img src="/assets/images/innovation.jpg" alt="Innovation" />
                            </div>

                            <StartButton />
                        </div>
                    </Col>
                </Row>

                <Row className="row-4 justify-content-center">
                    <Col xs={12}>
                        <h3>Need a chat?</h3>
                        <PhoneInTalkIcon className="mui-icon big" />
                        <p>We can arrange a 30-minute <b>free consultation</b> on the phone with you.</p>
                        <br />
                        <hr />
                    </Col>

                    <Col md={12} className="custom-solutions" id="business-contactus">
                        <br />
                        <h5>Be the difference, create che difference.</h5>
                        <p>Do you wish a customized version?</p>

                        <br />
                        <h2 id="contactUs">{AppContext.r['contact']}</h2>
                        <Separator />

                        <ContactForm />
                    </Col>
                </Row>

                <Row className="row-5">
                    <Col>
                        <h3>Got a great idea and don't know where to start?</h3>
                        <p>At Uderly, we can guide you from the design to the deployment of your project.</p>

                        <a href="https://www.uderly.com" target="_blank" rel="noopener noreferrer" className="uderly">
                            <img src="/assets/images/uderly-payoff.png" alt="Uderly" />
                        </a>
                    </Col>
                </Row>
            </>
        );

        if (language === "it") {
            view = (
                <>
                    <Row className="row-0 justify-content-md-center">
                        <Col md={6} className="col-0">
                            <BusinessIcon className="mui-icon large" />

                            <h1>{AppContext.r["iqspace-for-business"]}</h1>

                            <p>La piattaforma di intrattenimento definitiva <br /> per il tuo <b>business</b>.</p>
                        </Col>

                        <Col md={6}>
                            <img className="team-cover" src="/assets/images/people.jpg" alt="team" />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p className="text-center">Sei un imprenditore alla ricerca di nuove soluzioni per mandare in orbita il tuo business?</p>

                            <StartButton />
                        </Col>
                    </Row>

                    <Row className="row-1">
                        <Col sm={4}>
                            <h3>La nostra tecnologia</h3>
                            <img className="icon" src="/assets/iqspace/images/live-blue-mic.png" alt="live" />

                            <div className="texts-block">
                                <p>Il <b>live quiz</b> definitivo per il tuo intrattenimento di business, sviluppo e promozione.</p>
                                <p>Coinvolgi la tua audience ed intrattieni online o dal vivo.</p>
                            </div>
                        </Col>

                        <Col sm={4}>
                            <h3>Team Building</h3>
                            <img className="icon" src="/assets/iqspace/images/network.png" alt="live" />

                            <div className="texts-block">
                                <p>Lo strumento di <b>team-bulding</b> definitivo.</p>
                                <p>Riunisci il tuo team, si divertiranno a chattare e a competere tra di loro.</p>
                                <p>Connetti le persone grazie al web.</p>
                            </div>
                        </Col>

                        <Col sm={4}>
                            <h3>Promuovi</h3>
                            <img className="icon" src="/assets/iqspace/images/promote.png" alt="live" />

                            <div className="texts-block">
                                <p>Lo strumento <b>promozionale</b> definitivo e personalizzabile.</p>
                                <p>Mostra contenuti multimediali personalizzati nei tuoi quiz.</p>
                                <p>Promuovi ed informa riguardo il tuo business.</p>
                            </div>
                        </Col>
                    </Row>

                    <Separator />

                    <Row className="bond-night-images">
                        <Col>
                            <img src="/assets/images/bond-night-console.jpg" alt="Bond Night Console" />
                        </Col>

                        <Col>
                            <img src="/assets/images/bond-night-lounge.jpg" alt="Bond Night Lounge" />
                        </Col>
                    </Row>

                    {/* <Row className="row-7">
                        <Col>
                            <h1>AUTOMAZIONE</h1>
                            <p>Ti piacerebbe intrattenere con divertenti, interattivi e <b>completamente automatizzati</b> giochi in real-time?</p>
                            <i className="fas fa-magic"></i>
                            <h3>Possiamo automatizzare IQ Space per te!</h3>
                            
                            <div className="embedded-quiz-container">
                                <Game viewMode={ViewModeStatesEnum.Widget} id={2} />
                            </div>

                            <Button href="/event/2" target="_blank">Gioca la demo</Button>
                            <p><small>A partire da 29 € al mese. | <Link to="/automation">{AppContext.r["learn-more"]}</Link></small></p>
                        </Col>
                    </Row>
                
                    <Row className="row-3 justify-content-md-center">
                        <Col xs={12}>
                            <h1>IQ SPACE PER ORGANIZZAZIONI NO-PROFIT</h1>
                        </Col>

                        <Col md={8}>
                            <h3>Fai parte di una organizzazione no-profit?</h3>
                            <p>IQ Space renderà i tuoi eventi entusiasmanti e potresti averlo a condizioni molto speciali.</p>
                            <br />
                            <p className="small">Alcuni dei nostri partner no-profit:</p>
                        </Col>

                        <Col xs={12} md={10} className="partners">
                            <a href="http://www.rotaract2041.com" target="_blank" rel="noopener noreferrer"
                                className="rotaract">
                                <img src="/assets/images/partners/rotaract2041.png" alt="Rotaract 2041" />
                            </a>
                            <a href="https://www.aegee.org/" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/images/partners/aegee-europe.gif" alt="AEGEE Europe" />
                            </a>
                            <a href="https://aegeemalaga.org/" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/images/partners/aegee-malaga.png" alt="AEGEE Malaga" />
                            </a>
                        </Col>
                    </Row> */}

                    <hr className="separator" />

                    {timespan > 0 && expirationDate &&
                        <>
                            <div id="CountdownPromo" className="promo-banner">
                                <h3 className="promo-title">Promozione Grande Lancio</h3>

                                <div className="promo-balloon">
                                    {/* <p>You might qualify for a <strong>ZERO</strong> initial set up* fee if you request it by</p> */}
                                    <p>Ai primi 25 a scegliere il nostro servizio {/* this year */} offriremo un set-up iniziale <strong>GRATUITO</strong> completamente assistito e una <b>categoria</b> personalizzata a tua scelta.</p>
                                    <Countdown showLabel={false} timespan={timespan} onExpired={this.onCountdownExpired} />

                                    <p className="expiration-date"><b>Affrettati!</b> Scade il {expirationDate}</p>

                                    <Button href="#contactUs">{AppContext.r["contact-us"]}</Button>
                                </div>
                                {/* <p className="text-center">*according to our <a href="/terms#freefee">Terms & Conditions</a></p> */}
                            </div>

                            <Snackbar open={this.state.showAlert} autoHideDuration={null} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                                <Alert onClose={() => this.setState({ showAlert: false })} severity="warning">
                                    Solo {this.remaining} rimanenti.
                                </Alert>
                            </Snackbar>
                        </>}

                    <Row className="row-2 justify-content-md-center">
                        <Col md={6} lg={7}>
                            <h3>Non sei ancora sicuro?</h3>

                            <CheckList business />
                        </Col>

                        <Col md={6} lg={5}>
                            <h3>Pronto?</h3>

                            <div className="ready-list">
                                <div className="image-box">
                                    <img src="/assets/images/innovation.jpg" alt="Innovation" />
                                </div>
                                
                                <StartButton />
                            </div>
                        </Col>
                    </Row>

                    <Row className="row-4 justify-content-center">
                        <Col xs={12}>
                            <h3>Ne vuoi discutere?</h3>
                            <PhoneInTalkIcon className="mui-icon big" />
                            <p>Prenota una <b>consultazione gratuita</b> telefonica di 30 minuti.</p>
                            <br />
                            <hr />
                        </Col>

                        <Col md={12} className="custom-solutions">
                            <br />
                            <h5>Sii la differenza, crea la differenza.</h5>
                            <p>Desideri una versione personalizzata?</p>

                            <br />
                            <h2 id="contactUs">{AppContext.r["contact-us"]}</h2>
                            <Separator />

                            <ContactForm />
                        </Col>
                    </Row>

                    <Row className="row-5">
                        <Col>
                            <h3>Hai una grande idea e non sai da dove iniziare?</h3>
                            <p>Noi di Uderly possiamo guidarti dal design alla pubblicazione del tuo progetto.</p>

                            <a href="https://www.uderly.com" target="_blank" rel="noopener noreferrer" className="uderly">
                                <img src="/assets/images/uderly-payoff.png" alt="Uderly" />
                            </a>
                        </Col>
                    </Row>
                </>);
        }

        return (
            <Container fluid className="page organizations">
                {view}

                <div className="row-6">
                    <p className="tag-line">{AppContext.r["iqspace-tagline"]}</p>
                </div>

                <div className="row-footer">
                    <hr className="medium" style={{ width: "auto" }} />
                    <p><small>{AppContext.r['prices-exclude-vat']}</small></p>
                </div>

                {/* {!AppContext.isLocalhost && 
                    <MessengerCustomerChat htmlRef={window.location.pathname}
                        pageId={AppContext.s["facebook-page-id"]} appId={AppContext.s["facebook-app-id"]} /> } */}
            </Container>);
    }
}

export default withUderlyStore(Business);