// Version 1.0

import React from 'react';

import ProgressRing from './ProgressRing';

export default class Timer extends React.Component {
    constructor(props) {
        super(props);

        this.durationMs = (typeof (props.durationMs) !== "undefined") ? props.durationMs : 10000;

        this.state = {
            //durationMs: this.durationMs,
            progress: 0,
            elapsedMs: 0,
            countdown: parseInt(this.durationMs / 1000)
        };

        this.startTimerTime = Date.now();
    }

    static getDerivedStateFromProps(props, state) {
        // Store prevId in state so we can compare when props change.
        // Clear out previously-loaded data (so we don't render stale stuff).

        //if (props.durationMs != state.elapsedMs) {
        //    console.log(props.durationMs, state.elapsedMs);

        //    return {
        //        elapsedMs: props.durationMs
        //    };
        //}

        // No state update necessary
        return null;
    }

    componentDidMount() {
        if (this.interval != null)
            clearInterval(this.interval);

        this.interval = setInterval(() => {
            const elapsedMs = Math.min(this.durationMs, Date.now() - this.startTimerTime);

            this.setState({
                elapsedMs: elapsedMs,
                progress: elapsedMs * 100 / this.durationMs,
                countdown: parseInt((this.durationMs - elapsedMs) / 1000)
            });
        }, 100);
    }

    render() {
        if (this.state.countdown < 0)
            return "";

        return (
            <div className="timer" style={{ marginTop: this.props.marginTop ?? 0 }}>
                <ProgressRing radius={36} stroke={4}
                    progress={this.state.progress} />
                <p>{this.state.countdown}</p>
            </div>
        );
    }
}