import React from 'react';
import { t } from 'i18next';

import AppContext from '../AppContext';
import Settings from '../settings';

const getPlaylistColumns = (apiPath: string) => [{
	field: 'name',
	headerName: t('name'),
	minWidth: 100,
	flex: 1,
	sortable: false,
	renderCell: params => {
		const { row } = params;

		return (
			<div className="td-v-center">
				<div className="row-thumb">
					{(row.media && row.media[0]) && <>
						<img src={`${row.media[0].disk === "net" ? Settings.apiUrl : AppContext.s["api-url"]}${apiPath}/${row.id}/image/thumb`} alt="Thumb" />
					</>}
					{(!row.media || !row.media[0]) && <img src={AppContext.s["placeholder"]} className="placeholder-thumb" alt="Thumb" />}
				</div>

				{row.name}
			</div>
		);
	}
},
{
	field: 'questions-count',
	headerName: AppContext.r["questions-count"],
	minWidth: 160,
	sortable: false,
	renderCell: params => {
		const { row } = params;

		return <div className="td-v-center">{`${row.questions_count} ${t('questions')}`}</div>;
	}
}];

export default getPlaylistColumns;