import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Socials, BorderShapes } from "@uderly/react-uderly-ui";
import AppContext from "app/AppContext";
import Settings from '../settings';
import withUderlyStore from "../../zustand/withUderlyStore";

class Footer extends React.Component {
    state = {
        language: this.props.zustand.language
    };

    item(x) {
        if (!x || !x.to)
            return null;

        return (
            <ListItem>
                <ListItemIcon>
                    <ChevronRightIcon />
                </ListItemIcon>

                {x.to.indexOf("http") === 0 ?
                    <ListItemText
                        primary={<a href={x.to} target="_blank" rel="noreferrer">{x.title}</a>} /> :
                    <ListItemText
                        primary={<Link to={x.to} onClick={() => this.onRoute(x)}>{x.title}</Link>} />}
            </ListItem>);
    }

    onRoute = (x) => {
        if (x.to !== window.location.pathname)
            AppContext.animatePageChange();

        this.onClickMenuItem(x);
    }

    onClickMenuItem = (x) => {
        if (x.scrollTo) {
            const timing = (x.to !== window.location.pathname) ? 500 : 0;

            setTimeout(() => {
                this.scrollTo(x.scrollTo);
            }, timing);
        }
    }

    scrollTo = (selector) => {
        if (selector) {
            const el = document.querySelector(selector);

            if (el) {
                const r = el.getBoundingClientRect();
                window.scrollTo(r.left, r.top + window.scrollY - this.props.navBarHeight);
            }
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.zustand.language !== this.state.language) {
            this.setState({
                language: nextProps.zustand.language
            });
        }
    }

    render() {
        const { user } = this.props.zustand;

        if (this.props.hide)
            return "";

        const map = Settings.siteMap;

        return (
            <footer className="dark">
                <Socials socials={Settings.project.socials} borderShape={BorderShapes.none}
                    className="socials-list" />

                <Row className="map">
                    <Col xs={6} sm={4}>
                        <h2>{AppContext.s["project-name"]}</h2>

                        <List dense={true}>
                            {this.item(map[0])} {/* Home */}
                            {this.item(map[1])} {/* Mission */}
                            {this.item(map[2])} {/* Play */}
                            {this.item(map[4])} {/* Quizmaster */}
                            {this.item(map[5])} {/* Business */}
                            {this.item(map[6])} {/* Pricing*/}
                        </List>
                    </Col>

                    <Col xs={6} sm={4} className="help-and-support">
                        <h2>{AppContext.r["help-and-support"]}</h2>

                        <List dense={true}>
                            {this.item(map[3])}
                            {this.item({ title: AppContext.r["privacy-policy"], to: "/privacy" })}
                            {this.item({ title: AppContext.r["terms"], to: "/terms" })}
                            {this.item({ title: AppContext.r["contact"], to: "/", scrollTo: "#contact" })}
                            {this.item({ title: AppContext.r["refunds"], to: "/refunds" })}
                        </List>
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    <a className="logo-footer" href="https://uderly.com" target="_blank" rel="noreferrer">
                        <img className="logo-footer" src={"/assets/images/powered-by-uderly-white.png"} alt="Uderly" />
                    </a>
                </Row>

                <Socials />

                <Row className="justify-content-center">
                    <div className="signature">
                        <p>Copyright © <b>Uderly Ltd</b> (No: 12293155. Registered in England and Wales.)</p>

                        {!user &&
                            <div className="languages">
                                <Button variant="link" onClick={() => { this.props.zustand.setLanguage("en"); window.location.reload(); }}
                                    style={{ opacity: (this.state.language === "en") ? 1 : 0.5 }}>
                                    <img src="/assets/images/uk-flag.svg" alt="English" />
                                </Button>

                                <Button variant="link" onClick={() => { this.props.zustand.setLanguage("it"); window.location.reload(); }}
                                    style={{ opacity: (this.state.language === "it") ? 1 : 0.5 }}>
                                    <img src="/assets/images/italy-flag.svg" alt="Italian" />
                                </Button>
                            </div>}
                    </div>
                </Row>
            </footer>
        );
    }
}


export default withUderlyStore(Footer);