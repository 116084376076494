import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Browser } from "react-window-ui";
import Slider from "react-slick";

import ContactForm from '../../uderly/components/ContactForm';
import Separator from '../components/Separator';
import { StartButton } from './Business';
import Logo from '../../uderly/components/Logo';

const Quizmaster = (props) => {
  const { t, i18n } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true
  };

  useEffect(() => {
    if (props.updateLayout)
      props.updateLayout();
  }, [props]);

  return <Grid container className="quizmaster page target-width ">
    <Grid item sm={4}>
      <div className="animated-bg-title">
        <section>
          <Logo />
          <div className='air air1'></div>
          <div className='air air2'></div>
          <div className='air air3'></div>
          <div className='air air4'></div>
        </section>

        {i18n.language === "it" ?
          <h1>Il tuo evento di intrattenimento animato e brillante!</h1>
          : <h1>Your lively, bustling entertainment event!</h1>}
      </div>
    </Grid>

    <Grid item sm={8} className="content">
      {i18n.language === "it" ?
        <>
          <h1>Chiamata a tutti gli intrattenitori</h1>

          <p>Sei un intrattenitore in cerca di nuove opportunità?</p>
          <h3>Unisciti a IQ Space, la piattaforma rivoluzionaria che sta cambiando il gioco dell'intrattenimento!</h3>
          <p>Con IQ Space, potrai risolvere le tue sfide legate all'intrattenimento, raccogliere dati preziosi e sbloccare entusiasmanti nuove opportunità.</p>
          <p>Trasforma la tua passione in profitto con i nostri strumenti di marketing, analitiche e suggerimenti basati sui dati.</p>
          <p>Immagina di sfruttare la tecnologia più recente per analizzare le preferenze del pubblico, rendendo le tue performance più accattivanti che mai.</p>
          <p>Non siamo solo intrattenimento: siamo innovazione!</p>
          <p>La nostra intuitiva piattaforma ti consente di gestire facilmente i tuoi eventi, vendere biglietti e connetterti con un pubblico più ampio.</p>

          <p><b>Iscriviti ora</b> e inizia il tuo percorso di successo nell'intrattenimento!</p>

          <StartButton />

          <p>Unisciti a una comunità di talentuosi intrattenitori che hanno già trovato successo con IQ Space.</p>
        </> :
        <>
          <h1>Calling all entertainers</h1>

          <p>Are you an entertainer or quizmaster looking for new opportunities to shine?</p>
          <h3>Join IQ Space, the groundbreaking platform that's changing the game in entertainment!</h3>
          <p>With IQ Space, you have the power to solve entertainment challenges, collect valuable data, and unlock exciting new opportunities.</p>
          <p>Turn your passion into profit with our state-of-the-art analytics, marketing tools, and data-driven insights.</p>
          <p>Imagine harnessing the latest technology to analyze audience preferences, making your performances more captivating than ever.</p>
          <p>We're not just about entertainment: we're about <b>innovation</b>!</p>
          <p>Our intuitive platform lets you effortlessly manage your events, sell tickets, and connect with a wider audience.</p>

          <p><b>Sign up now</b> and start your journey towards entertainment success!</p>

          <StartButton />

          <p>Join a community of talented entertainers and quizmasters who have already found success with IQ Space.</p>
        </>}

      <Browser className="browser mobile-hidden">
        <Slider {...settings}>
          <img src="/assets/images/event-management1.jpg" alt="Event management" />
          <img src="/assets/images/event-management2.jpg" alt="Event management" />
        </Slider>
      </Browser>

      <Slider className="mobile-only" {...settings}>
        <div className="slide">
          <img src="/assets/images/event-management1.jpg" alt="Event management" />
        </div>

        <div className="slide">
          <img src="/assets/images/event-management2.jpg" alt="Event management" />
        </div>
      </Slider>
    </Grid>

    <Grid item sm={12} className="bottom">
      <img className="full-width" src="/assets/images/iq-space-questionmarks.jpg" alt="IQ Space question marks" />
      <h2 className='text-center'>{t('contact')}</h2>
      <Separator />
      <ContactForm />
    </Grid>
  </Grid>;
}

export default Quizmaster;