import Axios, { AxiosRequestConfig } from 'axios';

import Settings from "../app/settings";
import { errorLog } from '@uderly/react-uderly-ui/api';

export const onBoard = async (storeId: number) => {
  const data = {
    store_id: storeId,
    refresh_url: window.location.href,
    return_url: window.location.href
  };

  return await Axios.post(Settings.apiUrl + "/stripe/connect/onboard", data)
    .catch((error) => {
      console.log('error ' + error);
      return errorLog(error);
    });
}

export const retrieveAccount = async () => {
  return await Axios.get(Settings.apiUrl + "/stripe/connect/retrieveaccount")
    .catch((error) => {
      console.log('error ' + error);
      return errorLog(error);
    });
}

export const loginLink = async () => {
  return await Axios.post(Settings.apiUrl + "/stripe/connect/loginlink")
    .catch((error) => {
      console.log('error ' + error);
      return errorLog(error);
    });
}

export const paymentMethods = async (storeId: number) => {
  console.log(Settings.apiUrl + "/stripe/payments/methods?store_id=" + storeId)
  return await Axios.get(Settings.apiUrl + "/stripe/payments/methods?store_id=" + storeId)
    .catch((error) => {
      console.log('error ' + error);
      return errorLog(error);
    });
}

export const detachPaymentMethod = async (storeId: number, paymentMethod: number) => {
  const config = {
    data: {
      payment_method: paymentMethod,
      store_id: storeId
    }
  };

  return await Axios.delete(Settings.apiUrl + "/stripe/payments/methods/detach", config)
    .catch((error) => {
      console.log('error ' + error);
      return errorLog(error);
    });
}

export const refundTicket = async (attendance: any) => {
  return await Axios.post(Settings.apiUrl + "/events/attendances/" + attendance.id + "/refund")
    .catch((error) => {
      console.log('error ' + error);
      return errorLog(error);
    });
}

export const createTicketPaymentIntent = async (eventId: number, request: any, cancelToken: any = null) => {
  const config: AxiosRequestConfig = {};

  if (cancelToken)
    config.cancelToken = cancelToken;

  return await Axios.post(Settings.apiUrl + "/events/attendances/" + eventId + "/buy", { ...request }, config)
    .catch((error) => {
      console.log('error ' + error);
      return errorLog(error);
    });
}
