import React, { useState } from "react";
import { QRCode } from 'react-qrcode-logo';
import { Link } from "react-router-dom";

import Countdown from './Countdown';
import InterestedButton from "../app/components/InterestedButton";
import { BuyTicketButton } from "../app/components/BuyTicket";
import { IqEvent } from "../models/Event";
import GameStatus from "../models/GameStatus";
import AppContext from '../app/AppContext';
import Settings from "../app/settings";
import { ViewModeStatesEnum, gameStatusToClassName } from "./Game";
import { textSplitter } from "../app/utils";

export interface PreEventProps {
	event: IqEvent;
	viewMode: number;
	modal: any; // TODEL
	authActions: any; // TODEL
	gameStatus: GameStatus;
}

const StartView = ({ event, gameStatus, viewMode, modal, authActions }: PreEventProps) => {
	const [animated, setAnimated] = useState(false);

	const spaceUrl = `${Settings.project.web!.domain}/${event.store.subdomain}`;

	const onAnimationEnd = () => {
		console.log("onAnimationEnd");

		// setAnimated(false);
	}

	// Adaptive font size between 1 and 2 em
	const fontSize = Math.max(1, Math.min(200 / event.title.length, 2));

	return (<div className={`start-view ${gameStatusToClassName(gameStatus)} ${animated ? "animated" : ""}`}>
		<div>
			{viewMode === ViewModeStatesEnum.Recorder &&
				<>
					<img className="event-image" onAnimationEnd={onAnimationEnd}
						src={Settings.apiUrl + "/events/" + event.id + "/image"} alt={event.title} />

					<div className="split-title">
						{textSplitter(event.title, event.title.length / 2, 20, { fontSize: `${fontSize}em` })}
					</div>
				</>}

			{viewMode !== ViewModeStatesEnum.Recorder &&
				<>
					<div>
						{!event.published &&
							<p className="glamour-info-box">{AppContext.r["event-not-published"]}</p>}
					</div>

					<div>
						{(event.media && event.media.length > 0) &&
							<img className="event-image"
								src={Settings.apiUrl + "/events/" + event.id + "/image"} alt={event.title} />}

						<div className="split-title">
							{textSplitter(event.title, event.title.length / 2, 20, { fontSize: `${fontSize}em` })}
						</div>
					</div>

					<div>
						<Countdown timespan={event.countdown}
							showLabel={true} label={AppContext.r['on-air-in']} />

						{viewMode === ViewModeStatesEnum.Mobile &&
							<div className="game-actions">
								<InterestedButton eventAttendance={event.myAttendance} event={event}
									authActions={authActions} />

								<BuyTicketButton event={event} game={gameStatus} store={event.store}
									authActions={authActions} modal={modal} />
							</div>}

						<div className="game-hosted-by">
							<p>{AppContext.r['game-hosted-by']}</p>
							<Link to={"/" + event.store.subdomain}>{event.store.name}</Link>
						</div>
					</div>
				</>}

			{/* {viewMode === ViewModeStatesEnum.Recorder &&
				<img className="event-image"
					src={Settings.apiUrl + "/events/" + event.id + "/image"} alt={event.title} />} */}
		</div>

		{viewMode === ViewModeStatesEnum.BigScreen &&
			<div className="invite-players">
				<p>{AppContext.r['access-instructions']}</p>
				<h1>{spaceUrl}</h1>
				<p>{AppContext.r['access-instructions-qr']}</p>

				<QRCode value={"https://" + spaceUrl} size={256}
					logoImage={Settings.project.logo?.mini} logoWidth={256 * 0.2} />
			</div>}

	</div>);
}

export default StartView;