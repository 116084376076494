import React, { ReactNode, useEffect, useRef, useState } from "react";
import { textSplitter } from "../utils";

export interface WordsProps {
	text: string;
	durationMs: number;
	loop?: boolean;
	clearLastToken?: boolean;
}

const Words = ({ text, durationMs, loop, clearLastToken }: WordsProps) => {
	const [words, setWords] = useState<ReactNode[]>([]);
	const [tokenIndex, setTokenIndex] = useState<number>(0);

	const timeout = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		const jsx = textSplitter(text, 7);

		setWords(jsx);

		if (timeout.current != null) {
			clearTimeout(timeout.current);
		}

		timeout.current = setTimeout(() => {
			if (tokenIndex >= 0 && (tokenIndex + 1) < words.length)
				setTokenIndex(tokenIndex + 1);
			else {
				if (clearLastToken)
					setTokenIndex(loop ? 0 : -1);
				clearTimeout(timeout.current);
			}
		}, durationMs / jsx.length);
	}, [durationMs, loop, text, tokenIndex, words.length]);

	return <div className="words">{tokenIndex >= 0 && words[tokenIndex]}</div>;
}

export default Words;