import React from 'react';

import Settings from '../../settingsOld';

export default function loadResources (resources) {
    resources['first-name'] = "Nome";
    resources['about-you-desc'] = "Qualcosa su di TE";
    resources['hello'] = "Ciao";
    resources['signup'] = "Registrati";
    resources['dont-you-have-an-account'] = "Non hai un account?";
    resources['choose-nickname'] = "Scegli un nickname";
    resources['access-with-facebook'] = "Accedi con Facebook";
    resources['or-signup-via-email'] = "o registrati via e-mail";
    resources['or-signin-via-email'] = "o accedi via e-mail";
    resources['invalid-email'] = "L'email non è valida.";
    resources['invalid-name'] = "Il nome inserito non è valido.";
    resources['name-required'] = "Il nome è un campo obbligatorio.";
    resources['surname-required'] = "Il cognome è un campo obbligatorio.";
    resources['email-required'] = "L'email è un campo obbligatorio.";
    resources['password-min-length'] = "La password deve essere lunga almeno " + Settings["password-min-length"] + " caratteri.";
    resources['password-required'] = "La password è un campo obbligatorio.";
    resources['password-must-math'] = "Le password inserite non coincidono.";
    resources['phone-not-valid'] = "Il numero inserito non è valido.";
    resources['anonymous-player'] = "Utente Anonimo";
    resources['confirm-password-required'] = "Conferma la password.";
    resources['confirm-password'] = "Conferma la password";
    resources['login'] = "Accedi";
    resources['logout'] = "Esci";
    resources["l-forgot-password"] = "Ho dimenticato la password";
    resources["l-wrong-credentials"] = (
        <>
            <p>Le credenziali immesse non sono valide.</p>
            <p>Controlla l'e-mail e la password e prova di nuovo ad accedere.</p>
        </>);
    resources['account-created'] = <div><p>Fantastico. Il tuo account <i>{Settings["project-name"]}</i> è stato creato con successo.</p><p>Benvenuto su <span>{Settings["project-name"]}</span>.</p></div>
    resources['account-not-created'] = <div><p>Si è verificato un problema nella creazione del tuo account. L'email che hai usato potrebbe già essere registrata con noi. Prova ad effettuare il login o a specificare un'altra e-mail.</p></div>
    resources["something-wrong"] = "Qualcosa è andato storto. Se non riesci a risolvere, contattaci.";
    resources["social-login-failed"] = "Qualcosa è andato storto. Probabilmente il tuo account social non ha una e-mail valida associata.";
    resources["server-not-working"] = "Il server non ha risposto correttamente alla tua richiesta. Riprova tra un po' o prova a contattare l'amministrazione.";
    resources["change-password"] = "Cambia Password";
    resources["delete-account"] = "Cancella Account";
    resources["delete-account-message"] = "Cancella permanentemente il tuo account " + Settings["project-name"] + " e l'informazione associata.";
    resources["delete-account-desc"] = (<div>
        <p>Questa operazione cancellerà <b>permanentemente</b> il tuo account e tutti i dati associati.</p>
        <p>Per confermare che desideri procedere con questa operazione, digita la tua password e clicca su "{resources["delete-account"]}".</p>
        <p>Tutti i tuoi dati verranno rimossi dai nostri server ai sensi delle regolamentazioni GDPR e <b>non saranno ripristinabili</b>.</p>
    </div>);
    resources["old-password"] = "Password Corrente";
    resources["new-password"] = "Nuova Password";
    resources["new-password-confirmation"] = "Ripeti Nuova Password";
    resources["all-fields-required"] = "E' richiesto l'inserimento di tutti i campi.";
    resources["password-desc"] = "La nuova password deve essere costituita da almeno " + Settings["password-min-length"] + " caratteri e deve essere inserita 2 volte.";
    resources["wrong-password"] = "La password inserita non è valida.";
    resources["passwords-must-match"] = "Le password inserite non coincidono.";
    // resources['account-deleted'] = "Il tuo account è stato cancellato. Puoi sempre registrarti nuovamente se lo desideri.";
    resources["account-deleted"] = (
        <>
            <p>Il tuo account è stato cancellato.</p>
            <p>Ci dispiace vederti andar via.</p>
            <p>Se dovessi decidere di riutilizzare il nostro servizio puoi sempre decidere di registrarti nuovamente.</p>
        </>);
    // resources["must-be-logged"] = "Devi essere loggato per accedere a questa sezione.";
    resources['you-are-not-logged-in'] = <div><p>Non hai effettuato l'accesso.</p><p>Accedi o registrati adesso.</p></div>;
    resources["my-account"] = "Il tuo account";
    resources["status"] = "Stato";
    resources["password-forgotten"] = "Hai dimenticato la password?";
    resources["request-reset-password"] = "Richiedi Reimpostazione Password";
    resources["reset-password"] = "Reimposta Password";
    resources["password-reset-requested"] = <div>
        <b className="primary-color">Controlla la posta!</b><br/>
        <p>Hai richiesto la reimpostazione della tua password. Se l'e-mail che hai inserito è valida riceverai le istruzioni successive via e-mail.</p>
    </div>;
    resources["unprocessable-reset"] = "Non è stato possibile reimpostare la password. Controlla di aver inserito correttamente la tua e-mail o richiedi nuovamente il link di reimpostazione password.";
    resources["password-resetted"] = "La tua password è stata reimpostata con successo.";
    resources["account-verified"] = "Perfetto! Il tuo account è stato verificato.";
}