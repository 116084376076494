export default function loadClientResources (resources) {
    // Forms
    resources["field-too-short"] = "Too short";
    resources["field-too-long"] = "Too long";
    resources["field-required"] = "Required Field";
    resources["invalid-email"] = "Invalid e-mail";
    resources['phone-not-valid'] = "Phone number not valid.";
    resources["request-form-accept"] = "I have read and accept privacy policy, terms & conditions and data treatment.";
    resources["request-form-accept-newsletter"] = "Do you agree to receive periodic newsletters of information and marketing from us?";
    resources["full-name"] = "Full Name";
    resources["company-name"] = "Company Name";
    resources["email"] = "e-mail";
    resources["phone"] = "Phone";
    resources["title"] = "Title";
    resources["message"] = "Message";
    resources["dark-theme"] = "Dark Theme";
    resources["created-at"] = "Created at";
    
    resources["copy-link"] = "Copy Link";
    resources["copied"] = "Copied";
    resources["click-to-view-qr"] = "Click to view the QR Code";
    resources["image-instructions"] = <>
        <p>Drag and drop an image here</p>
        <p><small>Supported formats .gif, .jpeg, .png</small></p>
    </>;

    resources["saving"] = "Saving...";
    resources["close"] = "Close";
    resources["create-item-first"] = "Create the item before uploading the image.";
    resources["confirm-delete"] = "Are you sure you want to delete this item?";
    resources["confirm-delete-heading"] = "Delete this item";
    resources["insert"] = "Insert";
    resources["create"] = "Create";
    
    resources["tables"] = "Tables";
    resources["social-type"] = "Social Type";
    resources["social-types"] = "Social Types";
    resources["social"] = "Social";
    resources["socials"] = "Socials";
    resources["stream"] = "Stream";
    resources["streams"] = "Streams";
    resources["stream-type"] = "Stream Source";
    resources["stream-types"] = "Stream Sources";
    resources["report"] = "Report";
    resources["reports"] = "Reports";
    resources["change"] = "Change";
}