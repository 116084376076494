import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import { formatInTimeZone } from 'date-fns-tz';

import AppContext from '../AppContext';
import JsonToTable from "../../framework/JsonToTable";
import { IqEvent } from "../../models/Event";
import { Stats } from "../../models/Stats";
import IqSpaceApi from '../../api/IqSpace';
import { DataLoader } from '@uderly/react-uderly-ui';

export interface EventStatsProps {
  event: IqEvent;
}

const EventStats = ({ event }: EventStatsProps) => {
  const tableHead = [
    {
      Title: AppContext.r["question"],
      Adapter: (o) => {
        return o.question.text
      }
    },
    {
      Title: AppContext.r["total-players"],
      Adapter: (o) => {
        return <b>{o.correct_responses_count + o.unanswered_responses_count + o.incorrect_responses_count}</b>;
      }
    },
    {
      Title: AppContext.r["correct-responses-count"],
      Field: "correct_responses_count"
    },
    {
      Title: AppContext.r["unanswered-responses-count"],
      Field: "unanswered_responses_count"
    },
    {
      Title: AppContext.r["incorrect-responses-count"],
      Field: "incorrect_responses_count"
    },
    {
      ClassName: "played-at",
      Title: AppContext.r["on"],
      Adapter: (o) => !o.created_at ? "" : formatInTimeZone(new Date(o.created_at), o.timezone, "dd/MM/yyyy H:mm")
    }
  ];

  const [stats, setStats] = useState<Stats | undefined>();

  useEffect(() => {
    const init = async () => {
      const response = await IqSpaceApi.EventStats(event.id);
      console.log(response);
      if (response && response.status === 200)
        setStats(response.data.data);
    }

    init();
  }, [event.id]);

  if (!stats)
    return <DataLoader fullscreen />

  return (
    <Grid item xs={12}>
      <JsonToTable head={tableHead} body={stats.played_questions}
        noItemsText={AppContext.r["no-played-questions"]} />
    </Grid>)
}

export default EventStats;