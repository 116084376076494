import React from 'react';
import { Container } from '@mui/system';
import { Link } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { t } from 'i18next';

const ErrorPage = () => {
  return (
    <Container className="error-page">
      <WarningAmberIcon sx={{ width: '100px', height: '100px', color: 'error.dark' }} />

      <h1>Uh oh...</h1>

      <p>
        {t("page-not-existing")}
      </p>

      <Link to="/">
        <img src="/assets/uderly/images/logo-black.png" alt="Uderly" />
      </Link>
    </Container>
  );
};

export default ErrorPage;
