import React from "react";
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { formatInTimeZone } from 'date-fns-tz';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import * as yup from 'yup';

import { updateItemRequest } from '@uderly/react-uderly-ui/api';
import AppContext from 'app/AppContext';
import IqSpaceApi from 'api/IqSpace';
import Module from 'framework/Module';
import { withModal } from 'framework/withModal';
import { setAlert } from '../../redux/layoutSlice';
import MediaStockImage from '../../framework/MediaStockImage';
import PlaylistQuestions from '../components/PlaylistQuestions';
import Separator from "app/components/Separator";
import PlaylistsSelect from '../components/PlaylistsSelect';
import Settings from '../../app/settings';
import withUderlyStore from "../../zustand/withUderlyStore";
import { Roles } from "../../enums";
import getPlaylistColumns from "../components/getPlaylistColumns";

class Collections extends React.Component {
    state = {
        store: null
    };

    apiPath = "/collections";

    get userCanManageContent() {
        const { user } = this.props.zustand;
        return user && user.roles && user.roles.filter(r => r.id === Roles.Admin || r.id === Roles.Manager || r.id === Roles.ContentManager).length > 0;
    }

    get initialValues() {
        return {
            name: ""
        };
    }

    get schema() {
        const v = {
            name: yup.string().required(AppContext.r["required-field"])
        };

        return yup.object(v);
    }

    async componentDidMount() {
        if (this.props.updateLayout)
            this.props.updateLayout();

        const response = await IqSpaceApi.Store(this.props.storeName);

        if (response && response.status === 200) {
            const store = response.data.data;

            this.setState({
                selectedStore: store
            });
        }
    }

    onFetchedItem = (item) => {
        this.setState({
            item: item
        });
    }

    onImageFileChanged = async (imageFile) => {
        const { item } = this.state;

        const m = [
            {
                name: imageFile.name,
                size: imageFile.size,
                mime_type: imageFile.type
            }
        ];

        this.setState({
            media: m
        });

        if (item.id)
            await updateItemRequest(Settings.apiUrl + this.apiPath + "/" + item.id, { ...item, media: m });
    }

    get imageUrl() {
        return Settings.apiUrl + this.apiPath + "/" + this.state.item.id + "/image";
    }

    updateDataAdapter = (o) => {
        o["media"] = null;
        return o;
    }

    onAddPackageToPlaylist = (pack) => {
        const { setAlert } = this.props;

        console.log(pack);

        const onSelect = async (playlist) => {
            this.props.modal.hide();

            const response = await IqSpaceApi.AddPackageToPlaylist(playlist.id, pack.id);

            if (response.status === 202) {
                setAlert({ open: true, message: AppContext.r["added-to-playlist"] + " \"" + playlist.name + "\"", severity: "success" });
                // } else if(response.status === 406) {
                //     setAlert({ open: true, message: AppContext.r["already-in-playlist"], severity: "warning" });
            } else {
                setAlert({ open: true, message: "Error", severity: "error" });
            }
        }

        this.props.modal.setView(<><DriveFileMoveIcon /> {AppContext.r["add-all-to-playlist"]}</>,
            <PlaylistsSelect modal={this.props.modal} onSelect={onSelect} />, true);
    }

    view = ({ values }) => {
        const { item } = this.state;

        return (
            <>
                <Grid container>
                    <Grid container item spacing={1} sm md lg xl>
                        <Grid item xs={12} sm={8} xl={12}>
                            <Field component={TextField} name="name" type="text" readonly
                                label={AppContext.r["name"] + "*"}
                                inputProps={
                                    { readOnly: !this.userCanManageContent, }
                                }
                            />
                        </Grid>

                        {values.created_at &&
                            <Grid item xs={12} sm={4} xl={4}>
                                <div className="outlined-with-label disabled">
                                    {formatInTimeZone(values.created_at, values.timezone, "dd/MM/yyyy H:mm:ss")}
                                    <label>{AppContext.r["created-at"]}</label>
                                </div>
                            </Grid>}
                    </Grid>

                    <Grid container spacing={1} className="image-col small">
                        <MediaStockImage sx={{ width: 56, height: 56 }}
                            apiPath={this.apiPath}
                            imageUrl={(values.id && (item.media && item.media[0])) ? this.imageUrl : null}
                            item={values}
                            onImageFileChanged={this.onImageFileChanged}
                            viewOnly={!this.userCanManageContent} />
                    </Grid>
                </Grid>

                <div className="questions-view">
                    {(values && values.questions) &&
                        <>
                            <h3 className="text-center">{values.questions_count} {AppContext.r["questions"]}</h3>
                            <Separator />

                            <div className="top-menu">
                                <Button variant="outlined"
                                    onClick={() => this.onAddPackageToPlaylist(values)}>{AppContext.r["add-all-to-playlist"]}</Button>
                            </div>

                            <PlaylistQuestions playlist={values} disableSortable asCollection />
                        </>}
                </div>
            </>);
    }

    render() {
        const { selectedStore } = this.state;

        const columns = getPlaylistColumns(this.apiPath);

        return (
            <div className="page module playlists">
                {selectedStore &&
                    <Module
                        // lastRecordsRefresh={this.state.lastRecordsRefresh}
                        modal={this.props.modal}
                        setAlert={this.props.setAlert}
                        path={"/collections"}
                        apiPath={this.apiPath}
                        showApiPath={"/packages"}
                        apiUrl={Settings.apiUrl}
                        accessApiPath={this.apiPath + "/?store_id=" + selectedStore.id}
                        updateDataAdapter={this.updateDataAdapter}
                        createApiPath={this.apiPath + "/?store_id=" + selectedStore.id}
                        store={selectedStore} id={this.props.id} tableHead={columns}
                        view={this.view} viewClassName="playlist-mask mask"
                        onFetchedItem={this.onFetchedItem}
                        initialValues={this.initialValues}
                        authActions={this.props.authActions}
                        disableInsert={!this.userCanManageContent}
                        disableEdit={!this.userCanManageContent}
                        disableDelete={!this.userCanManageContent} />}
            </div>
        );
    }
}

const mapDispatchToProps = () => ({
    setAlert
});

export default connect(null, mapDispatchToProps())(withModal(withUderlyStore(Collections)));