import React from 'react';

import AppContext from "../app/AppContext";
import GameStatusEnum from './GameStatusEnum';
import RankingItem from "./RankingItem"
import { RankingStatistic } from "./RankingStatistic"

export default class Ranking extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            players: props.players,
            gameStatus: props.gameStatus,
            page: 0
        };

        this.interval = null;
    }

    get PageShiftDelay() { return 3000; }
    get ItemsPerCol() { return 10; }
    get ColsPerPage() { return this.props.colsPerPage ? this.props.colsPerPage : 1; }
    get PagesCount() { return !this.state.players ? 1 : Math.max(1, Math.ceil(this.state.players.length / (this.ItemsPerCol * this.ColsPerPage))); }

    componentDidMount() {
        if (document.getElementById("ranking")) {
            const r = document.getElementById("ranking").getBoundingClientRect();
            this.width = r.width;
            this.height = r.height;
        }

        if (this.state.gameStatus.gameStatus !== GameStatusEnum.PartialRanking) {
            this.setState({
                page: this.PagesCount - 1
            });

            this.interval = setInterval(() => {
                const nextPage = ((this.state.page - 1) >= 0) ? this.state.page - 1 : this.PagesCount - 1;

                this.setState({
                    page: nextPage
                })
            }, this.PageShiftDelay);
        }
    }

    componentWillUnmount() {
        if (this.interval != null) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    render() {
        const { players } = this.state;

        const minRequiredCols = !players ? 0 : Math.max(this.ColsPerPage, Math.ceil(players.length / this.ItemsPerCol));
        const colCount = minRequiredCols;//Math.max(this.ColsPerPage, minRequiredCols + (minRequiredCols % this.ColsPerPage));
        const cols = [];

        const s = this.state.gameStatus.gameStatus;

        const showMedals = s !== GameStatusEnum.Teams;
        const showScores = s !== GameStatusEnum.Teams;

        const playersToShow = (s === GameStatusEnum.PartialRanking) ? this.props.players.filter(p => p.lastAnswer >= 0) : this.props.players;

        for (var j = 0; j < colCount; j++) {
            const colItems = [];
            const slicedCol = playersToShow.slice(j * this.ItemsPerCol, j * this.ItemsPerCol + this.ItemsPerCol);

            for (var i = 0; i < this.ItemsPerCol; i++) {
                const p = slicedCol[i];

                const player = {
                    position: j * this.ItemsPerCol + (i + 1),
                    name: p != null ? p.name : "",
                    score: p != null ? p.score : null,
                    lastScore: p != null ? p.lastScore : null,
                    userId: p != null ? p.userId : null
                }

                colItems.push(<RankingItem key={i + "-" + j} player={player} showMedal={showMedals} showScore={showScores} isPartial={s === GameStatusEnum.PartialRanking} />);
            }

            cols.push(
                <div className="ranking-col" key={j}>
                    {colItems}
                </div>
            );
        }

        const colGroups = [];

        for (i = 0; i < cols.length; i += this.ColsPerPage) {
            const pageCols = [];

            for (j = 0; j < this.ColsPerPage; j++)
                if (cols[i + j])
                    pageCols.push(cols[i + j]);

            const pageColsCount = pageCols.length;

            const style = {
                maxWidth: (cols.length - i) < this.ColsPerPage ? ((100 / this.ColsPerPage) * pageColsCount) + "%" : "100%"
            }

            colGroups.push(
                <div className="ranking-col-group" key={"col-" + i} style={style}>
                    <div className="ranking-col-group-inner">
                        {pageCols}
                    </div>
                </div>);
        }

        // Define Title
        let title = "";

        if (s === GameStatusEnum.Teams) title = AppContext.r["players"];
        if (s === GameStatusEnum.PartialRanking) title = AppContext.r["partial-ranking"];
        if (s === GameStatusEnum.Ranking) title = AppContext.r["ranking"];
        if (s === GameStatusEnum.FinalRanking) title = AppContext.r["final-ranking"];

        const rankingAllPagesWidth = this.width * this.PagesCount;

        const rankingStyle = {
            width: rankingAllPagesWidth + "px",
            marginLeft: this.state.page * -(this.width ? this.width : 0)
        };

        let partialRankingStats = "";

        // Partial Ranking Statistics
        if (s === GameStatusEnum.PartialRanking) {
            let exact = 0, wrong = 0, notAnswered = 0;

            for (i = 0; i < players.length; i++) {
                //if (players[i].Enabled) {
                if (players[i].lastAnswer >= 0 && players[i].lastScore > 0) exact++;
                if (players[i].lastAnswer >= 0 && players[i].lastScore < 0) wrong++;
                if (players[i].lastAnswer === -1) notAnswered++;
                //}
            }

            partialRankingStats = (
                <div className="ranking-statistics">
                    <RankingStatistic className="exact" label={AppContext.r['exact']} value={exact} />
                    <RankingStatistic className="wrong" label={AppContext.r['wrong']} value={wrong} />
                    <RankingStatistic className="absteined" label={AppContext.r['not-answered']} value={notAnswered} />
                </div>);
        }

        // Partial Ranking
        return (
            <div className="ranking-container">
                <div className="ranking">
                    <h1>{title}</h1>

                    <div className="ranking-col-groups-container" style={rankingStyle}>
                        <div className="ranking-col-groups" style={{ width: this.width }}>
                            {colGroups}
                        </div>
                    </div>

                    {partialRankingStats}
                </div>
            </div>
        );
    }
}