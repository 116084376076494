import React, { useEffect, useState } from "react";
import { Avatar, Button, ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import parseHtml from 'html-react-parser';
import EventIcon from '@mui/icons-material/Event';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SettingsIcon from '@mui/icons-material/Settings';

import AppContext from "../../AppContext";
import Settings from '../../../app/settings';

interface StoreToolBarProps {
  user: any;
  store: any;
}

interface MenuItemProps {
  to: string;
  title: string;
  icon: any;
}

const StoreToolBar = (props: StoreToolBarProps) => {
  const { user, store } = props;

  const logoUrl = Settings.apiUrl + '/stores/' + store.id + '/image/thumb?t=' + Date.now();

  const [expanded, setExpanded] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<any[] | null>(null);

  useEffect(() => {
    const menuCard = (menuItem: MenuItemProps) => {
      return (
        <div className="cell" key={"card-" + menuItem.title}>
          <Tooltip title={menuItem.title} placement="right">
            <IconButton component={Link} to={menuItem.to}>
              {menuItem.icon}
            </IconButton>
          </Tooltip>

          <label className="mobile-only">{menuItem.title}</label>
        </div>
      );
    }

    const role = (user && user.roles) ? user.roles[0] : null;
    const modules: any[] = (role) ? JSON.parse(role.modules) : [];
    const items: any[] = [];

    items.push(menuCard({ to: "/" + store.subdomain + "/events", title: AppContext.r["events"], icon: <EventIcon /> }));
    items.push(menuCard({ to: "/" + store.subdomain + "/playlists", title: AppContext.r["playlists"], icon: <PlaylistPlayIcon /> }));
    items.push(menuCard({ to: "/" + store.subdomain + "/collections", title: AppContext.r["collections"], icon: <LibraryBooksIcon /> }));
    items.push(menuCard({ to: "/" + store.subdomain + "/followers", title: AppContext.r["followers"], icon: <PersonPinIcon /> }));

    if (modules.filter(m => m.name === "Packages").length > 0) {
      items.push(menuCard({ to: "/" + store.subdomain + "/packages", title: AppContext.r["packages"], icon: <EditNoteIcon /> }));
    }

    // TODO: Add categories management

    setMenuItems(items);
  }, []);

  return (<div className="store-toolbar">
    <ClickAwayListener onClickAway={() => { setExpanded(false) }}>
      <div className={"store-popover" + (expanded ? " expanded" : "")}>
        <div className="avatar-container" onClick={() => { setExpanded(!expanded) }}>
          <Avatar className="avatar" src={logoUrl}>{store.name && store.name.substr(0, 1)}</Avatar>
        </div>

        <div className="info">
          <h2>{AppContext.r["your-store"]}</h2>

          <h1>{store.name}</h1>

          <h3>
            <Link to={"/" + store.subdomain + "/followers"}>
              <b>{store.followersCount}</b> {AppContext.r["followers"]}
            </Link>
          </h3>

          {/* {(store.socials && store.socials.length > 0) && <>
            <h2>{AppContext.r["your-socials"]}</h2>

            <Socials small dark socials={store.socials} />
          </>} */}

          {(store.description && store.description.length > 0) &&
            <>
              <h2>{AppContext.r["your-intro"]}</h2>
              <div id="Description" className="description">
                {parseHtml(store.description)}
              </div>
            </>}

          {/* <StoreTeam store={store} /> */}

          <Button component={Link} endIcon={<SettingsIcon />} variant="contained" className="edit-store"
            to={("/" + store.subdomain + "/settings")}>{AppContext.r["settings"]}</Button>
        </div>
      </div>
    </ClickAwayListener>

    <div className="vertical-toolbar">
      {menuItems &&
        <div className="store-menu">
          {menuItems}
        </div>}
    </div>
  </div>);
}

export default StoreToolBar;
