import { DataLoader } from '@uderly/react-uderly-ui';
import poweredByUderly from "../assets/images/powered-by-uderly.png";

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <DataLoader />

      <div className="powered-by">
        <img src={poweredByUderly} alt="Uderly" />
      </div>
    </div>);
}

export default SplashScreen;