export default function loadLocale(resources) {
    resources['accept-cookies'] = "Questo sito utilizza i cookies al fine di migliorare la tua esperienza di navigazione, mantenendo le tue preferenze quali ad esempio la lingua selezionata. Proseguendo alla navigazione presti il consenso all'uso di cookies.";
    resources['accept'] = "Accetta";
    resources['months'] = ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'];
    resources['days'] = "Giorni";
    resources['hours'] = "Ore";
    resources['minutes'] = "Minuti";
    resources['seconds'] = "Secondi";
    resources['phone'] = "Telefono";
    resources['email'] = "e-mail";
    resources['name'] = "Nome";
    resources['surname'] = "Cognome";
    resources['close'] = "Chiudi";
    resources['update'] = "Aggiorna";
    resources['follow-us-fb'] = "Seguici su Facebook";
    resources['follow-us-ig'] = "Seguici su Instagram";
    resources['try-it-now'] = "Provalo Adesso";
    resources['contact-us'] = "Contattaci";
    resources['sponsored-by'] = "Sponsorizzato Da";
    resources['hosted-by'] = "Servizio di";
    resources['cookies-policy'] = "Cookies Policy";
    resources['privacy-policy'] = "Privacy Policy";
    resources['terms'] = "Condizioni e Termini";
    resources['cancel'] = "Annulla";
    resources['edit'] = "Modifica";
    resources['delete'] = "Elimina";
    resources['save'] = "Salva";
    resources['address'] = "Indirizzo";
    resources['insert-new-address'] = "Inserisci Nuovo indirizzo";
    resources['address-alias'] = "Alias Indirizzo";
    resources["required-field"] = "Campo Obbligatorio.";
    resources["default-address"] = "Indirizzo di Casa";
    resources["post-code"] = "CAP";
    resources["city"] = "Città";
    resources["country"] = "Nazione";
    resources["confirm-delete-address"] = "Sei sicuro di volere eliminare questo indirizzo?";
    resources["confirm-delete-address-heading"] = "Elimina Indirizzo";
    resources["no-address-selected"] = "Non è stato specificato nessun indirizzo."
    resources["no-payment-type-selected"] = "Non è stato specificato nessun metodo di pagamento."
    resources["payment-method"] = "Metodo Di Pagamento"
    resources['mobile-phone'] = "Telefono";
    resources['email'] = "e-mail";
    resources['yes'] = "Sì";
    resources['no'] = "No";
    resources['or'] = "o";
    resources['next'] = "Successivo";
    resources['back'] = "Indietro";
    resources['description'] = "Descrizione";
    resources['check-out'] = "dà un'occhiata a";
    resources['learn-more'] = "Ulteriori Informazioni";
    resources["access"] = "Accedi";
    resources['submit'] = "Invia";
    resources['profile-saved'] = "Il tuo profilo è stato aggiornato.";
    resources['thanks-message'] = "Grazie per averci scritto!";
    resources['wrong-delivery-message'] = "Qualcosa è andato storto!";
    resources['company-title'] = "Azienda";
    resources['company-title'] = "Titolo Aziendale";
    resources['language'] = "Lingua";
    resources['no-items-in-the-list'] = <><p>Questa lista è vuota.</p></>;
    
    resources['who-we-are'] = "Chi Siamo";
    resources['work-with-us'] = "Lavora Con Noi";
    resources['close'] = "Chiudi";
    resources['keep-in-touch'] = "Teniamoci in contatto";
    resources["can-help-with"] = "Ti possiamo aiutare con";
    resources["can-help-with-x"] = ["servizi e soluzioni IT innovativi", "e-commerce & marketplace", "la digitalizzazione del tuo business", "soluzioni gestionali", "gaming", "team building & entertainment", "social media management"];
}