import React from "react";
import { connect } from 'react-redux';
import { t } from 'i18next';
import * as yup from 'yup';

import { useUderlyStore } from "@uderly/react-uderly-ui/zustand";
import AppContext from 'app/AppContext';
import Module from 'framework/Module';
import { withModal } from 'framework/withModal';
import { setAlert } from '../../redux/layoutSlice';
import Settings from '../../app/settings';

class Followers extends React.Component {
    state = {
        store: null,
        isPremium: null
    };

    moduleRef = React.createRef();

    apiPath = "/users";

    get initialValues() {
        return {
            title: "",
            published: true
        };
    }

    get schema() {
        const v = {
            title: yup.string().required(AppContext.r["required-field"])
        };

        return yup.object(v);
    }

    async componentDidMount() {
        const stores = useUderlyStore.getState().stores;

        if (stores && stores[0])
            this.setState({
                store: stores[0],
                isPremium: stores[0].premium_id > 0 && (!stores[0].premium_ends_in || stores[0].premium_ends_in > 0)
            });
    }

    render() {
        const { store } = this.state;

        const columns = [{
            field: 'name',
            headerName: t('name'),
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderCell: params => {
                const { row } = params;

                return (
                    <div className="td-v-center">
                        <div className="row-thumb">
                            {(row.media && row.media[0]) && <>
                                <img src={`${Settings.apiUrl}${this.apiPath}/${row.id}/image/thumb`} alt="Thumb" />
                            </>}
                            {(!row.media || !row.media[0]) && <img src={AppContext.s["placeholder"]} className="placeholder-thumb" alt="Thumb" />}
                        </div>

                        {row.gamename}
                    </div>
                );
            }
        }];

        return (
            <div className="page module followers">
                {store &&
                    <Module ref={this.moduleRef}
                        apiUrl={Settings.apiUrl}
                        modal={this.props.modal} setAlert={this.props.setAlert}
                        apiPath={"/followers?store_id=" + store.id}
                        path={"/followers"} friendlyPath={"followers"}
                        store={store} id={this.props.id} tableHead={columns}
                        view={this.view} viewClassName="event-mask"
                        noItemsMessage={<><p>{t('no-followers')}</p><p>{t('share-your-space')}</p></>}
                        disableView disableInsert disableEdit disableDelete
                        authActions={this.props.authActions} />}
            </div>
        );
    }
}

const mapDispatchToProps = () => ({
    setAlert
});

export default connect(null, mapDispatchToProps())(withModal(Followers));