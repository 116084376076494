import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import { initi18 } from "@uderly/react-uderly-ui";
import { dictionaries as uderlyDictionaries } from '@uderly/react-uderly-ui';

import en from './locales/en/translation.json';
import enAccountancy from './locales/en/translation.accountancy.json';
import enCookies from './locales/en/translation.cookies.json';
import enPayments from './locales/en/translation.payments.json';
import enIqSpace from './locales/en/translation.iqspace.json';

import it from './locales/it/translation.json';
import itCookies from './locales/it/translation.cookies.json';
import itPayments from './locales/it/translation.payments.json';
import itIqSpace from './locales/it/translation.iqspace.json';

// Combine dictionaries
const dictionaries = {
  en: {
    translation: {
      ...uderlyDictionaries.en.translation,
      ...en,
      ...enCookies,
      ...enPayments,
      ...enAccountancy,
      ...enIqSpace
    }
  },
  it: {
    translation: {
      ...uderlyDictionaries.it.translation,
      ...it,
      ...itCookies,
      ...itPayments,
      ...itIqSpace
    }
  }
};

initi18();

i18next.use(initReactI18next).use(LanguageDetector).use(Backend).init({
  debug: false,
  fallbackLng: 'en',
  saveMissing: true,
  resources: dictionaries
});
