import React, { ResetPassword } from "@uderly/react-uderly-ui";

const ResetPasswordPage = () => {
  return (
    <div className='reset-password-page'>
      <ResetPassword />
    </div>
  );
}

export default ResetPasswordPage;
