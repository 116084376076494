// Countdown v1.3

import React from 'react';

import AppContext from '../app/AppContext';

export default class Countdown extends React.Component {
    constructor(props) {
        super(props);

        const timespan = this.props.timespan != null ? this.props.timespan : null;
        this.showLabel = this.props.showLabel != null ? this.props.showLabel : true;
        this.url = this.props.url != null ? this.props.url : "";
        this.title = this.props.title != null ? this.props.title : "";
        this.label = this.props.label;

        this.daysLabel = this.props.daysLabel ? this.props.daysLabel : AppContext.r["days"];
        this.hoursLabel = this.props.hoursLabel ? this.props.hoursLabel : AppContext.r["hours"];
        this.minutesLabel = this.props.minutesLabel ? this.props.minutesLabel : AppContext.r["minutes"];
        this.secondsLabel = this.props.secondsLabel ? this.props.secondsLabel : AppContext.r["seconds"];

        this.state = {
            timespan: parseInt(timespan)
        };
    }

    componentDidMount() {
        if (this.interval != null)
            clearInterval(this.interval);

        this.interval = setInterval(() => {
            this.setState(
                { timespan: parseInt(this.state.timespan) - 1 }
            );
            if(this.state.timespan <= 0 && this.props.onExpired)
                this.props.onExpired();
        }, (1000));
    }

    render() {
        const { timespan } = this.state;

        let countdownBox = "";

        const link = (this.url.length > 0) ? 
            <div className="next-event-url-box">
                <a href={this.url}>{this.title}</a>
            </div> : "";
            
        if (timespan != null && parseInt(timespan) > 0) {
            const days = Math.floor(timespan / 86400);
            const hours = Math.floor((timespan - (days * 86400)) / 3600);
            const minutes = Math.floor((timespan - (days * 86400) - (hours * 3600)) / 60);
            const seconds = Math.floor((timespan - (days * 86400) - (hours * 3600) - (minutes * 60)));

            countdownBox = (
                <div>
                    { this.showLabel && this.label && 
                        <h3 className="label">
                            {this.label}
                        </h3>
                    }

                    <div className="countdown">
                        {(days > 0) && 
                            <div className="time-quantity-box">
                                <span className="quantity">{days}</span>
                                <span className="label"> {this.daysLabel}</span>
                            </div> }

                        {(days > 0 || hours > 0) && 
                            <div className="time-quantity-box">
                                <span className="quantity">{hours}</span>
                                <span className="label">{this.hoursLabel} </span>
                            </div> }

                        {(days > 0 || hours > 0 || minutes > 0) && 
                            <div className="time-quantity-box">
                                <span className="quantity">{minutes}</span>
                                <span className="label">{this.minutesLabel} </span>
                            </div> }

                        <div className="time-quantity-box">
                            <span className="quantity">{seconds}</span>
                            <span className="label">{this.secondsLabel} </span>
                        </div>
                    </div>

                    {link}
                </div>);
        }

        return (
            <div className="countdown-box">
                {countdownBox}
            </div>
        );
    }
}