import settings from "../settingsOld";
import resources from "../resources/resources";

export default class AppContext {
    static get s() { return settings; }
    static get r() { return resources; }

    static yupAddPhoneTest = (yup) => {
        yup.addMethod(yup.string, "phone", function (messageError = AppContext.r['phone-not-valid']) {
            const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

            return this.test('phone', messageError, value => {//return false;
                if (value && value.substr(0, 1) === "+")
                    value = value.substr(1, value.length - 1);

                if (value && value.length > 0)
                    return phoneRegExp.test(value);

                return true;
            })
        })
    }

    static yupAddUrlTest = (yup) => {
        yup.addMethod(yup.string, "url", function (messageError = AppContext.r['url-not-valid']) {
            const regExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-zA-Z0-9\.-_]{2,}){1,3}(#?\/?[a-zA-Z0-9#\-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%&=_]+&?)?$/;

            return this.test('url', messageError, value => {
                if (value && value.length > 0)
                    return regExp.test(value);

                return true;
            })
        })
    }

    static get isLocalhost() {
        return window.location.hostname === "localhost";
    }

    static animatePageChange = () => {
        const rootElement = document.getElementById("root");
        rootElement.classList.add("page-changed");

        setTimeout(() => { rootElement.classList.remove("page-changed"); }, 1000);
    }

    static onResourcesLoaded = () => {

    }
}