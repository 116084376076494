import React, { useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useTranslation } from "react-i18next";

import { LayoutContext, ILayoutContext, DialogModesEnum } from '@uderly/react-uderly-ui';
import IqSpaceApi from '../../api/IqSpace';

export interface PopulatePlaylistProps {
	playlistId: number;
	onPerformed: (response: any) => void;
	disabled?: boolean;
}

const PopulatePlaylist = ({ disabled, playlistId, onPerformed }: PopulatePlaylistProps) => {
	const { t } = useTranslation();

	const layoutContext = useContext(LayoutContext) as ILayoutContext;

	const onAddQuestions = async () => {
		const response = await IqSpaceApi.PopulatePlaylist(playlistId);

		if (response && response.status === 202) {
			onPerformed(response);
		} else {
			console.error(response);
		}
	}

	return <Tooltip title={t('playlist-wizard')} onClick={() => {
		layoutContext.dialog.show({
			title: t('playlist-wizard'),
			content: <>
				<p>{t('playlist-wizard-desc')}</p>
			</>,
			mode: DialogModesEnum.confirmation,
			onConfirm: onAddQuestions
		});
	}}>
		<IconButton aria-label="populate" disabled={disabled}>
			<AutoFixHighIcon />
		</IconButton>
	</Tooltip>;
}

export default PopulatePlaylist;