// ActionButton v1.0

import React from 'react';
import Tooltip from '@mui/material/Tooltip';

export default class ActionButton extends React.Component {
    state = {
        running: false,
        selected: this.props.selected ? true : false,
        disabled: this.props.disabled ? true : false,
        tooltip: this.props.tooltip ? this.props.tooltip : ""
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.disabled !== this.props.disabled) {
            this.setState({
                disabled: nextProps.disabled
            });
        }
        
        if (nextProps.selected !== this.props.selected) {
            this.setState({
                selected: nextProps.selected
            });
        }
        
        if (nextProps.tooltip !== this.props.tooltip) {
            this.setState({
                tooltip: nextProps.tooltip
            });
        }
    }

    runAction = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        if(this.props.action && !this.state.running && !this.state.disabled) {
            this.setState({
                running: true
            });

            await this.props.action();

            this.setState({
                running: false
            });
        }
    }

    render() {
        const {asDiv} = this.props;

        const className = this.props.className ? this.props.className : "";
        const c = "action-button " 
            + className
            + (this.props.small ? " small" : "")
            + (this.props.big ? " big" : "")
            + (this.state.selected ? " selected" : "")
            + (this.state.disabled ? " disabled" : "")
            + (this.state.running ? " running" : "");

        const content = <>
            {this.props.icon && <i className={this.props.icon}></i>}
            {this.props.children}
            <div className="overlay"></div>
        </>;

        const b = !asDiv ? 
            <button className={c} onClick={this.runAction}>
                {content}
            </button> :
            <div className={c} onClick={this.runAction}>
                {content}
            </div>;

        return this.state.tooltip ? (
            <Tooltip title={<span className="action-button-tooltip">{this.state.tooltip}</span>}>
                {b}
            </Tooltip>) : b;
    }
}