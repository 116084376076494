import i18n from 'i18next';

import Settings from '../app/settings';

import resourcesAuthentication from "./authentication/resources";
import itResourcesAuthentication from "./authentication/resources-it";
import itResources from './resources-it';
import docsFramework from "./docs/resources";
import itDocsFramework from "./docs/resources-it";
import resourcesFramework from "./framework/resources";
import itResourcesFramework from "./framework/resources-it";
import resourcesUderly from "./uderly/resources";
import itResourcesUderly from "./uderly/resources-it";
import resourcesIqSpace from "./IqSpace/resources";
import itResourcesIqSpace from "./IqSpace/resources-it";

import AppContext from "../app/AppContext";

const resources = [];

resources['preloader'] = (
    <div className="logo-spinner-preloader">
        <img src="/assets/iqspace/images/logo.png" alt="IQ Space logo" />

        {/* <svg className="splash-spinner" viewBox="0 0 50 50">
            <circle className="path"
                cx="25" cy="25" r="20"
                fill="none" strokeWidth="5"></circle>
        </svg> */}
    </div>);

function enResources() {
    resources['accept-cookies'] = "This website uses cookies to improve your navigation experience, storing your preferences such as your selected language. If you decide to carry on the navigation you accept the usage of cookies.";
    resources['accept'] = "Agreed";
    resources['months'] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    resources['days'] = "Days";
    resources['hours'] = "Hours";
    resources['minutes'] = "Minutes";
    resources['seconds'] = "Seconds";
    resources['phone'] = "Phone";
    resources['email'] = "e-mail";
    resources['name'] = "Name";
    resources['surname'] = "Surname";
    resources['close'] = "Close";
    resources['update'] = "Update";
    resources['follow-us-fb'] = "Like us on Facebook";
    resources['follow-us-ig'] = "Follow us on Instagram";
    resources['try-it-now'] = "Try It Now";
    resources['contact-us'] = "Contact Us";
    resources['sponsored-by'] = "Sponsored By";
    resources['hosted-by'] = "Hosted by";
    resources['cookies-policy'] = "Cookies Policy";
    resources['privacy-policy'] = "Privacy Policy";
    resources['terms'] = "Terms & Conditions";
    resources['cancel'] = "Cancel";
    resources['edit'] = "Edit";
    resources['delete'] = "Delete";
    resources['save'] = "Save";
    resources['address'] = "Address";
    resources['insert-new-address'] = "Insert New Address";
    resources['address-alias'] = "Address Alias";
    resources["required-field"] = "Required Field.";
    resources["default-address"] = "Home Address";
    resources["post-code"] = "Post Code";
    resources["city"] = "City";
    resources["country"] = "Country";
    resources["confirm-delete-address"] = "Are you sure you want to delete this address?";
    resources["confirm-delete-address-heading"] = "Delete Address";
    resources["no-address-selected"] = "No address has been selected."
    resources["no-payment-type-selected"] = "No payment method has been selected."
    resources["payment-method"] = "Payment Method"
    resources['mobile-phone'] = "Mobile Phone";
    resources['email'] = "e-mail";
    resources['yes'] = "Yes";
    resources['no'] = "No";
    resources['or'] = "or";
    resources['next'] = "Next";
    resources['back'] = "Back";
    resources['description'] = "Description";
    resources['check-out'] = "check-out at";
    resources['learn-more'] = "Learn More";
    resources["access"] = "Access";
    resources['profile-saved'] = "Your profile has been updated.";
    resources['close'] = "Close";
    resources['submit'] = "Submit";
    resources['thanks-message'] = "Thanks for your message!";
    resources['wrong-delivery-message'] = "Something went wrong delivering your message!";
    resources['company'] = "Company";
    resources['company-title'] = "Company Title";
    resources['language'] = "Language";
    resources['no-items-in-the-list'] = <><p>This list is empty.</p></>;

    resources['keep-in-touch'] = "Let's keep in touch";
    resources["can-help-with"] = "We can help you with";
    resources["can-help-with-x"] = ["innovative IT services and solutions", "e-commerces & marketplaces", "digitalizing your business", "management software", "gaming", "team building & entertainment", "social media management"];
}

export function loadResources(language) {
    if (language === "it") {
        itResources(resources);
        itResourcesAuthentication(resources);
        itDocsFramework(resources);
        itResourcesFramework(resources);
        itResourcesUderly(resources);
        itResourcesIqSpace(resources);
    } else {
        enResources();
        resourcesAuthentication(resources);
        docsFramework(resources);
        resourcesFramework(resources);
        resourcesUderly(resources);
        resourcesIqSpace(resources);
    }

    AppContext.onResourcesLoaded();
}

export default resources;