import React, { Component } from 'react';

export default class FullScreenMessage extends Component {
    constructor(props) {
        super(props);

        this.callback = this.props.onAnimationEnd;

        this.state = {
            classNames: "fullscreen-message"
        };
    }

    componentDidMount() {
        this.startStopAnimation();
    }

    startStopAnimation = () => {
        const { classNames } = this.state;

        this.setState({ classNames: classNames ? "fullscreen-message" : "fullscreen-message animation" });
    };

    onAnimationStart = () => {
    };

    onAnimationEnd = () => {
        if (typeof (this.callback) !== "undefined")
            this.callback();
    };

    render() {
        return (
            <div className={`box ${this.state.classNames}`}
                onAnimationStart={this.onAnimationStart}
                onAnimationEnd={this.onAnimationEnd}>{this.props.text}</div>
        );
    }
}
