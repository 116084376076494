import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes } from "react-router-dom";
import { Route } from 'react-router-dom';
import { useLocation, useSearchParams } from 'react-router-dom';

import Home from './views/Home';
import Layout from './components/Layout';
import AccountPage from './views/AccountPage';
import PrivacyPolicy from './views/PrivacyPolicy';
import Quizmaster from './views/Quizmaster';
import Business from './views/Business';
import Refunds from './views/Refunds';
import HowToPlay from './views/HowToPlay';
import WorkWithUs from './views/Play';
import Pricing from './views/Pricing';
import Entertainer from './views/Store';
import Events from './views/Events';
import Playlists from '../app/views/Playlists';
import Collections from '../app/views/Collections';
import Packages from '../app/views/Packages';
import Followers from '../app/views/Followers';
import Following from '../app/views/Following';
import Tickets from '../app/views/Tickets';
import ResetPasswordPage from '../app/views/ResetPasswordPage';
import Terms from './views/Terms';
import StoreSettings from './views/StoreSettings';
import { loadResources } from "../resources/resources";
import SignOutPage from "./views/SignOutPage";
import ErrorPage from '../app/views/ErrorPage';
import Game, { ViewModeStatesEnum } from '../quiz-components/Game';
import SplashScreen from "../uderly/components/SplashScreen";
// import { SplashScreen } from "@uderly/react-uderly-ui";
import usePagesRouting from "../uderly/hooks/usePagesRouting";
import { clearAxiosInterceptors, setAxiosInterceptors } from "@uderly/react-uderly-ui/api";
import { useUderlyStore } from '@uderly/react-uderly-ui/zustand';
import Settings from "./settings";
import { useTranslation } from "react-i18next";
// import useMediaRecorder from "./hoooks/useMediaRecorder";

export default function BasePage() {
  const pagesRouting = usePagesRouting();

  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const layout = useSelector(state => state.layout);

  // const { renderMediaRecorder } = useMediaRecorder();

  const { language, token, setToken, fetchUserInfo } = useUderlyStore((state) => {
    return {
      language: state.language,
      token: state.token,
      setToken: state.setToken,
      fetchUserInfo: state.fetchUserInfo
    };
  });

  const initialized = useRef(false);

  useEffect(() => {
    const initWithSplashScreen = async () => {
      const durationMs = 1500; // Show splash screen for durationMs
      const dt0 = Date.now();

      if (!initialized.current) {
        setLoading(true);

        initialized.current = true;

        if (searchParams.has('token') && location.pathname != "/resetpassword") {
          const queryToken = searchParams.get('token');

          if (queryToken !== null && queryToken.length > 0) {
            // Clear Axios Interceptors
            clearAxiosInterceptors();

            setAxiosInterceptors(queryToken);

            searchParams.delete('token');
            setSearchParams(searchParams);

            console.log("Query Token received", queryToken)
            setToken({ access_token: queryToken });

            // const response = await fetchUserInfo();
            // if(response != null && response.data && !response.data.email_verified_at) {
            // await verify();
            // }
          }
        } else {
          if (token && token.access_token)
            setAxiosInterceptors(token.access_token);
        }

        await fetchUserInfo();

        loadResources(language);

        const dt1 = Date.now() - dt0;
        // console.log("Delay splash screen for " + (durationMs - dt1) + " ms");

        setTimeout(() => {
          setLoading(false);
        }, Math.max(0, (durationMs - dt1)));
      }

      Settings.siteMap = [
        { to: "/", scrollTo: "#home", title: t("home"), element: <Layout><Home /></Layout> },
        { to: "/", scrollTo: "#mission", title: "IQ Space" },
        { to: "/play", scrollTo: "#playwithfriends", title: t("play"), element: <Layout alwaysShowBackground><WorkWithUs /></Layout> },
        { to: "/howtoplay", scrollTo: "#howtoplay", title: t("how-to-play"), element: <Layout alwaysShowBackground><HowToPlay /></Layout> },
        { to: "/quizmaster", hideFromHeader: true, title: t("quizmasters"), element: <Layout alwaysShowBackground><Quizmaster /></Layout> },
        { to: "/business", title: "Business", element: <Layout alwaysShowBackground><Business /></Layout> },
        { to: "/pricing", hideFromHeader: true, title: t("pricing"), element: <Layout alwaysShowBackground><Pricing /></Layout> },
        { to: "/terms", hideFromHeader: true, title: t("pricing"), element: <Layout alwaysShowBackground><Terms /></Layout> },
        { to: "/privacy", hideFromHeader: true, title: t("pricing"), element: <Layout alwaysShowBackground><PrivacyPolicy /></Layout> },
        { to: "/refunds", hideFromHeader: true, title: t("pricing"), element: <Layout alwaysShowBackground><Refunds /></Layout> },
        { to: "/following", hideFromHeader: true, title: t("following"), element: <Layout alwaysShowBackground><Following /></Layout> },
        { to: "/tickets", hideFromHeader: true, title: t("tickets"), element: <Layout alwaysShowBackground><Tickets /></Layout> },
        { to: "/", scrollTo: "#contact", title: t("contact") }
      ];
    }

    initWithSplashScreen();
  }, [fetchUserInfo, language, layout, searchParams, setSearchParams, setToken, t, token]);

  const marginTop = searchParams.get('marginTop') ?? 0;

  if (marginTop)
    console.log("MarginTop: " + marginTop);

  if (loading)
    return <SplashScreen />;

  return (
    <Routes>
      {/* <Route path="/verify/email/:token" render={({ match }) =>
            <Layout><Home token={match.params.token} /></Layout>} /> */}
      {pagesRouting}

      <Route path="/myaccount" element={<Layout alwaysShowBackground><AccountPage /></Layout>} />
      <Route path="/logout" element={SignOutPage} />

      <Route path='/event/:id'
        element={
          <Layout className="quiz-game" disableFooter hideLogo hideNavigationMenu accountMenuAlignLeft>
            <Game viewMode={ViewModeStatesEnum.Mobile} />
          </Layout>} />

      <Route path='/event/:id/maxi'
        element={
          <Layout disableFooter hideLogo hideHeader disableAuthentication>
            <Game viewMode={ViewModeStatesEnum.BigScreen} />
          </Layout>} />

      <Route path='/event/:id/recorder'
        element={
          <Layout disableFooter hideLogo hideHeader disableAuthentication>
            <Game viewMode={ViewModeStatesEnum.Recorder} marginTop={marginTop}
              onGameStatusChanged={(gameStatus) => console.log(gameStatus)} />
            {/* {renderMediaRecorder()} */}
          </Layout>} />

      <Route exact path='/:entertainer' element={<Layout controlFooter alwaysShowBackground location={location}>
        <Entertainer />
      </Layout>} />

      <Route exact path='/:entertainer/settings' element={<Layout disableFooter alwaysShowBackground location={location}>
        <StoreSettings />
      </Layout>} />

      <Route exact path='/:entertainer/events/:id' element={<Layout disableFooter alwaysShowBackground location={location}>
        <Events />
      </Layout>} />

      <Route exact path='/:entertainer/events' element={<Layout disableFooter alwaysShowBackground location={location}>
        <Events />
      </Layout>} />

      <Route exact path='/:storeName/playlists/:id' element={<Layout disableFooter alwaysShowBackground location={location}>
        <Playlists />
      </Layout>} />

      <Route exact path='/:storeName/playlists' element={<Layout disableFooter alwaysShowBackground location={location}>
        <Playlists />
      </Layout>} />

      <Route exact path='/:storeName/collections/:id' element={<Layout disableFooter alwaysShowBackground location={location}>
        <Collections />
      </Layout>} />

      <Route exact path='/:storeName/collections' element={<Layout disableFooter alwaysShowBackground location={location}>
        <Collections />
      </Layout>} />

      <Route exact path='/:entertainer/followers' element={<Layout disableFooter alwaysShowBackground location={location}>
        <Followers />
      </Layout>} />

      <Route exact path='/:entertainer/packages/:id' element={<Layout disableFooter alwaysShowBackground location={location}>
        <Packages />
      </Layout>} />

      <Route exact path='/:entertainer/packages' element={<Layout disableFooter alwaysShowBackground location={location}>
        <Packages />
      </Layout>} />

      <Route path='/resetpassword' element={<ResetPasswordPage />} />

      <Route path='*' element={<Layout alwaysShowBackground><ErrorPage /></Layout>} />
    </Routes>
  );
}
