import React, { useState } from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { Button, LinearProgress, FormControlLabel, Box, Grid } from '@mui/material';
import { TextField, Switch as FormikSwitch } from 'formik-mui';
import SendIcon from '@mui/icons-material/Send';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Settings from '../../app/settings';
import { yupAddPhoneTest } from '../yupValidation';
import { submitContact } from '@uderly/react-uderly-ui/api';
import ContactForm from '../models/Contact';
// import golfIcon from "../assets/icons/golf-icon.svg";
import { DataLoader } from '@uderly/react-uderly-ui';

yupAddPhoneTest();

const useSampleValues = false;

const RequestForm = () => {
  const { t } = useTranslation();

  // Country autosuggest
  // https://codesandbox.io/s/xoplpm1w84?file=/src/countries.json

  const [overlay, setOverlay] = useState<React.ReactNode | null>(null);
  const [filename, setFilename] = useState<string | null>(null);

  const initialValues: ContactForm = !useSampleValues ? {
    title: '',
    full_name: '',
    company_name: '',
    email: '',
    phone: '',
    message: '',
    terms_accepted: false,
    allow_newsletter: false,
    store_id: Settings.storeId
  } : {
    title: "CTO",
    full_name: "Alvaro Fernandez Torrez",
    company_name: "La madrilena Ltd",
    email: "simone.vitale1987@gmail.com",
    phone: "+44 123456789",
    message: "Ciao ragazzi,\n\nqui è Alvaro de Madrid!\n\nsaluti cari ciao ciao",
    terms_accepted: true,
    allow_newsletter: true,
    store_id: Settings.storeId
  };

  const schema = Yup.object().shape({
    full_name: Yup.string()
      .min(2, t("field-too-short"))
      .max(50, t("field-too-long"))
      .required(t("field-required")),
    email: Yup.string().email(t("invalid-email")).required(t("field-required")),
    phone: Yup.string().phone().nullable(),
    message: Yup.string()
      .min(10, t("field-too-short"))
      .max(1000, t("field-too-long"))
      .required(t("field-required"))
  });

  const onSubmit = async (values: ContactForm, { resetForm }: FormikHelpers<ContactForm>) => {
    setOverlay(<DataLoader />);

    const reset = () => {
      setOverlay(null);
      setFilename(null);

      resetForm();
    }

    values.source = Settings.project.title;
    values.url = window.location.href;

    const response = await submitContact(values);
    console.log(values, response);

    if (response && (response.status === 201 || response.status === 200)) {
      setOverlay(<>
        {/* <img className="icon large" src={golfIcon} alt="Sent Icon" /> */}
        <p className="message">{t('thanks-message')}</p>
        <Button variant="contained" onClick={reset}>{t('close')}</Button>
      </>);
    } else {
      console.error(response);
      
      setOverlay(<>
        <ErrorOutlineIcon />
        <p className="message">{t('wrong-delivery-message')}</p>
        <Button variant="contained" onClick={reset}>{t('close')}</Button>
      </>);
    }
  }

  return (
    <Grid container justifyContent="center">
      <Grid className="contact-form" id="contactUsForm" item md={8} lg={6}>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
          {({ submitForm, isSubmitting, values, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <Field component={TextField} name="full_name" type="text"
                    label={t("full-name") + "*"} />
                </Grid>

                <Grid item md={4}>
                  <Field component={TextField} name="title" type="text"
                    label={t("company-title")} />
                </Grid>

                <Grid item md={4}>
                  <Field component={TextField} name="company_name" type="text"
                    label={t("company-name")} />
                </Grid>

                <Grid item md={6}>
                  <Field component={TextField} name="email" type="email"
                    label={t("email") + "*"} />
                </Grid>

                <Grid item md={6}>
                  <Field component={TextField} name="phone" type="phone"
                    label={t("phone")} />
                </Grid>
              </Grid>

              <Box marginTop={2}>
                <Field component={TextField} name="message" type="text"
                  label={t("message") + "*"} multiline />
              </Box>

              <Box margin={1} className="accept-data-treatment-box">
                <FormControlLabel control={<Field component={FormikSwitch} type="checkbox" name="allow_newsletter" checked={values.allow_newsletter} />}
                  label={t("request-form-accept-newsletter")} onChange={(e, checked) => setFieldValue("allow_newsletter", checked)} />
              </Box>

              <Box margin={1} className="accept-data-treatment-box">
                <FormControlLabel control={<Field component={FormikSwitch} type="checkbox" name="terms_accepted" checked={values.terms_accepted} />}
                  label={t("request-form-accept")} onChange={(e, checked) => setFieldValue("terms_accepted", checked)} />
              </Box>

              {isSubmitting && <LinearProgress />}

              <Box margin={1} className="submit-box">
                <Button variant="outlined" endIcon={<SendIcon />} className="light"
                  disabled={isSubmitting || !values.terms_accepted}
                  onClick={submitForm}>{t("submit")}</Button>
              </Box>
            </Form>
          )}
        </Formik>

        {overlay &&
          <div className="overlay">
            {overlay}
          </div>}
      </Grid>
    </Grid>
  );
}

export default RequestForm;