import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";

import layoutSlice from "./layoutSlice";
import iqSlice from "./iqSlice";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    layout: layoutSlice.reducer,
    iq: iqSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
