import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Row, Col } from 'react-bootstrap';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DeleteIcon from '@mui/icons-material/Delete';

import AppContext from 'app/AppContext';
import { withModal } from "framework/withModal";
import CheckoutForm from "../uderly/components/CheckoutForm";
import { paymentMethods, detachPaymentMethod } from 'api/Stripe.ts';
import ActionButton from 'framework/ActionButton';
import { createTicketPaymentIntent } from '../api/Stripe.ts';

class StripeCheckout extends React.Component {
    state = {
        paymentMethods: null,
        paymentMethod: null // Selected Payment Method
    };

    async componentDidMount() {
        await this.fetchPaymentMethods();
    }

    fetchPaymentMethods = async () => {
        // Fetch customer payment methods
        const response = await paymentMethods(this.props.store.id);

        if (response && response.data && response.status === 200) {
            this.setState({
                paymentMethods: response.data.data
            });
        }
    }

    deletePaymentMethod = async (p) => {
        // It will show the preloader
        this.setState({
            paymentMethods: null
        });

        this.props.modal.hide();

        const response = await detachPaymentMethod(this.props.store.id, p.id);
        console.log(response);

        await this.fetchPaymentMethods();
    }

    confirmDeletePaymentMethod = (p) => {
        this.props.modal.confirm(AppContext.r["delete"] + " " + p.card.brand + " **** **** **** " + p.card.last4,
            AppContext.r["delete-payment-method-confirm"], async () => await this.deletePaymentMethod(p));
    }

    handleChange = (event) => {
        this.setState({
            paymentMethod: event.target.value
        });
    }

    render() {
        const { paymentMethods, paymentMethod } = this.state;
        const { user, request, amount, onSucceeded } = this.props;

        // If there are not previous payment methods set up a new one
        if (!paymentMethods || paymentMethods.length === 0)
            return (
                <CheckoutForm
                    request={request} email={user.email} amount={amount}
                    onSucceeded={onSucceeded}
                    onCreatePaymentIntent={async (saveCard) =>
                        await createTicketPaymentIntent(request.event, { ...request, saveCard })} />);

        // Present payment methods
        return (
            <>
                <FormControl className="payment-methods" component="fieldset">
                    <FormLabel component="legend">{AppContext.r["select-a-card"]}</FormLabel>
                    <RadioGroup className="payment-method" aria-label="payment-methods"
                        value={paymentMethod} onChange={this.handleChange}>
                        {paymentMethods.map(p =>
                            <Row>
                                <Col xs={10}>
                                    <FormControlLabel value={p.id} control={<Radio />} label={p.card.brand + " **** **** **** " + p.card.last4} />
                                </Col>

                                <Col xs={2}>
                                    <ActionButton action={async () => await this.confirmDeletePaymentMethod(p)}>
                                        <DeleteIcon />
                                    </ActionButton>
                                </Col>
                            </Row>)}
                    </RadioGroup>
                </FormControl>

                {paymentMethod !== undefined &&
                    <Row className="justify-content-center">
                        <Button className="new-card-button" onClick={() => this.setState({ paymentMethod: undefined })}>{AppContext.r["pay-by-new-card"]}</Button>
                    </Row>}

                <CheckoutForm paymentMethod={paymentMethod}
                    request={request} email={user.email} amount={amount}
                    onStartedProcessing={() => this.props.modal.setBusy()}
                    onSucceeded={onSucceeded}
                    onCreatePaymentIntent={async (saveCard) =>
                        await createTicketPaymentIntent(request.event, { ...request, saveCard })} />
            </>);
    }
}

const InjectedCheckout = (props) => {
    const { user, amount, request, onSucceeded, modal, testMode, store } = props;

    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <StripeCheckout
                    stripe={stripe} elements={elements}
                    store={store} testMode={testMode}
                    modal={modal}
                    user={user} amount={amount} request={request}
                    onSucceeded={onSucceeded} />
            )}
        </ElementsConsumer>
    );
}

export default withModal(InjectedCheckout);