import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

import { defaultSettings } from "@uderly/react-uderly-ui";
import { ISettings, AuthTypes } from "@uderly/react-uderly-ui/models";

interface IqSpaceSettings extends ISettings {
  maxPlaylistQuestions: number;
}

const Settings: IqSpaceSettings = {
  storeId: 2,
  provider: defaultSettings.provider,
  project: {
    id: "iqspace",
    title: "IQ Space",
    web: {
      title: "IQ Space",
      domain: "iqspace.live",
      site: "www.iqspace.live"
    },
    logo: {
      original: "/assets/iqspace/images/logo.png",
      originalDark: "/assets/iqspace/images/logo.png",
      mini: "/assets/iqspace/images/logo.png",
      miniDark: "/assets/iqspace/images/logo.png"
    },
    socials: [
      {
        title: "Facebook",
        url: "https://www.facebook.com/iqspace.live",
        icon: <FontAwesomeIcon icon={faFacebook} />
      },
      {
        title: "Instagram",
        url: "https://www.instagram.com/iqspace.live",
        icon: <FontAwesomeIcon icon={faInstagram} />
      },
      {
        title: "TikTok",
        url: "https://www.tiktok.com/@iqspace.live",
        icon: <FontAwesomeIcon icon={faTiktok} />
      }]
  },
  auth: {
    type: AuthTypes.oauth2,
    clientId: "2",
    clientSecret: "0dIONiMg3HBDq6AID9dkFXxu8gNeWBQ6XLfxQtdu"
  },
  stripe: {
    key: "pk_live_51ITrkbG9FiCTI7YqVdVNptG2rRm57v3R5xnfw4JZK3BLRW6RZE1rJE0JwnDwZbOwvP5JEM8gnQEezE56vlxNSzuX00qjXClR1v",
    testKey: "pk_test_51ITrkbG9FiCTI7Yqf9vWy3mK8qiCMhrRcXHBm8GhpaSXkmrQsyoZDMCFMIKphJMQplftj8HFRtbg22hAB4gWrOdY00yzSXqjyc"
  },
  localhostUrl: "http://localhost:3000",
  apiUrl: "https://iqspace-api.net.uderly.com",
  // apiUrl: "https://iqspace-test-api.net.uderly.com",
  // apiUrl: "https://localhost:7042",
  isLocalhost: () => window.location.hostname === "localhost",
  cookies: true,
  maxPlaylistQuestions: 100,
  siteMap: undefined // Defined externally
};

export default Settings;
