import React, { useState, useEffect } from 'react';
import { ThemeContext, themes } from './ThemeContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Cookie from 'react-cookies';

// https://levelup.gitconnected.com/dark-mode-in-react-533faaee3c6e

export default function ThemeContextWrapper(props) {
    const darkTheme = createTheme({
      palette: {
        mode: 'light',
      },
    });

    const [theme, setTheme] = useState(Cookie.load("dark-theme") === "true" ? themes.dark : themes.light);

    function changeTheme(theme) {
        setTheme(theme);
    }

    useEffect(() => {
        switch (theme) {
            case themes.light:
                document.body.classList.add('light-theme');
                document.body.classList.remove('dark-theme');
                break;
            case themes.dark:
            default:
                document.body.classList.add('dark-theme');
                document.body.classList.remove('light-theme');
                break;
        }
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme: theme, changeTheme: changeTheme }}>
            <ThemeProvider theme={darkTheme}>
                {props.children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
}