import React, { useState } from 'react';
import { Button, Grid, LinearProgress } from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';

import { useUderlyStore } from '@uderly/react-uderly-ui/zustand';
import { updateMyself } from '@uderly/react-uderly-ui/api';
import AppContext from '../app/AppContext';
import { GameNameForm } from '../app/views/AccountPage';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

interface AccessGameProps {
  onClickPlay: () => void;
}

enum AccessGameModes {
  normal,
  editGameName
}

const AccessGame = ({ onClickPlay }: AccessGameProps) => {
  const { user, setUser } = useUderlyStore();

  const [mode, setMode] = useState<AccessGameModes>(!user || !user.gamename ? AccessGameModes.editGameName : AccessGameModes.normal);

  const schema = yup.object({
    gamename: yup.string().nullable()
      .required(AppContext.r["required-field"])
      .min(3, AppContext.r["gamename-validation"])
  })

  return (
    <>
      {mode === AccessGameModes.normal && <>
        {user && user.gamename && <>
          <p>Hey <b>{user.gamename}</b></p>
          <p>{AppContext.r['ready-for-challenge']}</p>
        </>}

        <Button variant="outlined" className="play-button branded-button" onClick={onClickPlay}>
          <img src={AppContext.s["mini-logo-dark"]} alt="Mini Logo" /> {AppContext.r['play']}
        </Button>

        <button className="link-button"
          onClick={() => setMode(AccessGameModes.editGameName)}>
          {AppContext.r['wanna-change-name']}
        </button>
      </>}

      {mode === AccessGameModes.editGameName && <div className="gamename-form">
        <p>{AppContext.r['whats-game-name']}</p>

        <Formik
          enableReinitialize={true}
          initialValues={{ gamename: !user ? "" : user.gamename }}
          validationSchema={schema}
          onSubmit={async (values) => {
            console.log('SUBMIT', values);
            const response = await updateMyself(values);

            if (response && response.data)
              setUser(response.data);

            setMode(AccessGameModes.normal);
          }}>
          {({ values, isValid, isSubmitting, errors, handleSubmit, handleReset, setFieldValue }) => (
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <Grid container>
                <GameNameForm formikProps={{
                  values: values,
                  isValid: isValid,
                  isSubmitting: isSubmitting,
                  setFieldValue: setFieldValue,
                  errors: errors
                } as FormikProps<any>} fullWidth hideColorsPalette />

                <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                  <Button type="submit" variant='contained'>Okay</Button>
                  {isSubmitting && <LinearProgress />}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>

        <Link to="/myaccount" className='more-settings'>{AppContext.r["more-account-settings"]}</Link>

        <br />

        {(user && user.gamename) && (
          <button className="link-button"
            onClick={() => setMode(AccessGameModes.normal)}>
            {AppContext.r['cancel']}
          </button>)}
      </div>}
    </>);
}

export default AccessGame;