import React, { Component } from 'react';
import AppContext from '../app/AppContext';
import Settings from '../app/settings';

export default class RankingItem extends Component {
    constructor(props) {
        super(props);

        const { player } = props;

        this.state = {
            showScore: this.props.showScore,
            showMedal: this.props.showMedal,
            player: {
                position: props.position ? props.position : player.position,
                name: player.name,
                score: props.isPartial ? player.lastScore : player.score,
                userId: player.userId
            }
        };
    }

    render() {
        const { showScore, showMedal } = this.props;

        const p = this.state.player;

        const score = p.score !== null && showScore ? p.score : "";
        let position = (<span className="circle"><i>{p.position}</i></span>);

        if (showMedal) {
            if (p.position === 1) position = (<img src={AppContext.s["gold-medal-path"]} alt="gold" />);
            if (p.position === 2) position = (<img src={AppContext.s["silver-medal-path"]} alt="silver" />);
            if (p.position === 3) position = (<img src={AppContext.s["bronze-medal-path"]} alt="bronze" />);
        }

        const profilePictureUrl = (p.userId && p.userId > 0) ? "url(" + Settings.apiUrl + "/users/" + p.userId + "/image/thumb)" : "";

        return (
            <div className={"ranking-item " + (this.props.you ? "you" : "")} style={this.props.style}>
                <span className="position">{position}</span>
                <span className="name">{p.name}</span>
                <span className="score">{score}{score !== "" && <i className="far fa-star"></i>}</span>
                <div className="profile-picture" style={{ backgroundImage: profilePictureUrl }}></div>
            </div>
        );
    }
}
