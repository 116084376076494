import React from "react";
import { Link } from 'react-router-dom';
import { Field } from 'formik';
import { TextField, Switch as FormikSwitch } from 'formik-mui';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Icon from '@mui/material/Icon';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import itLocale from 'date-fns/locale/it';
import MuiTextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import * as yup from 'yup';
import { t } from 'i18next';

import { LayoutContext, TimezoneField } from '@uderly/react-uderly-ui';
import { updateItemRequest } from '@uderly/react-uderly-ui/api';
import AppContext from 'app/AppContext';
import IqSpaceApi from 'api/IqSpace';
import Module from 'framework/Module';
import { withModal } from 'framework/withModal';
import ShareEvent from '../components/ShareEvent';
import { setAlert } from '../../redux/layoutSlice';
import MediaStockImage from '../../framework/MediaStockImage';
import EntityNotifications from '../../uderly/components/EntityNotifications';
import Settings from '../../app/settings';
import withUderlyStore from "../../zustand/withUderlyStore";
import ActionButton from 'framework/ActionButton';
import EventExtraTabs from "../components/EventExtraTabs";

class Events extends React.Component {
    static contextType = LayoutContext;

    state = {
        events: null,
        item: null,
        store: null,
        transactionFee: 0,
        netIncome: 0,
        isFree: true,
        selectedTabIndex: 0
    };

    minPrice = 0.5;
    defaultPrice = 5;

    apiPath = "/events";

    get initialValues() {
        return {
            title: "",
            published: true,
            description: "",
            date_time: format(new Date(), (new Date().getHours() > 20 && new Date().getMinutes() > 30) ? "yyyy-MM-dd HH:mm:ss" : "yyyy-MM-dd 20:30:00"),
            timezone: this.state.store.timezone,
            location_type_id: 0,
            facebook_url: "",
            is_free: true,
            price: this.defaultPrice,
            netIncome: 0,
            transactionFee: 0,
            autostart: false
        };
    }

    get schema() {
        const v = {
            title: yup.string().required(AppContext.r["required-field"])
        };

        if (!this.state.isFree)
            v["price"] = yup.number()
                .required(AppContext.r["required-field"])
                .min(this.minPrice, AppContext.r["ticket-min-price"].replace("{currency}", this.state.store.locale.currency_symbol));

        return yup.object(v);
    }

    async componentDidMount() {
        const response = await IqSpaceApi.Store(this.props.entertainer);

        if (response && response.status === 200) {
            const store = response.data.data;

            this.setState({
                store: store
            });
        }
    }

    onInsertItem = () => {
        this.setState({
            item: { ...this.initialValues }
        });
    }

    onFetchedItem = (item) => {
        this.setState({
            item: item,
            isFree: !(item.price > 0)
        });

        this.calculateFees(item.price);
    }

    calculateFees = (price = null) => {
        const { store } = this.state;

        if (store) {
            const { licence } = store;

            if (licence) {
                const transactionFee = ((parseFloat(price) / 100 * parseFloat(licence.stripe_fee)) + parseFloat(licence.stripe_fixed_fee));

                this.setState({
                    netIncome: parseFloat(price) - transactionFee,
                    transactionFee: transactionFee
                });
            }
        }
    }

    get imageUrl() {
        return Settings.apiUrl + this.apiPath + "/" + this.state.item.id + "/image";
    }

    updateDataAdapter = (values) => {
        if (values["location_type_id"] === 0)
            values["location_type_id"] = null;
        values["media"] = null;
        return values;
    }

    insertDataAdapter = (values) => {
        if (values["location_type_id"] === 0)
            values["location_type_id"] = null;
        values["store_id"] = this.state.store.id;
        values["media"] = this.state.media;
        return values;
    }

    onImageFileChanged = async (imageFile) => {
        const { item } = this.state;

        const m = [
            {
                name: imageFile.name,
                size: imageFile.size,
                mime_type: imageFile.type
            }
        ];

        this.setState({
            media: m
        });

        console.log(item)

        if (item && item.id)
            await updateItemRequest(Settings.apiUrl + this.apiPath + "/" + item.id, { ...item, media: m });
    }

    view = ({ values, setFieldValue }) => {
        const { item, store, transactionFee, netIncome } = this.state;

        if (!store) return null;

        return (
            <>
                <Grid container>
                    <Grid container item spacing={1} sm={8}>
                        <Grid item sm={10} xl={11}>
                            <Field component={TextField} name="title" type="text"
                                label={AppContext.r["title"] + "*"} />
                        </Grid>

                        <Grid item sm={2} xl={1}>
                            <FormControlLabel control={<Field component={FormikSwitch} type="checkbox" name="published" checked={values.published} />}
                                label={AppContext.r["published"]} onChange={(e, checked) => {
                                    if (!checked)
                                        setFieldValue("autostart", false);

                                    setFieldValue("published", checked);
                                }} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                                <DateTimePicker
                                    inputFormat="yyyy-MM-dd HH:mm:ss"
                                    value={new Date(values.date_time)}
                                    onChange={(v) => setFieldValue("date_time", format(v, "yyyy-MM-dd HH:mm:ss"))}
                                    label={AppContext.r["datetime"]}
                                    renderInput={(params) => <MuiTextField {...params} />} />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            {values && values.timezone &&
                                <TimezoneField value={values.timezone} onChange={(e) => {
                                    setFieldValue("timezone", e.value)
                                }} />}
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{AppContext.r["event-schedule-auto-start"]}</FormLabel>

                                <FormControlLabel control={<Field component={FormikSwitch} type="checkbox" name="autostart" checked={values.autostart} />}
                                    label={"Auto Start"} onChange={(e, checked) => {
                                        if (checked && store.licence.id === 1) {
                                            this.context.toast.show({
                                                open: true, severity: "warning", autoHideDuration: 7000,
                                                content: <>
                                                    <p>{AppContext.r["pro-licence-required"]}</p>
                                                    <Link to={"/" + store.subdomain + "/settings"}>{t('upgrade-licence', { store: store.name })}</Link>
                                                </>
                                            });
                                        } else if (checked && !values.published) {
                                            this.context.toast.show({
                                                open: true, severity: "warning", autoHideDuration: 7000,
                                                content: <p>{AppContext.r["event-must-be-published"]}</p>
                                            });
                                        } else {
                                            setFieldValue("autostart", checked);
                                        }
                                    }
                                    } />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{AppContext.r["event-location-type-where"]}</FormLabel>

                                <RadioGroup
                                    className="location-types"
                                    aria-labelledby="radio-buttons-group-location"
                                    defaultValue={values.location_type_id === null ? 0 : values.location_type_id}
                                    name="location_type_id"
                                    onChange={(event) => {
                                        const value = parseInt(event.currentTarget.value);
                                        setFieldValue("location_type_id", value > 0 ? value : null);
                                    }}>
                                    <FormControlLabel value={0} control={<Radio />} label={AppContext.r["unspecified"]} />
                                    <FormControlLabel value={1} control={<Radio />} label={AppContext.r["online"]} />
                                    <FormControlLabel value={2} control={<Radio />} label={AppContext.r["on-site"]} />
                                    <FormControlLabel value={3} control={<Radio />} label={AppContext.r["both-online-on-site"]} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        {values.id &&
                            <Grid item xs={12} sm={12}>
                                <Box marginTop={2}>
                                    <ShareEvent event={values} label={AppContext.r["event-online-at"]} disableIcons />
                                </Box>
                            </Grid>}

                        <Grid item xs={12} marginTop={1}>
                            {values.id && <>
                                <EntityNotifications values={values} />
                            </>}
                        </Grid>
                    </Grid>

                    <Grid container item spacing={1} sm={4}>
                        <MediaStockImage sx={{ width: 56, height: 56 }}
                            apiPath={this.apiPath}
                            imageUrl={(values.id && (item.media && item.media[0])) ? this.imageUrl : null}
                            item={values}
                            onImageFileChanged={this.onImageFileChanged} />
                    </Grid>
                </Grid>

                {/* Tickets */}

                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12}>
                        <h3>{AppContext.r["tickets"]}</h3>
                    </Grid>

                    <Grid item xs={12} sm={3} md={3}>
                        <FormControlLabel control={<Field component={FormikSwitch} type="checkbox" name="published" checked={values.is_free} />}
                            label={AppContext.r["this-is-a-free-event"]} onChange={(e, checked) => {
                                if (!checked && !store.has_stripe_setup) {
                                    this.context.toast.show({
                                        open: true, severity: "warning", autoHideDuration: 7000,
                                        content: <>
                                            <p>{AppContext.r["must-setup-stripe"]}</p>
                                            <Link to={"/" + store.subdomain + "/settings"}>{t('store-settings', { store: store.name })}</Link>
                                        </>
                                    });
                                } else {
                                    setFieldValue("is_free", checked);
                                }

                                if (!checked)
                                    this.calculateFees(values.price);
                            }} />
                    </Grid>

                    <Grid item xs={12} sm={9} md={9}>
                        {values.is_free && store.licence &&
                            <>{AppContext.r["licence"]}: <b>{store.licence.title}</b> {" | " + store.licence.max_attendants_count + " " + AppContext.r["players"]}</>}
                    </Grid>

                    {!values.is_free &&
                        <>
                            <Grid item xs={4} md={3}>
                                <FormControl fullWidth sx={{ m: 1 }}>
                                    <InputLabel htmlFor="outlined-adornment-amount">{AppContext.r["price"]}</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        value={values.price}
                                        onChange={(e) => {
                                            setFieldValue('price', (e.target['value']));
                                            this.calculateFees(e.target['value']);
                                        }}
                                        startAdornment={<InputAdornment position="start">{store.locale.currency_symbol}</InputAdornment>}
                                        label="Amount" />
                                </FormControl>
                            </Grid>

                            <Grid item xs={4} md={3}>
                                <div className="outlined-with-label transaction-fee">
                                    &nbsp;&nbsp;&nbsp;{store.locale.currency_symbol + " " + transactionFee.toFixed(2)}
                                    <label>{AppContext.r["transaction-fee"]}</label>
                                </div>
                            </Grid>

                            <Grid item xs={4} md={3}>
                                <div className="outlined-with-label you-earn">
                                    &nbsp;&nbsp;&nbsp;{store.locale.currency_symbol + " " + netIncome.toFixed(2)}
                                    <label>{AppContext.r["you-earn"]}</label>
                                </div>
                            </Grid>

                            {values.id &&
                                <>
                                    {(!values.vouchers || values.vouchers.length === 0) &&
                                        <Grid item xs={12}>
                                            <ActionButton className="square"
                                                action={async () => {
                                                    const response = await IqSpaceApi.GenerateFreeTickets(values);

                                                    if (response && response.status === 201)
                                                        setFieldValue("vouchers", response.data.data)
                                                    else
                                                        this.context.toast.show({
                                                            open: true, severity: "error", autoHideDuration: 5000,
                                                            content: "Error"
                                                        });
                                                }}>
                                                &nbsp; {AppContext.r["generate-free-tickets"]}
                                            </ActionButton>
                                        </Grid>}

                                    {(values.vouchers && values.vouchers.length > 0) &&
                                        <Grid item xs={12}>
                                            <p>{AppContext.r["free-tickets-desc"]}</p>

                                            <div className="free-tickets">
                                                {values.vouchers.map(v =>
                                                    <Chip disabled={v.updated_at} variant="outlined" label={v.code} icon={<Icon className="fas fa-ticket-alt" />} />)}
                                            </div>
                                        </Grid>}
                                </>}
                        </>}

                    {values.id && <EventExtraTabs event={values} store={store} />}
                </Grid >
            </>);
    }

    onDeleteItem = (response) => {
        if (response && response.status === 423)
            this.props.setAlert({ open: true, message: AppContext.r["event-locked"], severity: "error" });
    }

    render() {
        const { store } = this.state;

        const columns = [{
            field: 'title',
            headerName: t('title'),
            minWidth: 200,
            flex: 1,
            sortable: false,
            renderCell: params => {
                const { row } = params;

                return (
                    <div className="td-v-center">
                        <div className="row-thumb">
                            {(row.media && row.media[0]) && <>
                                <img src={`${Settings.apiUrl}${this.apiPath}/${row.id}/image/thumb`} alt="Thumb" />
                            </>}
                            {(!row.media || !row.media[0]) && <img src={AppContext.s["placeholder"]} className="placeholder-thumb" alt="Thumb" />}
                        </div>

                        {row.title}
                    </div>
                );
            }
        },
        {
            field: 'price',
            headerName: AppContext.r["price"],
            minWidth: 60,
            sortable: false,
            renderCell: params => {
                const { row } = params;

                let p = this.state.store.locale.currency_symbol + " " + row.price;

                if (row.price === 0 || !row.price || row.is_free)
                    p = AppContext.r["free"];

                return <div className="td-v-center">{p}</div>;
            }
        },
        {
            field: 'datetime',
            headerName: AppContext.r["datetime"],
            minWidth: 160,
            sortable: false,
            renderCell: params => {
                const { row } = params;

                return <div className="td-v-center" style={{ minWidth: "100px" }}>
                    {!row.date_time ? "" : format(new Date(row.date_time), "d/MM/yyyy H:mm")}
                </div>;
            }
        }];

        return (
            <div className="page module events">
                {store &&
                    <Module
                        paginated
                        apiUrl={Settings.apiUrl}
                        accessApiPath={"/events?store_id=" + store.id}
                        modal={this.props.modal} setAlert={this.props.setAlert}
                        apiPath={this.apiPath}
                        path={"/events"}
                        store={store} id={this.props.id} tableHead={columns}
                        view={this.view} viewClassName="event-mask"
                        schema={this.schema}
                        onInsertItem={this.onInsertItem}
                        onFetchedItem={this.onFetchedItem}
                        initialValues={this.initialValues}
                        insertDataAdapter={this.insertDataAdapter}
                        updateDataAdapter={this.updateDataAdapter}
                        authActions={this.props.authActions}
                        onDeleteItem={this.onDeleteItem}
                        enablePublishedQuickToggle />}
            </div>
        );
    }
}

const mapDispatchToProps = () => ({
    setAlert
});

export default connect(null, mapDispatchToProps())(withModal(withUderlyStore(Events)));