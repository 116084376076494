import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import TvIcon from '@mui/icons-material/Tv';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ShareIcon from '@mui/icons-material/Share';

import 'react-quill/dist/quill.snow.css';

import AppContext from 'app/AppContext';
import Settings from '../../app/settings';

export default class ShareEvent extends React.Component {
    state = {
        mode: 0
    };

    handleChange = (e, value) => {
        this.setState({ mode: value });
    }

    render() {
        const { event } = this.props;
        const { mode } = this.state;

        const path = "/event/" + event.id + (mode === 1 ? "/maxi" : "");
        const displayUrl = Settings.project.web.domain + path;

        return (
            <div className="shareable-event-links">
                <BottomNavigation className="modes" value={mode} onChange={this.handleChange}>
                    <Tooltip title={<p className="mode-tooltip">{AppContext.r["play-desc"]}</p>}>
                        <BottomNavigationAction value={0} label={AppContext.r["play"]} icon={<PhoneIphoneIcon />} />
                    </Tooltip>

                    <Tooltip title={<p className="mode-tooltip">{AppContext.r["project-desc"]}</p>}>
                        <BottomNavigationAction value={1} label={AppContext.r["project"]} icon={<TvIcon />} />
                    </Tooltip>
                </BottomNavigation>

                <a href={Settings.isLocalhost() ? path : ("https://" + displayUrl)} target="_blank" rel="noopener noreferrer">
                    {displayUrl}
                </a>

                <ShareIcon className="share-icon" />

                {this.props.rightLabel &&
                    <span className="details-label">{this.props.rightLabel}</span>}
                {this.props.label &&
                    <span className="title-label">{this.props.label}</span>}
            </div>);
    }
}