import CheckIcon from '@mui/icons-material/Check';

import Settings from '../../settingsOld';

export default function loadClientResources (resources) {
    resources["home"] = "Home";
    resources["about-us"] = "Su di Noi";
    resources["mission"] = "Mission";
    resources["technologies"] = "Tecnologie";
    resources["friends"] = "Amici";
    resources["board"] = "Board";
    resources["contact"] = "Contattaci";
    resources["help-and-support"] = "Assistenza";
    resources["cms"] = "CMS";
    resources["content-management-systems"] = "Content Management System";
    resources["innovation"] = "Innovazione";
    resources["gaming"] = "Gaming";
    resources["itservices"] = "Servizi IT";
    resources["creative-solutions"] = "Soluzioni Creative";
    resources["designer-websites"] = "Siti Web di Designer";
    resources["who-we-are"] = "Chi siamo";
    resources["work-with-us"] = "Lavora con noi";
    resources["learn-more"] = "Scopri di più";
    resources["tell-your-idea"] = "Raccontaci la tua idea e ci metteremo in contatto con te.";
    resources["socials-tagline"] = "Servizi e soluzioni innovativi che miglioreranno la tua immagine e performance.";
    resources["connect-with-us-on"] = "Teniamoci in contatto su";
    resources["ready-to-start"] = "Pronto per partire?";
    resources["members"] = "Membri";
    resources["invoices"] = "Fatture";
    resources["not-affiliated"] = <><b>Non</b> risultano affiliazioni ai nostri servizi.</>;
    resources["view"] = "Visualizza";
    resources["paid"] = "Pagato";
    resources["not-paid"] = "Non Pagato";
    resources["manage-your-memberships"] = "Gestisci le tue affiliazioni";

    resources["home-iqspace-desc"] = <>
        <p>IQ Space è il gioco a quiz dal vivo per il tuo divertimento. </p>
        <p>Gioca con i tuoi amici o colleghi e ospita il tuo gioco gratuitamente.</p>
        <p>Sfrutta le possibilità di una tecnologia in <b>real time</b> usando solo il tuo smartphone!</p>
    </>;
    resources["home-playwithfriends-desc"] = <>
        <h3>Gioca con gli amici</h3>
        <p>Gioca a IQ Space con i tuoi amici dove e quando vuoi!</p>
        <p>IQ Space è <b>gratuito da usare</b>, ti servirà solo il tuo smartphone!</p>
        <p><b>Sfida i tuoi amici</b> con tantissime domande diverse a quiz.</p>
    </>;
    resources["home-fun-desc"] = <>
        <h3>Cerchi più divertimento?</h3>
        <p>Puoi vendere ticket per i tuoi eventi e assegnare premi per sfidare i giocatori!</p>

        <div className="tickets-col">
            <h5>Monetizza dai tuoi eventi</h5>

            <div className="check-list">
                <div className="item">
                    <CheckIcon />
                    <p>Stabilisci un prezzo! Decidi tu quanto vale la partecipazione ai tuoi eventi (min € 0.50).</p>
                </div>

                <div className="item">
                    <CheckIcon />
                    <p>Fino a <strong>90 giocatori</strong>.<br /><small>Te ne servono di più? Contattaci!</small></p>
                </div>

                <div className="item">
                    <CheckIcon />
                    <p>Nessun contratto. Nessun impegno. Nessuna seccatura.</p>
                </div>

                <div className="item">
                    <CheckIcon />
                    <p>Paghi soltanto una transazione bancaria per i ticket che vendi.</p>
                </div>
{/* 
                <div className="item">
                    <CheckIcon />
                    <p>Paghi soltanto <b>{Settings["pricing"].perc} % + {Settings["pricing"].fixed} €</b> di transazione per i ticket che vendi.</p>
                </div> */}

                <div className="item">
                    <CheckIcon />
                </div>

                <div className="item">
                    <CheckIcon />
                    <p>I tuoi giocatori pagheranno una quota di prenotazione per ogni ticket che acquistano.</p>
                </div>
{/* 
                <div className="item">
                    <CheckIcon />
                    <p>I tuoi giocatori pagheranno una quota di prenotazione<br/>a partire da {Settings["pricing"].bookingStartsFrom} € per ogni ticket che acquistano.</p>
                </div> */}
            </div>
        </div>
    </>;
    resources["home-business-desc"] = <>
        <p>Hai bisogno di una spinta per la tua attività?</p>
        <p>Stai cercando qualcosa di divertente per fare "team building" nella tua azienda?</p>
        <p>IQ Space è il quiz dal vivo che lo rende possibile.</p>
    </>;

    resources["become-quizmaster"] = "Diventa un intrattenitore";

    resources["iqspace-tagline-0"] = "Let's get together";
    resources["iqspace-tagline-1"] = "Il nuovo live quiz per smartphone per il tuo intrattenimento";
    resources["iqspace-tagline-2"] = "IQ Space è il nuovo live quiz che manderà in orbita il tuo business di intrattenimento. 🚀";

    resources["start-now"] = "Inizia Adesso";
    resources["play-now"] = "Gioca";
    resources["play"] = "Gioca";
    resources["start-now-for-free"] = "Inizia adesso gratis";

    resources["iqspace-features"] = [{
        title: "Gioca con i tuoi amici",
        desc: "Prova subito IQ Space gratuitamente e crea la tua \"virtual room\": divertiti con i nostri quiz direttamente dal tuo smartphone!",
        icon: "/assets/images/carousel/play-with-friends-iq-space.png",
        to: ""
    },{
        title: "Chat",
        desc: "Chat, Chat Chat! Un'interazione \"social\" e istantanea darà vita al tuo momento virtuale.",
        icon: "/assets/images/carousel/chat-friends-game-iq-space.png",
        to: ""
    },{
        title: "Team building",
        desc: "Lo strumento definitivo per il team bulding. Raduna il tuo team, lasciali chattare tra di loro, senti la competizione. Connetti persone grazie al web.",
        icon: "/assets/images/carousel/competition-iq-space.png",
        to: ""
    },
    // {
    //     title: "Live stream con Zoom",
    //     desc: "Zoom integrato \"in-app\" per un coinvolgimento real-time e faccia a faccia con i giocatori.",
    //     icon: "/assets/images/carousel/zoom-embedded-app-iq-space.png",
    //     to: ""
    // },
    {
        title: "Ticket",
        desc: "Monetizza dai tuoi eventi, vendi partecipazioni al tuo evento direttamente tramite il sistema di ticket integrato.",
        icon: "/assets/images/carousel/ticket-sell-iq-space.png",
        to: ""
    },{
        title: "Promuovi il tuo business",
        desc: "Lo strumento definitivo e personalizzabile per la promozione del tuo business. Riproduci domande multimediali personalizzate. Promuovi ed informa i giocatori riguardo il tuo business.",
        icon: "/assets/images/carousel/promote-your-business-iq-space.png",
        to: ""
    }];
    resources["prices-exclude-vat"] = "Tutti i prezzi sono al netto dell'IVA.";
}