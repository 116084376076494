export default function loadClientResources (resources) {
    // Forms
    resources["field-too-short"] = "Troppo corto";
    resources["field-too-long"] = "Troppo lungo";
    resources["field-required"] = "Campo Richiesto";
    resources["invalid-email"] = "E-mail non valida";
    resources['phone-not-valid'] = "Numero di telefono non valido.";
    resources["request-form-accept"] = "Ho letto ed accetto privacy policy, condizioni e termini e trattamento dei dati.";
    resources["request-form-accept-newsletter"] = "Accetti di voler ricevere newsletter periodiche di informazione e marketing da Uderly?";
    resources["full-name"] = "Nome Completo";
    resources["company-name"] = "Azienda";
    resources["email"] = "e-mail";
    resources["phone"] = "Telefono";
    resources["title"] = "Titolo";
    resources["message"] = "Messaggio";
    resources["dark-theme"] = "Tema Scuro";
    resources["created-at"] = "Creato il";
    
    resources["copy-link"] = "Copia Link";
    resources["copied"] = "Copiato";
    resources["click-to-view-qr"] = "Visualizza il codice QR";
    resources["image-instructions"] = <>
        <p>Trascina qui un'immagine</p>
        <p><small>Formati supportati .gif, .jpeg, .png</small></p>
    </>;
    
    resources["saving"] = "Salvataggio...";
    resources["close"] = "Chiudi";
    resources["create-item-first"] = "Crea la scheda prima di trasferire l'immagine.";
    resources["confirm-delete"] = "Sei sicuro di volere eliminare questa scheda?";
    resources["confirm-delete-heading"] = "Elimina questa scheda";
    resources["insert"] = "Inserisci";
    resources["create"] = "Crea";
    
    resources["tables"] = "Tabelle";
    resources["social-type"] = "Tipo Social";
    resources["social-types"] = "Tipi Social";
    resources["social"] = "Social";
    resources["socials"] = "Social";
    resources["stream"] = "Stream";
    resources["streams"] = "Stream";
    resources["stream-type"] = "Sorgente Stream";
    resources["stream-types"] = "Sorgenti Stream";
    resources["report"] = "Report";
    resources["reports"] = "Report";
    resources["change"] = "Cambia";
}