import React from "react";
import Button from '@mui/material/Button';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

import AppContext from "app/AppContext";
import IqSpaceApi from "api/IqSpace";
import withUderlyStore from "../../zustand/withUderlyStore";
import { EventAttendanceStatusesEnum } from "./BuyTicket";

class InterestedButton extends React.Component {
  state = {
    user: this.props.zustand.user,
    interested: this.props.eventAttendance && this.props.eventAttendance.status_id !== 5
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.zustand.user !== this.state.user) {
      this.setState({
        user: nextProps.zustand.user,
        interested: !nextProps.zustand.user ? false : this.state.interested
      });
    }
  }

  handleClick = async () => {
    const { interested } = this.state;
    const { event } = this.props;

    if (!this.state.user) {
      this.props.authActions.onLogin();
    } else {
      let response = null;

      if (!interested) {
        response = await IqSpaceApi.AttendEvent(event.id, EventAttendanceStatusesEnum.interested)
      } else {
        response = await IqSpaceApi.AttendEvent(event.id, EventAttendanceStatusesEnum.notInterested)
      }

      if (response && response.status === 200)
        this.setState({
          interested: !interested
        });
    }
  }

  render() {
    const { interested } = this.state;

    const className = "following-button ";

    return interested ? (
      <Button variant="outlined" color="secondary" className={className + " interested"}
        onClick={this.handleClick}
        startIcon={<HeartBrokenIcon />}>
        {AppContext.r["not-interested"]}
      </Button>
    ) : (
      <Button variant="outlined" color="primary" className={className + " not-interested"}
        onClick={this.handleClick}
        startIcon={<FavoriteIcon />}>
        {AppContext.r["interested"]}
      </Button>
    )
  }
}

export default withUderlyStore(InterestedButton);