import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Logo from './Logo';
import SiteMapItem from '../models/SiteMapItem';

export function scrollTo(selector: string, navBarHeight = 70) {
  const el = document.querySelector(selector);

  if (el) {
    const r = el.getBoundingClientRect();
    window.scrollTo(r.left, r.top + window.scrollY - navBarHeight);
  }
};

export interface HeaderProps {
  siteMap: SiteMapItem[];
  navBarHeight: number;
  alwaysShowBackground?: boolean;
  accountMenu: any | null;
  hideNavigationMenu?: boolean;
  hideLogo?: boolean;
  centeredLayout?: boolean;
  scrolledThreshold?: number; // 0 to 1
  onToggle?: (open: boolean) => void;
}

const Header = (props: HeaderProps) => {
  const { siteMap, scrolledThreshold, alwaysShowBackground, accountMenu, hideLogo, centeredLayout, hideNavigationMenu, onToggle } = props;

  const [open, setOpen] = useState(false);
  const [submenuIndexOpen, setSubmenuIndexOpen] = useState<string | null>(null);
  const [scrolled, setScrolled] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SiteMapItem | null>(siteMap[0]);

  const itemsMap = useRef<{ [to: string]: SiteMapItem[] }>();

  const findSelectedItem = useCallback(() => {
    siteMap.forEach(item => {
      for (const k in itemsMap.current) {
        if (item.to === k && item.to === window.location.pathname) {
          if (item.scrollTo) {
            const el = document.querySelector(item.scrollTo);

            if (el) {
              const br = el.getBoundingClientRect();

              if (br && br.y <= 100) {
                setSelectedItem(item);
              }
            }
          } else {
            setSelectedItem(item);
          }
        }
      }
    });
  }, [siteMap]);

  const onScroll = useCallback(() => {
    const scrollY = Math.min(window.scrollY / window.innerHeight, 1); // Perc
    const threshold = scrolledThreshold ? scrolledThreshold : 0.92;

    if (scrollY >= threshold && !scrolled && !open)
      setScrolled(true);
    else if (scrollY < threshold && scrolled)
      setScrolled(false);

    findSelectedItem();
  }, [findSelectedItem, scrolled, scrolledThreshold]);

  useEffect(() => {
    // It creates a rearranged map of the website items according to their "to" parameter

    if (!itemsMap.current) {
      itemsMap.current = {}

      for (const item of siteMap) {
        if (itemsMap.current) {
          if (!itemsMap.current[item.to])
            itemsMap.current[item.to] = [];
          itemsMap.current[item.to].push(item);
        }
      }
    }

    setTimeout(findSelectedItem, 100);

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    }
  }, [findSelectedItem, onScroll, siteMap]);

  const toggleMenu = () => {
    if (onToggle) onToggle(!open);
    setOpen(!open);
  }

  const onClickSubMenuTitle = (index: string | null = null) => {
    if (index === null) {
      setOpen(false);
      if (onToggle) onToggle(false);
    }

    // const selector = "#submenu-" + (index ? index : submenuIndexOpen);
    // const subMenuEl = document.getElementById(selector);

    // TODO: Calc height of sub items
    // if (subMenuEl) {
    //   const vPadding = 0;
    //   let height = 0;

    //   if (index && index !== this.state.submenuIndexOpen) {
    //     const children = document.querySelectorAll(selector);
    //     for (const child of children) {
    //       const style = getComputedStyle(child);
    //       height += child.getBoundingClientRect().height + parseInt(style.marginTop) + parseInt(style.marginBottom);
    //     }

    //     height += (vPadding * 2);
    //   }

    //   subMenuEl.style.height = height + "px";
    // }

    setSubmenuIndexOpen(index === submenuIndexOpen ? null : index);

    findSelectedItem();
  }

  const closeSubMenu = () => onClickSubMenuTitle();

  const onRoute = (x: SiteMapItem) => {
    // if (x.to !== window.location.pathname)
    //   AppContext.animatePageChange();

    closeSubMenu();
  }

  const onClickMenuItem = (x: SiteMapItem) => {
    if (x.scrollTo) {
      const timing = (x.to !== window.location.pathname) ? 500 : 0;

      setTimeout(() => {
        if (x.scrollTo)
          scrollTo(x.scrollTo, props.navBarHeight);
      }, timing);
    }

    setSelectedItem(x);
  }

  const handleClickAway = () => {
    if (window.innerWidth >= 600) {
      // this.setState({ submenuIndexOpen: null });
      onClickSubMenuTitle();
    }
  }

  const item = (x: SiteMapItem, index: string, isSelected?: boolean) => {
    if (isSelected === undefined)
      isSelected = !selectedItem ? false : (selectedItem.to === x.to && selectedItem.scrollTo === x.scrollTo);

    if (x.hideFromHeader)
      return null;

    if (x.to || x.scrollTo) {
      const isExternal = x.to && (x.to.indexOf("https://") === 0 || x.to.indexOf(".pdf") > 0);

      return (
        <Button key={"menu-item-" + index}
          onClick={() => onClickMenuItem(x)}
          className={(isSelected ? "selected" : "")}>
          {(isExternal && x.to) &&
            <a href={x.to} onClick={closeSubMenu} target="_blank" rel="noopener noreferrer">{x.title}</a>}

          {(!isExternal && x.to) &&
            <Link to={x.to} onClick={() => onRoute(x)}>{x.title}</Link>}
        </Button>);
    }

    if (x.submenu) {
      return (
        <ClickAwayListener key={"menu-item-" + index}
          mouseEvent="onMouseDown" touchEvent="onTouchStart"
          onClickAway={handleClickAway}>
          <div className={"submenu-container" + ((submenuIndexOpen === index) ? " open" : "") + (isSelected ? "selected" : "")}>
            <button onClick={() => onClickSubMenuTitle(index)}>{x.title}</button>

            <div id={"submenu-" + index}
              className={"submenu " + ((submenuIndexOpen === index) ? "open" : "")}>
              {x.submenu.map((y, j) => item(y, (index + j).toString(), isSelected))}
            </div>
          </div>
        </ClickAwayListener>);
    }

    return "";
  }

  const itemsCount = siteMap.filter(m => !m.hideFromHeader).length;

  return (
    <header className={(open ? "open" : "")
      + (alwaysShowBackground || scrolled ? " scrolled" : "")
      + (centeredLayout ? " centered-layout" : "")}>
      <div className="nav-bar">
        {!hideLogo && !centeredLayout &&
          <Logo mini to={"/"} />}

        {!hideNavigationMenu &&
          <>
            <div className={"hamburger-icon " + (open ? "open" : "")}
              onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>

            <div className="menu" style={accountMenu ? { marginRight: "3em" } : {}}>
              {!centeredLayout &&
                siteMap.map((x, index) => item(x, index.toString()))}

              {centeredLayout && <>
                <Logo to={"/"} dark className="mobile-only"
                  onClick={() => { onClickMenuItem(siteMap[0]); toggleMenu(); }} />

                <div className="left">
                  {siteMap.map((x, index) => (index < Math.floor(itemsCount / 2)) ? item(x, index.toString()) : null)}
                </div>

                <div className="gap-box">&nbsp;</div>

                <Logo to={"/"} className="mobile-hidden" />

                <div className="gap-box">&nbsp;</div>

                <div className="right">
                  {siteMap.map((x, index) => (index >= Math.ceil(itemsCount / 2)) ? item(x, index.toString()) : null)}
                </div>
              </>}
            </div>
          </>
        }

        {accountMenu}
      </div>
    </header>);
}

export default Header;