import React from 'react';
import { Image } from 'react-bootstrap'
// import useSound from 'use-sound';

import AppContext from "../app/AppContext"
import FullScreenMessage from "./FullScreenMessage"
import Socials from "../framework/Socials";
import Words from "../app/components/Words";
import Settings from "../app/settings";
import GameStatus from '../models/GameStatus';
import AdViewer, { AdTypeEnum } from '../app/components/AdViewer';
import SoundsManager from './SoundsManager';

export interface BreakingTimeProps {
	gameStatus: GameStatus;
	isRecorder?: boolean;
}

const BreakingTime = (props: BreakingTimeProps) => {
	const { gameStatus, isRecorder } = props;
	// gameStatus.isShowingAdvertisement = true;

	// const [speechFollowUs] = useSound('/assets/iqspace/sounds/speech_follow_us.mp3', {
	// 	volume: 0.99,
	// });

	return (
		<div className="breaking-time">
			<div className="content">
				<div className="ads-show">
					<div className='item selected'>
						<FullScreenMessage text={(<Image src={"/assets/iqspace/images/logo-text-white.png"} alt="IQ Space" />)} />
					</div>
				</div>
			</div>

			{gameStatus.isShowingAdvertisement &&
				<>
					<Words durationMs={3000} loop={false} text={AppContext.r["like-us-desc"]} />
					<Socials />

					{isRecorder &&
						<SoundsManager sound={"speech-follow-us"} loop={false} enabled={true} />}
				</>}

			<p className="text-center">{AppContext.r["become-a-quizmaster"]}</p>

			<p><a href={`http://${Settings.project.web?.domain}`} target='_blank' rel="noreferrer">{Settings.project.web?.site}</a></p>
			{/* Settings.storeId! */}
			{gameStatus.isShowingAdvertisement &&
				<AdViewer useUderlyApi adType={AdTypeEnum.banner} storeId={1} requestAdById={20} />}

			{!gameStatus.isShowingAdvertisement &&
				<>
					<p className="text-center">{AppContext.r["like-us-desc-short"]}</p>
					<Socials />
				</>}
		</div>);
}

export default BreakingTime;