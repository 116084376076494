// TOMOVE to lib

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { defaultSettings, AdPublicRequest, entityMediaUrl, getAds, HasMedia } from "@uderly/react-uderly-ui";

export enum AdTypeEnum {
  banner = 1,
  video = 2
}

export interface Ad extends HasMedia {
  title: string;
  url?: string;
  type_id: number;

  type?: AdType;
  subType?: AdType;
}

export interface AdType {
  title: string;
  id: number;
  metadata?: string;
  parent_ad_type_id?: number;
}

export interface BannerSubTypeMetadata {
  width?: number;
  height?: number;
}

export interface AdProps extends AdPublicRequest {
  adType: AdTypeEnum;
  className?: string;
}

const AdViewer = (props: AdProps) => {
  const navigate = useNavigate();

  const [ads, setAds] = useState<Ad[]>();

  useEffect(() => {
    const fetchAds = async () => {
      const response = await getAds(props);

      if (response && response.status === 200) {
        setAds(response.data.data);
      }
    }

    fetchAds();
  }, [props]);

  const ad: Ad | null = (ads && ads.length > 0 && ads[0]) ? ads[0] : null;
  const metadata = (ad && ad.subType && ad.subType.metadata) ? JSON.parse(ad.subType.metadata) : {} as BannerSubTypeMetadata;

  const onClickAd = () => {
    if (ad?.url)
      if (ad?.url.startsWith("http"))
        window.location.href = ad?.url;
      else
        navigate(ad?.url);
  }


  if (ad && ad.media && ad.media.length > 0) {
    const imageMediaUrl = props.useUderlyApi ?
      `${defaultSettings.apiUrl}/ads/${ad.id}/media/file?name=${ad.media[0].file_name}` :
      entityMediaUrl(ad.id, "ads", ad.media[0].file_name);

    return <div
      onClick={onClickAd}
      className={`ad banner screen-only ${props.className && ''}`}
      style={{
        width: metadata.width,
        height: metadata.height,
        backgroundImage: `url(${imageMediaUrl})`
      }}>
    </div>;
  }

  return null;
}

export default AdViewer;