import React from 'react';
import { Store } from '../../zustand/storesSlice';
import { useTranslation } from 'react-i18next';
import { formatInTimeZone } from 'date-fns-tz';

interface LicenceProps {
  store: Store;
}

const Licence = (props: LicenceProps) => {
  const { t } = useTranslation();

  const { store } = props;

  if (!store || !store.licence) return null;

  return <div className="licence-box">
    <p>
      {t('your-licence')}: <span className={'licence-type ' + store.licence.title.toLowerCase()}>{store.licence.title}</span>
    </p>

    {store.licence_ends_at &&
      <p className="licence-expiration">{t('licence-expires-on')} {
        formatInTimeZone(new Date(store.licence_ends_at), store.timezone as string, "dd/MM/yyyy H:mm")}</p>}
  </div>;
}

export default Licence;
