import React, { Component } from 'react';

import GameStatusEnum from './GameStatusEnum';

export default class Quiz extends Component {
    constructor(props) {
        super(props);

        const { myPlayer, question } = props.gameStatus;

        this.state = {
            gameStatus: props.gameStatus,
            selectedAnswer: (myPlayer && question && myPlayer.questionId === question.questionId) ? myPlayer.lastAnswer : -1,
            allowResponding: props.allowResponding
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.gameStatus !== state.gameStatus
            || props.allowResponding !== state.allowResponding) {
            const { myPlayer, question } = props.gameStatus;

            // console.log(myPlayer, state.selectedAnswer)
            return {
                gameStatus: props.gameStatus,
                selectedAnswer: (myPlayer && question && myPlayer.questionId === question.questionId) ? myPlayer.lastAnswer : state.selectedAnswer,
                allowResponding: props.allowResponding
            };
        }

        return null;
    }

    onAnswer = (i) => {
        const s = this.state.gameStatus;

        if (this.props.onPreviewSubmitAnswer)
            this.props.onPreviewSubmitAnswer(i);

        if (s && s.gameStatus === GameStatusEnum.AnswersTimerOn && this.state.allowResponding) {
            // console.log("Clicked on " + i);

            if (this.state.selectedAnswer < 0) {
                this.setState({
                    selectedAnswer: i
                })

                if (this.props.onSubmitAnswer)
                    this.props.onSubmitAnswer(i);
            }
        }
    }

    render() {
        const s = this.state.gameStatus;
        const q = this.state.gameStatus.question;

        if (q != null && q.question != null && q.answers != null) {
            let k = 0, i = 0;

            const answers =
                q.answers.map(a => {
                    var answerClassName = "answer";

                    if (s.gameStatus === GameStatusEnum.AnswersTimerOn) {
                        if (i === this.state.selectedAnswer)
                            answerClassName += " selected";
                    }

                    if (s.gameStatus === GameStatusEnum.AnswersTimerOff) {
                        if (i === this.state.selectedAnswer)
                            answerClassName += " selected";
                    }

                    if (s.gameStatus === GameStatusEnum.CorrectAnswer) {
                        if (i === this.state.selectedAnswer && q.correctAnswer !== i)
                            answerClassName += " wrong";
                        if (q.correctAnswer === i)
                            answerClassName += " correct";
                    }

                    const letter = (q.showAnswers !== true) ? "" : String.fromCharCode(65 + i);
                    const answer = (q.showAnswers !== true) ? "" : a;

                    const letterClassName = "letter " + letter;
                    const answerContainerClassName = (q.showAnswers !== true) ? "" : "fade-in";
                    const index = i++;
                    const answerStyle = { display: (q.showAnswers === true && answer.length > 0) ? "block" : "none" };

                    return (
                        <tr style={answerStyle} className={answerContainerClassName + " answer-container"} key={k++} onClick={() => this.onAnswer(index)}>
                            <td className="td-letter"><span className={letterClassName}>{letter}</span></td>
                            <td className="td-answer"><span className={answerClassName}>{answer}</span></td>
                        </tr>);
                });

            const note = (q.note !== null && q.note.length > 0 && this.state.gameStatus.gameStatus === GameStatusEnum.CorrectAnswer) ?
                <div className="note-box">{q.note}</div> : "";

            return (
                <div className="quiz">
                    <h1 className="slide-in">{q.question}</h1>

                    <table>
                        <tbody>
                            {answers}
                        </tbody>
                    </table>

                    {note}
                </div>
            );
        }

        return "";
    }
}
