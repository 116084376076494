const settings = [];

settings['provider'] = {
    name: 'Uderly',
    website: 'www.uderly.com',
    websiteUrl: 'https://www.uderly.com',
    contactFormUrl: 'https://www.uderly.com/#contact',
    logoPath: "/assets/images/uderly-32.png",
    facebookPageUrl: "https://www.facebook.com/uderly",
    linkedinPageUrl: "https://www.linkedin.com/company/uderly/"
};
settings["project-name"] = "IQ Space";
settings["project-website"] = "iqspace.live";
settings["domain"] = "iqspace.live";
settings["sub-title"] = "";
settings["language"] = null; // Defined in BasePage
settings["currency"] = "£";

settings["logo-grey"] = "/assets/images/uderly-payoff.png";
settings["logo-dark"] = "/assets/images/uderly-payoff.png";
settings["logo-light"] = "/assets/images/uderly-payoff-light.png";

settings["iqspace-logo-light"] = "/assets/iqspace/images/logo-text-white.png";

settings["mini-logo-dark"] = "/assets/iqspace/images/logo.png";
settings["mini-logo-light"] = "/assets/iqspace/images/mini.png";
settings["placeholder"] = "/assets/iqspace/images/logo.png";
settings["password-min-length"] = 8;

settings['facebook-page-id'] = '104696300956103'; // Iq Space ~ 104751018345289
settings['facebook-app-id'] = '954928778580117';

settings["client-id"] = "2";
settings["client-secret"] = "0dIONiMg3HBDq6AID9dkFXxu8gNeWBQ6XLfxQtdu";
settings["domain"] = "iqspace.live";

settings["facebook-page-url"] = "https://www.facebook.com/iqspace.live";
settings['instagram-page-url'] = 'https://www.instagram.com/iqspace.live';
settings['tiktok-page-url'] = 'https://www.tiktok.com/@iqspace.live';

settings["google-maps-key"] = "AIzaSyBBduueLb6PfFIa5Z811RxdusHYeP3-u-I";

settings["redux-auth-key"] = "uderly-auth-key";
settings["redux-cart-key"] = "uderly-cart-key";

settings['phone'] = "";
settings['email'] = "iqspace@uderly.com";

settings['primary-color'] = "#008170";
settings['secondary-color'] = "#f5bd4a";

settings["splashscreen-image"] = "/assets/images/uderly-payoff.png";
settings["splashscreen-text"] = settings["project-name"];

// Iq Space
const iqSpaceRoot = "/assets/iqspace/";

settings["intermission-slide-1"] = iqSpaceRoot + "/images/logo-light.png";
settings["intermission-slide-2"] = iqSpaceRoot + "/images/breaking-time.png";

settings["gold-medal-path"] = iqSpaceRoot + "/images/gold-medal.png";
settings["silver-medal-path"] = iqSpaceRoot + "/images/silver-medal.png";
settings["bronze-medal-path"] = iqSpaceRoot + "/images/bronze-medal.png";
settings["bulb"] = iqSpaceRoot + "/images/bulb.png";
settings["trophy"] = iqSpaceRoot + "/images/cup.png";
settings["live"] = iqSpaceRoot + "/images/live.png";
settings["quiz-cookie-name"] = "quiz-iqspace.live";

settings["zoom-api-key"] = "JYSWEnaUTtmT9qW6M_Godg";
settings["zoom-api-secret"] = "bi6Qwjxli9JJsxvtk8GBDbw4ovOm0cWy1ybv";

settings["pricing"] = {
    perc: "5.9",
    fixed: "0.25",
    bookingStartsFrom: "0.60"
};

export default settings;
