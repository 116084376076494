import { createSlice } from '@reduxjs/toolkit'

import IqSpaceApi from "../api/IqSpace";

const initialState = { 
    store: null
};

const iqSlice = createSlice({
    name: 'iq',
    initialState,
    reducers: {
        fetchStore: async (state, action) => {
            const response = await IqSpaceApi.Store(action.payload);

            console.log(response)
            state.store = response.data.data;

            console.log("SET STORE", response.data.data);
        }
    },
});

export const { fetchStore } = iqSlice.actions;

export default iqSlice;