import * as yup from "yup";
import type { Maybe, AnyObject, Flags } from 'yup';

declare module 'yup' {
  class StringSchema<TType extends Maybe<string> = string | undefined, TContext = AnyObject, TDefault = undefined, TFlags extends Flags = ''>
    extends Schema<TType, TContext, TDefault, TFlags> {
    phone(): yup.StringSchema<string | undefined, AnyObject, string | undefined>;
    url(): yup.StringSchema<string | undefined, AnyObject, string | undefined>;
  }
}

function urlValidation(this: yup.StringSchema, msg: string) {
  const regExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-zA-Z0-9.-_]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%&=_]+&?)?$/;

  const testUrl = (value: any) => {
    if (value && value.length > 0)
      return regExp.test(value);

    return true;
  }

  return this.test({
    name: 'testUrl',
    message: msg,
    test: testUrl,
  });
}

const yupAddUrlTest = () => {
  yup.addMethod(yup.string, 'url', urlValidation);
}

function phoneValidation(this: yup.StringSchema, msg: string) {
  const regExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const testPhone = (value: any) => {
    if (value) {
      let v = value.replace(/ /g, '');

      if (v.substr(0, 1) === "+")
        v = v.substr(1, v.length - 1);

      if (v.length > 0)
        return v.length > 6 && regExp.test(v);
    }

    return true;
  }

  return this.test({
    name: 'testPhone',
    message: msg,
    test: testPhone,
  });
}

const yupAddPhoneTest = () => {
  yup.addMethod(yup.string, 'phone', phoneValidation);
}

export { yupAddPhoneTest, yupAddUrlTest };