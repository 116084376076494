// Socials v2.1

import React from 'react';
import IconButton from '@mui/material/IconButton';

import AppContext from "app/AppContext";

export default class Socials extends React.Component {
    state = {
        socials: null
    };

    constructor(props) {
        super(props);

        this.state.socials = this.props.socials ? this.props.socials : [];

        if (!this.props.socials) {
            const { socials } = this.state;

            if (AppContext.s["facebook-page-url"])
                socials.push({
                    url: AppContext.s["facebook-page-url"],
                    icon: "fab fa-facebook"
                });

            if (AppContext.s["twitter-page-url"])
                socials.push({
                    url: AppContext.s["twitter-page-url"],
                    icon: "fab fa-twitter"
                });

            if (AppContext.s["instagram-page-url"])
                socials.push({
                    url: AppContext.s["instagram-page-url"],
                    icon: "fab fa-instagram"
                });

            if (AppContext.s["linkedin-page-url"])
                socials.push({
                    url: AppContext.s["linkedin-page-url"],
                    icon: "fab fa-linkedin"
                });

            if (AppContext.s["tiktok-page-url"])
                socials.push({
                    url: AppContext.s["tiktok-page-url"],
                    icon: "fab fa-tiktok"
                });
        } else {
            this.setState({
                socials: this.props.socials
            });
        }
    }

    render() {
        const { socials } = this.state;
        const { small, dark, className } = this.props;

        if (!socials)
            return "";

        let i = 0;

        return (
            <div className={"socials-list display-flex justify-content-center" +
                ((small ? " small" : "") + (dark ? " dark" : "") + (className ? (" " + className) : ""))} >
                {socials.map(s =>
                    <IconButton target="_blank" rel="noopener noreferrer" href={s.url.indexOf("http") !== 0 ? "https://" + s.url : s.url} key={"social-" + i++}>
                        {s.icon && <i className={s.icon} />}

                        {(!s.icon && s.type && s.type.name === "Website") && <i className="fas fa-globe" />}
                        {(!s.icon && s.type && s.type.name === "Facebook") && <i className="fab fa-facebook" />}
                        {(!s.icon && s.type && s.type.name === "Twitter") && <i className={"fab fa-twitter"} />}
                        {(!s.icon && s.type && s.type.name === "Instagram") && <i className={"fab fa-instagram"} />}
                        {(!s.icon && s.type && s.type.name === "Pinterest") && <i className={"fab fa-pinterest"} />}
                        {(!s.icon && s.type && s.type.name === "LinkedIn") && <i className={"fab fa-linkedin"} />}
                        {(!s.icon && s.type && s.type.name === "TikTok") && <i className={"fab fa-tiktok"} />}
                    </IconButton>)}
            </div>);
    }
}