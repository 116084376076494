import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '@mui/material/Button';

import AppContext from 'app/AppContext.js';
import { withModal } from 'framework/withModal';
import ApplicationForm from "../components/ApplicationForm";
import withUderlyStore from "../../zustand/withUderlyStore";

class Play extends React.Component {
    state = {
        user: this.props.zustand.user
    };

    componentDidMount() {
        if (this.props.updateLayout)
            this.props.updateLayout();
    }

    startApplication = () => {
        this.props.modal.setView(<><i className="fas fa-question"></i> {AppContext.r["become-quizmaster"]}</>,
            <ApplicationForm modal={this.props.modal} />, true, "wide");
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.zustand.user !== this.state.user) {
            this.setState({
                user: nextProps.zustand.user
            });
        }
    }

    render() {
        const { user } = this.state;

        return (
            <Container fluid className="page play">
                {(user && user.stores && user.stores.length > 0) &&
                    AppContext.r["quizmaster-intro"](user)}

                <p className='text-center'>{AppContext.r["fancy-some-fun"]}</p>

                <Row>
                    <Col md={6} className="role">
                        {AppContext.r["play-the-quizmaster"]}

                        <img src="/assets/images/play-with-friends-quizmaster.png" alt="IQ Space Quizmaster" />

                        {!user &&
                            <Button variant="outlined" className="signup call-to-action"
                                onClick={this.props.authActions.onRegister}>{AppContext.r["signup"]}</Button>}

                        {(user && (!user.stores || user.stores.length === 0)) &&
                            <div className="iq-button-link">
                                <Button variant="outlined" onClick={this.startApplication}>
                                    <img src={AppContext.s["mini-logo-dark"]} alt="Mini Logo" /> {AppContext.r["become-quizmaster"]}</Button>
                            </div>
                        }
                    </Col>

                    <Col md={6} className="role">
                        {AppContext.r["join-as-player"]}

                        <img src="/assets/images/play-with-friends.png" alt="IQ Space Player" />

                        {!user &&
                            <Button variant="outlined" className="signup call-to-action"
                                onClick={this.props.authActions.onRegister}>{AppContext.r["signup"]}</Button>}

                        {user && <p className="outlined">{AppContext.r["already-registered"]}</p>}
                    </Col>
                </Row>
            </Container>);
    }
}

export default withUderlyStore(withModal(Play));
