import React, { useContext } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { formatInTimeZone } from 'date-fns-tz';

import { LayoutContext, ILayoutContext, DialogModesEnum } from '@uderly/react-uderly-ui';
import AppContext from '../AppContext';
import JsonToTable from "../../framework/JsonToTable";
import { IqEvent } from "../../models/Event";
import { refundTicket } from "../../api/Stripe.ts";

export interface AttendancesProps {
  event: IqEvent;
}

const Attendances = ({ event }: AttendancesProps) => {
  const layoutContext = useContext(LayoutContext) as ILayoutContext;

  const confirmRefund = (attendance) => {
    const { name, gamename, email } = attendance.user;

    const text = AppContext.r["refund-confirm"].replace("{user}", name ? name : gamename ? gamename : email);

    layoutContext.dialog.show({
      mode: DialogModesEnum.confirmation,
      title: AppContext.r["refund-ticket"],
      content: text,
      onConfirm: async () => await refund(attendance)
    })
    // this.props.modal.confirm(AppContext.r["refund-ticket"], text,
    //     async () => await this.refund(attendance));
  }

  const refund = async (attendance) => {
    // this.props.modal.setBusy();
    layoutContext.dialog.showBackdrop();

    const response = await refundTicket(attendance);
    console.log(attendance, response);

    // // this.fetchItem(this.state.item.id);

    layoutContext.dialog.hide();
    // this.props.modal.hide();
  }

  const tableHead = [
    {
      Title: AppContext.r["ticket-id"],
      Field: "id"
    }, {
      Title: AppContext.r["user"],
      Adapter: (o) => {
        if(!o.user) return "";

        let toDisplay = o.user.email;

        const name = o.user.name ?? "";
        const surname = o.user.surname ?? "";

        if (o.user.name || o.user.surname)
          toDisplay += ` (${name} ${surname})`;

        return toDisplay;
      }
    },
    {
      Title: AppContext.r["on"],
      Adapter: (o) => formatInTimeZone(new Date(o.created_at), "Europe/London", "dd/MM/yyyy H:mm")
    }, {
      Title: AppContext.r["voucher"],
      Field: "voucher_code"
    }, {
      Title: AppContext.r["attendance_status"],
      Adapter: (o) => {
        const status = AppContext.r["attendance_statuses"][o.status_id];

        if (o.status_id === 2 // && o.payment_intent 
          && o.transfer_id && o.amount > 0)
          return <span>{status}<Button onClick={() => confirmRefund(o)}>{AppContext.r["refund"]}</Button></span>;

        return status;
      }
    }
  ];

  return <>
    <Grid item xs={12}>
      {/* <h3>{AppContext.r["attendances"]}</h3> */}
      {event.attendances && !event.is_free &&
        <p><b>{event.attendances.filter(x => (x.status_id === 2 && x.amount > 0)).length}</b> Sold,&nbsp;
          <b>{event.attendances.filter(x => (x.status_id === 2 && x.amount === 0)).length}</b> Obtained by voucher</p>
      }
    </Grid>

    <Grid item xs={12}>
      <JsonToTable head={tableHead} body={event.attendances.filter(ea => ea.status_id !== 5)}
        noItemsText={AppContext.r["no-attendances"]} />
    </Grid>
  </>;
}

export default Attendances;