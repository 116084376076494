import { Route } from 'react-router-dom';

import Settings from '../../app/settings';

const usePagesRouting = () => {
  const { siteMap } = Settings;

  return !siteMap ? null : <>
    {siteMap.map(item => <Route path={item.to} element={item.element} key={"routing" + item.to} />)}
  </>;
}

export default usePagesRouting;