import React from 'react';

import GameStatusEnum from './GameStatusEnum';

export default class SoundsManager extends React.Component {
    static _sounds = null;
    static get Sounds() {
        if (SoundsManager._sounds == null)
            SoundsManager._sounds = [];
        return SoundsManager._sounds;
    }

    static SoundsManager = null;

    constructor(props) {
        super(props);

        this.state = {
            sound: null,
            loop: false,
            enabled: false
        }
    }

    componentDidMount() {
        SoundsManager.Sounds[GameStatusEnum.Category] = undefined;//new Audio("/assets/iqspace/sounds/question_playing.mp3");
        SoundsManager.Sounds[GameStatusEnum.Image] = SoundsManager.Sounds[GameStatusEnum.Category];
        SoundsManager.Sounds[GameStatusEnum.Question] = SoundsManager.Sounds[GameStatusEnum.Category];
        //sounds[GameStatusEnum.Category].load();

        SoundsManager.Sounds[GameStatusEnum.AnswersTimerOn] = new Audio("/assets/iqspace/sounds/timer.mp3");
        SoundsManager.Sounds[GameStatusEnum.AnswersTimerOn].addEventListener('ended', this.onTimerAudioEnded);
        SoundsManager.Sounds[GameStatusEnum.AnswersTimerOn].load();

        SoundsManager.Sounds[GameStatusEnum.AnswersTimerOff] = new Audio("/assets/iqspace/sounds/timer_expired.mp3");
        SoundsManager.Sounds[GameStatusEnum.AnswersTimerOff].load();

        SoundsManager.Sounds[GameStatusEnum.CorrectAnswer] = new Audio("/assets/iqspace/sounds/correct_answer.mp3");
        SoundsManager.Sounds[GameStatusEnum.CorrectAnswer].load();

        SoundsManager.Sounds[GameStatusEnum.Teams] = new Audio("/assets/iqspace/sounds/ranking.mp3");
        SoundsManager.Sounds[GameStatusEnum.Ranking] = SoundsManager.Sounds[GameStatusEnum.Teams];
        SoundsManager.Sounds[GameStatusEnum.Teams].load();

        SoundsManager.Sounds[GameStatusEnum.FinalRanking] = new Audio("/assets/iqspace/sounds/final_ranking.mp3");
        SoundsManager.Sounds[GameStatusEnum.FinalRanking].load();

        // sounds[GameStatusEnum.Completed] = new Audio("/assets/iqspace/sounds/game_completed.mp3");
        // sounds[GameStatusEnum.Completed].load();

        SoundsManager.Sounds["breaking-time"] = new Audio("/assets/iqspace/sounds/breaking_time.mp3");
        SoundsManager.Sounds["breaking-time"].load();

        SoundsManager.Sounds["enable-sounds"] = new Audio("/assets/iqspace/sounds/show_answers.mp3");
        SoundsManager.Sounds["enable-sounds"].load();

        SoundsManager.Sounds["speech-follow-us"] = new Audio("/assets/iqspace/sounds/speech_follow_us.mp3");
        SoundsManager.Sounds["speech-follow-us"].load();
    }

    onTimerAudioEnded = () => {
        console.log("audio ended", this.state.loop);

        if (this.state.loop && SoundsManager.Sounds[this.state.sound]) {
            SoundsManager.Sounds[this.state.sound].currentTime = 0;
            SoundsManager.Sounds[this.state.sound].play();
        }
    }

    static getDerivedStateFromProps(props, state) {
        let s = null;

        // Stop any current sound is playing if audio has been muted
        if (!props.enabled && SoundsManager.currentSound) {
            SoundsManager.currentSound.pause();
            SoundsManager.currentSound.currentTime = 0;
        }

        if ((props.sound !== state.sound || (props.enabled && props.enabled !== state.enabled)) && SoundsManager.Sounds) {

            if (SoundsManager.Sounds[props.sound] && props.enabled) {
                if (SoundsManager.Sounds[state.sound]) {
                    const prevSound = (SoundsManager.Sounds[state.sound]);
                    const newSound = (SoundsManager.Sounds[props.sound]);

                    if (prevSound.src !== newSound.src) {
                        // Stop the previous sound
                        prevSound.pause();
                        prevSound.currentTime = 0;
                        // Start the new sound
                        // SoundsManager.currentSound = newSound;
                        newSound.play();
                    }
                } else {
                    if (props.sound)
                        SoundsManager.Sounds[props.sound].play(); // Play the next
                }

                // Keep track of the currently playing sound
                SoundsManager.currentSound = SoundsManager.Sounds[props.sound];
            }

            s = {
                sound: props.sound,
                loop: props.loop,
                // enabled: props.enabled
            };
        }

        if (props.enabled !== state.enabled) {
            if (!s) s = {};
            s["enabled"] = props.enabled
        }

        return s;
    }

    toggleEnabled = () => {
        if (!this.state.enabled) {
            SoundsManager.Sounds["enable-sounds"].play();
        } else {
            for (const sound of SoundsManager.Sounds)
                if (sound) {
                    sound.pause();
                    sound.currentTime = 0;
                }
        }

        this.setState({
            enabled: !this.state.enabled
        });
    }

    render() {
        return "";
    }
}