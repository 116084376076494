import React from 'react';
import { Container, Row } from 'react-bootstrap';

import AppContext from 'app/AppContext.js';

export default class PrivacyPolicy extends React.Component {
    componentDidMount() {
        if(this.props.updateLayout)
           this.props.updateLayout();
    }
    
    render() {
        return (
            <Container fluid className="page legal-container">
                <Row className="justify-content-md-center">
                    {AppContext.r["refunds-doc"]}
                </Row>
            </Container>);
    }
}