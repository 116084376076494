// RemoteSelect 1.2

import React, {Component} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default class RemoteSelect extends Component {
    state = {
        options: this.props.options,
        value: this.props.value
    };

    constructor(props) {
        super (props);

        this.fieldToMap = this.props.fieldToMap ? this.props.fieldToMap : "name";
    }
    
    static getDerivedStateFromProps(props, state) {
        if(props.options !== state.options || props.value !== state.value) {
            const s = {};

            if(props.options !== state.options)
                s.options = props.options;

            if(props.value !== state.value)
                s.value = props.value;

            return s;
        }

        return null;
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.options !== prevProps.options) {
    //         console.log(this.props.options);
                
    //         this.setState({
    //             options: this.props.options
    //         });
    //     }

    // //     if (this.props.value !== prevProps.value) {
    // //         console.log(this.props.value);
                
    // //         this.setState({
    // //             value: this.props.value
    // //         });
    // //     }
    //   }

    handleChange = (e) => {
        const {value} = e.target;

        this.setState({
            value: value
        });

        if(this.props.onChange !== undefined)
            this.props.onChange(e, value);

        if(this.props.onChange2 !== undefined)
            this.props.onChange2(e, value);
    }

    render() {
        const { value, options } = this.state;
        const { nullOption } = this.props;
        const label = this.props.label ? this.props.label : " ";

        const isDisabled = this.state.options === null || this.props.disabled;
        const className = (this.props.className) ? this.props.className : "";
        const menuItems = (options === null || options === undefined) ? [] 
            : options.map(o => <MenuItem value={o.id ? o.id : undefined} key={"item-"+o.id}>{o[this.fieldToMap]}</MenuItem>);

        return (
            <FormControl variant={this.props.variant} fullWidth className={className}>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label" id="demo-simple-select"
                    value={value} label={label} disabled={isDisabled}
                    onChange={this.handleChange}>
                        {nullOption && <MenuItem value={undefined}></MenuItem>}
                        {menuItems}
                        </Select>
            </FormControl>
        );
    }
}