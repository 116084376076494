import React from 'react';

import AppContext from '../../app/AppContext';
import Settings from '../../settingsOld';
import docsFramework from "./resources";

export default function loadClientResources (resources) {
    docsFramework(resources);

    // resources["privacy-policy-doc"] = (
    //     <div>
    //         <h1>Privacy Policy</h1>
    //     </div>);

    // resources["terms-doc"] = (
    //     <div>
    //         <h1>Terms & Conditions</h1>
    //     </div>
    // );

    resources["refunds-doc"] = (
        <>
            <h1>Rimborsi</h1>

            <p>Questo sito Web è un marketplace che consente ai propri utenti di acquistare biglietti o altri prodotti digitali, direttamente da {Settings["provider"].name} o dai loro partner, pertanto, da ora in avanti, <b>fornitori di servizi</b>.</p>

            <p>I fornitori di servizi sono responsabili dell'emissione dei rimborsi. Si prega di verificare con loro eventuali ulteriori condizioni di rimborso.</p>

            <p>La nostra policy dura 15 giorni. Se sono trascorsi 15 giorni dal tuo acquisto, purtroppo non possiamo offrirti un rimborso.</p>

            <p>I biglietti non sono normalmente rimborsabili per eventi già iniziati, in base alla data e all'ora programmate. Per eventuali condizioni eccezionali o reclami, si prega di verificare con il fornitore di servizi, responsabile dell'approvazione o del rifiuto dei rimborsi.</p>

            <p>I costi di prenotazione dell'evento non sono rimborsabili.</p>
                
            <p>I fornitori di servizi sono responsabili del pagamento delle commissioni sulle transazioni con carta ({AppContext.s["pricing"].perc}% + {AppContext.s["pricing"].fixed}c) e queste non sono rimborsabili.</p>

            <h4>Rimborsi (se applicabile)</h4>

            <p>Una volta che il tuo rimborso è stato approvato dal fornitore di servizi, riceverai un'e-mail per informarti dell'approvazione o del rifiuto del rimborso.
                <br />Se sei approvato, il rimborso verrà elaborato e un credito verrà automaticamente applicato alla tua carta di credito o al metodo di pagamento originale, entro un certo numero di giorni.</p>

            <h4>Rimborsi in ritardo o mancati (se applicabile)</h4>

            <p>Se non hai ancora ricevuto un rimborso, controlla di nuovo il tuo conto bancario.
                Quindi contatta la società che ha emesso la carta di credito/debito, potrebbe volerci del tempo prima che il rimborso venga pubblicato ufficialmente.
                Quindi contatta la tua banca. Spesso è necessario del tempo di elaborazione prima che venga pubblicato un rimborso.
                Se hai fatto tutto questo e non hai ancora ricevuto il rimborso, contatta il fornitore di servizi al suo indirizzo e-mail.</p>

            <p>Non è possibile effettuare scambi di biglietti.</p>                
        </>
    );

    resources["quiz-rules-doc"] = (
        <div>
            <h1>Regolamento Quiz {Settings["project-name"]}</h1>
        
            <hr className="small" />

            <p>{Settings["project-name"]} è un gioco a quiz "social" che coinvolge i giocatori tramite l’utilizzo del proprio smartphone, tablet o PC.</p> 
                
            <h2>Come Funziona</h2>
            <p>Il gioco è accessibile tramite un indirizzo web, potrebbe richiedere l'acquisto di un ticket di partecipazione, e richiede la registrazione all’evento in corso, con un numero massimo di giocatori partecipanti variabile da evento a evento. Durante l’avanzamento del gioco, il giocatore si troverà a rispondere a diverse domande di cultura generale, che possono presentare contenuti multimediali e che possono essere raggruppate in “manches”.</p>
            
            <h2>Punteggio</h2>
            <p>L’interazione del giocatore comporta l’assegnazione di un punteggio: positivo in caso di risposta corretta o negativo in caso di risposta scorretta. I punteggi, così, possono variare normalmente da -250 a +1000 a ogni risposta data. L'intrattenitore tuttavia può decidere di assegnare un punteggio prefissato a specifiche domande.</p>
                
            <h2>Tempistica</h2>
            <p>Il tempo di risposta influisce sull’assegnazione del punteggio, privilegiando chi risponde correttamente e velocemente. Le tempistiche di gioco possono variare di volta in volta, anche a seconda del contenuto. Una risposta fuori tempo comporterà l’assegnazione del punteggio zero. </p>
                
            <h2>Classifica</h2>
            <p>La classifica finale è data dalla sommatoria dei punteggi acquisiti da ogni giocatore durante ogni manches giocata oppure da un sistema ad “eliminazione” che seleziona dei giocatori scelti per la competizione finale. L’amministrazione si riserva a sua discrezione di scegliere l’algoritmo di calcolo dei punteggi finali di volta in volta.</p>
                
            <h2>Personalizzazione Quiz</h2>
            <p>Il social quiz di {Settings["project-name"]} si presta alla personalizzazione delle domande per accrescere la brand awareness dell’attività e l’engagement dei giocatori partecipanti che potranno essere premiati per aver risposto correttamente alle domande inerenti all’attività.</p>
                
            <h2>Proiezione Quiz</h2>
            <p>L’uso facoltativo di uno o più maxi schermi o proiettori offrirà una migliore e più immersiva esperienza di gioco.</p> 
            
            <h2>Chat In Real Time</h2>
            <p>I partecipanti al gioco possono chattare tra di loro tramite la chat di gruppo, costituendo così una <b>virtual room social</b>.</p>

            <hr />

            <p>In un mondo in cui le interazioni sociali sono l'unico vero valore aggiunto, noi di <a href="https://www.uderly.com" target="_blank" rel="noopener noreferrer">Uderly</a> abbiamo la tecnologia per avvicinare le persone e fornire un servizio in modo unico e innovativo.</p>
        </div>
    );
}