import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';

import { MustBeLogged } from '@uderly/react-uderly-ui';
import AppContext from 'app/AppContext.js';
import IqSpaceApi from 'api/IqSpace';
import Separator from 'app/components/Separator';
import withUderlyStore from "../../zustand/withUderlyStore";
import Settings from '../../app/settings';

class Following extends React.Component {
    state = {
        following: null,
        user: this.props.zustand.user
    };

    fetchFollowing = async () => {
        const response = await IqSpaceApi.Following();
        console.log(response);

        if (response && response.status === 200) {
            this.setState({
                following: response.data.data
            });
        }
    }

    async componentDidMount() {
        if (this.props.updateLayout)
            this.props.updateLayout();

        if (this.state.user)
            await this.fetchFollowing();
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.zustand.user !== this.state.user) {
            if (nextProps.zustand.user)
                await this.fetchFollowing();

            this.setState({
                user: nextProps.zustand.user
            });
        }
    }

    storeCard = (store) => {
        return (
            <Col xs={6} md={3} key={"card" + store.id}>
                <Link className="store-card" to={"/" + store.subdomain}>
                    <Avatar className="avatar"
                        src={Settings.apiUrl + "/stores/" + store.id + "/image/thumb"}>
                        {store.name.substr(0, 1)}</Avatar>
                    <h2>{store.name}</h2>
                </Link>
            </Col>);
    }

    render() {
        const { following } = this.state;
        const { user } = this.state;

        let view = "";

        if (!user) {
            view = <MustBeLogged {...this.props} />;
        } else {
            view = (
                <Row className="justify-content-md-center">
                    <h1>{AppContext.r["following"]}</h1>
                    <Separator />

                    {!following ? AppContext.r["preloader"]
                        : following.length === 0 ?
                            <p className="text-center">{AppContext.r["not-following-anyone"]}</p>
                            : following.map(store => this.storeCard(store))}
                </Row>
            );
        }

        return (
            <Container fluid className="page following">
                {view}
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    };
};

export default connect(mapStateToProps, null)(withUderlyStore(Following));
