import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import AddIcon from '@mui/icons-material/Add';
import Axios from 'axios';

import { errorLog, uploadFile } from '@uderly/react-uderly-ui/api';
import AppContext from 'app/AppContext';
import { withModal } from 'framework/withModal.js';
import Settings from '../app/settings';

class MediaStockImage extends React.Component {
    state = {
        status: 0,
        imageUrl: this.props.imageUrl,
        image: null,
        drag: "",
        item: this.props.item,
        media: (this.props.item && this.props.item.media
            && this.props.item.media.length > 0) ? this.props.item.media[0] : null
    };

    containerRef = React.createRef();
    fileInputRef = React.createRef();

    get apiUrl() {
        return Settings.apiUrl;
    }
  
    get apiPath() {
        return this.props.apiPath ? this.props.apiPath : '/mediastock';
    }

    static getDerivedStateFromProps(props, state) {
        if (props.item !== state.item) {
            return {
                item: props.item
            };
        }

        return null;
    }

    async componentDidMount() {
        const div = this.containerRef.current;

        div.addEventListener('dragenter', this.onDragEnter);
        div.addEventListener('dragleave', this.onDragLeave);
        div.addEventListener('dragover', this.onDragOver);
        div.addEventListener('drop', this.onDrop);

        if (this.state.image === null && this.state.imageUrl !== null) {
            await this.fetchImage();
        }
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.imageUrl !== prevProps.imageUrl) {
            await this.fetchImage();
        }
    }

    componentWillUnmount() {
        if(this.containerRef) {
            const div = this.containerRef.current;

            div.removeEventListener('dragenter', this.onDragEnter);
            div.removeEventListener('dragleave', this.onDragLeave);
            div.removeEventListener('dragover', this.onDragOver);
            div.removeEventListener('drop', this.onDrop);
        }
    }

    onDragEnter = (event) => {
        event.preventDefault();

        if(this.state.drag !== "enter")
            this.setState({
                drag: "enter"
            });
    }

    onDragOver = (event) => {
        event.preventDefault();

        const { drag, status } = this.state;

        if(status === 0 && drag !== "over")
            this.setState({
                drag: "over"
            });

        // console.log(event);

        // if (event.dataTransfer != null && event.dataTransfer.files != null)
        //     console.log(event.dataTransfer, event.dataTransfer.files);
    }

    onDragLeave = (event) => {
        event.preventDefault();

        const { drag, status } = this.state;
        console.log("onDragLeave", event)

        if(status === 0 && drag !== "leave")
            this.setState({
                drag: "leave"
            });
    }

    onDrop = async (event) => {
        event.preventDefault();

        const { drag, status } = this.state;
        console.log("onDrop", event);

        if(status === 0 && drag !== "drop")
            this.setState({
                drag: "drop"
            });

        let file = null;

        if (event.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (event.dataTransfer.items[i].kind === 'file') {
                    file = event.dataTransfer.items[i].getAsFile();
                    console.log('... file[' + i + '].name = ' + file.name);
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                console.log('... file[' + i + '].name = ' + event.dataTransfer.files[i].name);
            }
        }

        await this.onFileChanged([ file ])
    }

    onFileChanged = async (files) => {
        // TODO: Check if it's only ONE file

        if(files[0]) {
            this.setState({
                status: 1
            });

            var reader = new FileReader();

            reader.onload = (files) => {
                this.setState({
                    image: files.target.result
                });
            };

            reader.readAsDataURL(files[0]);

            const formData = new FormData();
            formData.append('files', files[0]);

            const url = this.apiUrl + this.apiPath + "/upload"; 
            const response = await uploadFile(url, formData);

            console.log(response);

            if(this.props.onImageFileChanged)
                this.props.onImageFileChanged(files[0]);
            
            this.setState({
                status: 0
            });
        }
    }

    openFileDialog = () => {
        if (this.state.status !== 0 || this.props.disabled || this.props.viewOnly) return;

        this.fileInputRef.current.click();
    }

    onDelete = async (event) => {
        event.stopPropagation();

        let r = null; // Delete Response

        console.log(this.state.item)

        if(this.state.item.id) {
            const url = this.apiUrl + this.apiPath + "/" + this.state.item.id + "/image";
            
            r = await Axios.delete(url)
                .catch(function (error) {
                    console.log('error ' + error);
                    errorLog(error);
    
                    return error.response;
                });
    
            console.log(r);
        }

        if(!this.state.item.id || (r && r.status === 200)) {
            this.fileInputRef.current.value = "";

            this.setState({
                image: null,
                imageUrl: null
            });
        }
    }

    onView = (event) => {
        event.stopPropagation();

        const { media } = this.state;

        const title = media ? media.name : "";

        // this.props.modal.setView(title, <div className="image" style={{ backgroundImage: `url(${this.state.image})` }}></div>, true);
        this.props.modal.setView(title, <img src={this.state.image} alt={title} />, true);
    }

    async fetchBase64Image(url) {
        const response = await Axios.get(url, { responseType: 'arraybuffer' })
            .catch((error) => {
                console.log('error ' + error);
                errorLog(error);
            });

        if(response && response.status === 200) {
            const image = btoa(
                new Uint8Array(response.data)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );

            if (image !== null && image.length > 0)
                return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
        }

        return null;
    }

    fetchImage = async () => {
        if (this.props.imageUrl !== "") {
            this.setState({
                status: 1,
                image: null
            });

            const imageBase64 = await this.fetchBase64Image(this.props.imageUrl);
            
            this.setState({
                imageUrl: this.props.imageUrl,
                image: imageBase64,
                status: 0
            });
        }
    }

    render() {
        const { variant, viewOnly } = this.props;
        const { drag, image, status } = this.state;

        const acceptFiles = "image/gif, image/jpeg, image/png";
        const className = (this.props.className ? this.props.className : "") + " media-image " 
            + (variant ? variant : "") + (drag ? (" drag-"+drag) : "") + ((status === 1) ? " loading" : "");

        // let ratio = 1;
        // if(variant && variant.substr(0, "ratio".length) === "ratio")
        //     ratio = variant.substr("ratio".length, "variant".length);

        const imageStyle = { backgroundImage: `url(${this.state.image})` };

        const options = (
            <div className="options">
                {!viewOnly &&
                    <IconButton aria-label="delete" onClick={this.onDelete}>
                        <DeleteIcon className="delete-button" />
                    </IconButton> }

                <IconButton aria-label="view" onClick={this.onView}>
                    <ImageIcon />
                </IconButton>
            </div>);

        return (
            <div ref={this.containerRef} className={className} onClick={this.openFileDialog}>
                {status === 0 && 
                    <div className="image" style={imageStyle}>
                        {!viewOnly && 
                            <div className="browse">
                                <AddIcon />
                            </div> }
                    </div> }

                {!image && 
                    <div className="overlay-texts">
                        {!viewOnly &&  AppContext.r["image-instructions"]}
                    </div> }

                {status !== 0 && AppContext.r["preloader"]}

                {(image && status === 0) && options}
                
                <input type="file" ref={this.fileInputRef} className="FileInput"
                    accept={acceptFiles}
                    onChange={(e) => { this.onFileChanged(e.target.files); }} />
            </div>);
    }
}

export default withModal(MediaStockImage);