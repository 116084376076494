import React, { useState } from "react";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';

import AppContext from '../AppContext';
import Attendances from "./Attendances";
import { Typography } from "@mui/material";
import { IqEvent } from "../../models/Event";
import Stats from "./EventStats";
import { Store } from "../../zustand/storesSlice";
import { useTranslation } from "react-i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export interface EventExtraTabsProps {
  event: IqEvent;
  store: Store;
}

const EventExtraTabs = ({ event, store }: EventExtraTabsProps) => {
  const { t } = useTranslation();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <Box className="event-extras-tabs">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTabIndex} onChange={handleChange} aria-label="Event extra tabs">
          <Tab label={AppContext.r["attendances"]} {...a11yProps(0)} />
          <Tab label={AppContext.r["played-questions"]} {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={selectedTabIndex} index={0}>
        <Attendances event={event} />
      </CustomTabPanel>

      <CustomTabPanel value={selectedTabIndex} index={1}>
        {store.licence.id > 1 ? <Stats event={event} /> :
          <>
            <p>{AppContext.r["pro-licence-required"]}</p>
            <Link to={"/" + store.subdomain + "/settings"}>{t('upgrade-licence', { store: store.name })}</Link>
          </>}
      </CustomTabPanel>
    </Box>
  );
}

export default EventExtraTabs;