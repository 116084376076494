import React from "react";
import { Button, Alert, AlertTitle, Tabs, Tab, Paper, LinearProgress } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import * as yup from 'yup';

import { MustBeLogged, QrLink } from '@uderly/react-uderly-ui';
import { updateItemRequest } from '@uderly/react-uderly-ui/api';
import AppContext from 'app/AppContext';
import IqSpaceApi from 'api/IqSpace';
import { onBoard, retrieveAccount, loginLink } from "api/Stripe.ts";
import ActionButton from 'framework/ActionButton';
import Licence from "../components/Store/Licence";
import LocalizationOptions from "../components/Store/LocalizationOptions";
import MediaStockImage from '../../framework/MediaStockImage';
import { fetchStore } from '../../redux/iqSlice';
import withUderlyStore from "../../zustand/withUderlyStore";
import Settings from '../../app/settings';

import 'react-quill/dist/quill.snow.css';

AppContext.yupAddPhoneTest(yup);
AppContext.yupAddUrlTest(yup);

class StoreSettings extends React.Component {
    state = {
        store: null,
        stripeAccount: undefined,
        enableEdit: true,
        tabPage: 0,
        user: this.props.zustand.user
    };

    apiPath = "/stores";

    async componentWillReceiveProps(nextProps) {
        if (nextProps.zustand.user !== this.state.user) {
            if (nextProps.zustand.user)
                await this.fetchFollowing();

            this.setState({
                user: nextProps.zustand.user
            });
        }
    }

    async componentDidMount() {
        const response = await IqSpaceApi.Store(this.props.entertainer);

        if (response && response.data.data) {
            const store = response.data.data;
            console.log(store)

            if (store && store.has_stripe_setup) {
                // Retrieve Stripe Connected Account
                const r = await retrieveAccount();
                console.log(r.data.data);

                this.setState({
                    store: store,
                    stripeAccount: (r && r.data && r.status === 200) ? r.data.data : null
                });
            } else {
                this.setState({
                    store: store,
                    stripeAccount: null
                });
            }
        } else {
            this.setState({
                store: -1
            })
        }
    }

    onUpdate = async (values) => {
        const response = await updateItemRequest(Settings.apiUrl + '/stores/' + this.state.store.id, values);

        if (response && response.status === 202) {
            const store = { ...this.state.store };

            store["name"] = values.name;

            this.setState({
                store: store
            });
        }
    }

    stripeOnboard = async () => {
        const response = await onBoard(this.state.store.id);
        console.log(response);

        if (response && response.status === 201) {
            window.location = response.data.data.url;
        }
    }

    stripeViewAccount = async () => {
        const r = await loginLink();
        console.log(r)

        if (r && r.status === 200 && r.data.data) {
            window.location = r.data.data.url;
        }
    }

    storePageUrl(includeHttp = false) {
        const { store } = this.state;

        return ((includeHttp) ? "https://" : "") + Settings.project.web.domain + "/" + store.subdomain;
    }

    get imageUrl() {
        return Settings.apiUrl + this.apiPath + "/" + this.state.store.id + "/image";
    }

    onImageFileChanged = async (imageFile) => {
        const { store } = this.state;

        const m = [
            {
                name: imageFile.name,
                size: imageFile.size,
                mime_type: imageFile.type
            }
        ];

        this.setState({
            media: m
        });

        if (store.id)
            await updateItemRequest(Settings.apiUrl + this.apiPath + "/" + store.id, { ...store, media: m });
    }

    render() {
        const { user, store, enableEdit, stripeAccount } = this.state;

        if (!user)
            return (<div className="your-store page">
                <MustBeLogged {...this.props} />
            </div>);

        if (!store || store === -1)
            return AppContext.r["preloader"];

        // Stripe OnBoard/OnBoarded View
        let stripeView = "";

        if (stripeAccount === undefined) {
            stripeView = AppContext.r["preloader"];
        } else {
            if (stripeAccount === null || !stripeAccount.chargesEnabled)
                stripeView =
                    <>
                        {enableEdit &&
                            <>
                                {AppContext.r["stripe-partners"]}

                                <ActionButton className="connect-with-stripe-button" icon="fab fa-stripe-s"
                                    action={this.stripeOnboard}>{AppContext.r["connect-stripe"]}</ActionButton>
                            </>}

                        <div className="stripe-error-alert-container">
                            <Alert className="stripe-error-alert" severity="error">
                                <AlertTitle>{(stripeAccount && !stripeAccount.chargesEnabled) && AppContext.r["stripe-charges-not-enabled"]}</AlertTitle>
                                {AppContext.r["stripe-required"]}
                            </Alert>
                        </div>
                    </>;
            else
                stripeView =
                    <>
                        <p>{AppContext.r["stripe-successfully-connected"]}</p>

                        {!stripeAccount.payoutsEnabled && stripeAccount.type !== "standard" &&
                            <div className="stripe-error-alert-container">
                                <Alert className="stripe-error-alert" severity="error">
                                    {AppContext.r["stripe-payouts-not-enabled"]}
                                </Alert>
                            </div>}

                        {store.stripe_account_type === "express" ?
                            <ActionButton className="connect-with-stripe-button" icon="fab fa-stripe-s"
                                action={this.stripeViewAccount}>{AppContext.r["view-stripe-account"]}</ActionButton> :
                            AppContext.r["view-stripe-dashboard"]}
                    </>;
        }

        return (
            <div className="your-store page">
                <div className="profile">
                    <p>{AppContext.r["your-store"]}</p>
                    <h1>{store.name}</h1>

                    <Row>
                        <Col>
                            <p>{AppContext.r["your-company-logo"]}</p>

                            <MediaStockImage sx={{ width: 56, height: 56 }} variant="rounded"
                                apiPath={this.apiPath}
                                imageUrl={(store.id && (store.media && store.media[0])) ? (this.imageUrl) : null}
                                item={store}
                                onImageFileChanged={this.onImageFileChanged} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p style={{ marginBottom: 5 }}>{AppContext.r["page-at"]}</p>
                            <QrLink value={this.storePageUrl(true)} logoImage={AppContext.s["mini-logo-qr"]} />
                            {/* <p>{AppContext.r["page-at"]} <a target="_blank" href={AppContext.s["project-url"]+"/"+store.subdomain}>{AppContext.s["project-url"]+"/"+store.subdomain}</a></p> */}
                            <p><small>{AppContext.r["wish-change-subdomain"]}</small></p>
                        </Col>
                    </Row>
                </div>

                {enableEdit &&
                    <Row>
                        <Col>
                            <Formik
                                initialValues={{ name: store.name, description: store.description }}
                                validationSchema={yup.object({
                                    name: yup.string().required().min(6),
                                    // phone: yup.string().phone().nullable(),
                                    // email: yup.string().email().nullable()
                                })}
                                onSubmit={(values) => this.onUpdate(values)}>

                                {({ isSubmitting }) => (
                                    <Form className="form">
                                        <Row className="justify-content-center">
                                            <Col xs={10} md={6}>
                                                <Field className="field" component={TextField} name="name" type="text"
                                                    label={AppContext.r["name"]} />
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center">
                                            <Col md={10}>
                                                <Field name="description">
                                                    {({ field }) => <ReactQuill value={field.value} onChange={field.onChange(field.name)} />}
                                                </Field>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center">
                                            <Button type="submit" variant="outlined" disabled={isSubmitting}>{AppContext.r["save"]}</Button>
                                        </Row>

                                        {isSubmitting && <><LinearProgress /></>}
                                    </Form>
                                )}
                            </Formik>

                            <Row>
                                <Col>
                                    <Licence store={store} />
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col className="tabs">
                                    <Paper className="paper" id="StoreSettings">
                                        <Tabs
                                            value={this.state.tabPage}
                                            onChange={(e, value) => this.setState({ tabPage: value })}
                                            indicatorColor="primary" textColor="primary" centered>
                                            <Tab label={AppContext.r["payments"]} />
                                            <Tab label={AppContext.r["localization-options"]} />
                                            {/* <Tab label={AppContext.r["socials"]} /> */}
                                            {/* <Tab label={AppContext.r["team-members"]} /> */}
                                        </Tabs>

                                        <Col xs={12} className="tab">
                                            {this.state.tabPage === 0 &&
                                                <div className="connect-with-stripe">
                                                    <br /><br />
                                                    {stripeView}
                                                </div>}

                                            {this.state.tabPage === 1 &&
                                                <LocalizationOptions store={store} onUpdated={(values) => {
                                                    const updatedStore = { ...store };

                                                    updatedStore.timezone = values.timezone;
                                                    updatedStore.country_id = values.country_id;
                                                    updatedStore.language_id = values.language_id;
                                                    updatedStore.locale_id = values.locale_id;

                                                    this.setState({
                                                        store: updatedStore
                                                    });
                                                }} />}
                                            {/*
                                            {this.state.tabPage === 2 &&
                                                <_Socials sociableType={"stores"} sociableId={store.id} className="store-socials" />}*/}
                                        </Col>
                                    </Paper>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = () => ({
    fetchStore
});

export default connect(null, mapDispatchToProps())(withUderlyStore(StoreSettings));