import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';

import AppContext from 'app/AppContext.js';
import { withModal } from 'framework/withModal';
import GameStatusEnum from 'quiz-components/GameStatusEnum';
import { BuyTicketButton } from '../components/BuyTicket';
import Separator from '../components/Separator';
import Settings from '../../app/settings';

class Events extends React.Component {
    state = {
        upcomingEvents: null, // With no live games
        upcomingEventsWithGames: null
    };

    componentDidMount() {
        const { upcomingEvents } = this.props;

        if (upcomingEvents && upcomingEvents.length > 0)
            this.setState({
                upcomingEvents: upcomingEvents.filter(e => e.games === null || e.games.length === 0 || !this.isLive(e.games[0])),
                upcomingEventsWithGames: upcomingEvents.filter(e => e.games != null && e.games.length > 0 && this.isLive(e.games[0]))
            });
    }

    isLive = (game) => {
        return game.game_status > GameStatusEnum.NotStarted
            && game.game_status < GameStatusEnum.Completed && !game.stop_timestamp
            && game.duration > ((Date.now() / 1000) - game.start_timestamp);
    }

    card(event, past = false) {
        const imageUrl = ((event.media[0] && event.media[0].disk === "net") ? Settings.apiUrl : AppContext.s["api-url"]) + "/events/" + event.id + "/image";
        const game = (event.games && event.games[0]) ? event.games[0] : null;

        return (
            <Card className="card" key={"event-" + event.id}>
                <Link to={"/event/" + event.id} onClick={() => window.restartHub = true}>
                    <CardActionArea>
                        <div className="image-box">
                            <CardMedia component="img" alt={event.title} height="140"
                                image={(event.image || event.media[0]) ? imageUrl : AppContext.s["placeholder"]}
                                title={event.title} />
                        </div>

                        <CardContent className="content">
                            <h3>{format(new Date(event.date_time), "E d MM yyyy H:mm")}</h3>

                            <Typography gutterBottom variant="h5" component="h2">
                                {event.title}
                            </Typography>

                            <Typography variant="body2" color="textSecondary" component="p">
                                {event.description && event.description.substr(0, 100) + "..."}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Link>

                {(game && this.isLive(game)) &&
                    <span className="live">LIVE</span>}

                <CardActions className="actions">
                    {(!past && !event.is_free && (!game || game.stop_timestamp)) &&
                        <BuyTicketButton event={event} game={game} store={this.props.store}
                            authActions={this.props.authActions} modal={this.props.modal} />
                    }
                </CardActions>
            </Card>
        );
    }

    render() {
        const { pastEvents } = this.props;
        const { upcomingEvents, upcomingEventsWithGames } = this.state;

        return (
            <div className="events-view">
                {upcomingEventsWithGames && upcomingEventsWithGames.length > 0 &&
                    <div className="events">
                        <h2>{AppContext.r["playing-now"]}</h2>
                        <Separator />

                        <div className="list">
                            {upcomingEventsWithGames.map(event => this.card(event))}
                        </div>
                    </div>}

                {upcomingEvents &&
                    <div className="events">
                        <h2>{AppContext.r["upcoming-events"]}</h2>
                        <Separator />

                        <div className="list">
                            {upcomingEvents.length === 0 && <p>{AppContext.r["no-upcoming-events"]}</p>}
                            {upcomingEvents.map(event => this.card(event))}
                        </div>
                    </div>}

                {pastEvents &&
                    <div className="events past">
                        <h2>{AppContext.r["past-events"]}</h2>
                        <Separator />

                        <div className="list">
                            {pastEvents.length === 0 && <p>{AppContext.r["no-past-events"]}</p>}
                            {pastEvents.map(event => this.card(event, true))}
                        </div>
                    </div>}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    layout: state.layout,
    auth: state.auth
});

export default connect(mapStateToProps, null)(withModal(Events));