import * as React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Settings from '../../app/settings';

import AppContext from 'app/AppContext';
import { useUderlyStore } from '@uderly/react-uderly-ui/zustand';
import { useIqSpaceStore } from '../../zustand';

function AccountMenu(props) {
    const { alignLeft } = props;

    const { user, signOut } = useUderlyStore((state) => ({
        user: state.user,
        signOut: state.signOut
    }));

    const { gameBookmarks } = useIqSpaceStore((state) => ({
        gameBookmarks: state.gameBookmarks
    }));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSignOut = () => {
        signOut();
    }

    let avatar = (
        <IconButton aria-label="access" onClick={props.authActions.onLogin}>
            <PersonIcon />
        </IconButton>);

    const userThumbPath = () => Settings.apiUrl + "/users/" + user.id + "/image/thumb/?t=" + Date.now();

    const name = !user ? "" : (user.gamename ? user.gamename.substring(0, 1) : user.name ? user.name.substring(0, 1) : "");

    if (user)
        avatar =
            <Tooltip title="Account settings">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}>
                    <Avatar
                        alt={user.name}
                        sx={user.color ? { bgcolor: user.color.hex } : {}}
                        src={user.media && user.media.length > 0 ? userThumbPath() : null}>
                        {name}</Avatar>
                </IconButton>
            </Tooltip>;

    return (
        <React.Fragment>
            <div className={"account-menu " + (alignLeft ? "align-left" : "")}>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    {avatar}
                </Box>

                {(user) &&
                    <Menu className="account-menu-menu"
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                pl: 2,
                                pr: 2,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -1.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: alignLeft ? "auto" : 14,
                                    left: alignLeft ? 14 : "auto",
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                        <MenuItem component={Link} to="/myaccount">
                            <SettingsIcon />{AppContext.r["my-account"]}
                        </MenuItem>

                        <MenuItem component={Link} to="/tickets">
                            <LocalActivityIcon />{AppContext.r["your-tickets"]}
                        </MenuItem>

                        <MenuItem component={Link} to="/following">
                            <FavoriteIcon />{AppContext.r["following"]}
                        </MenuItem>

                        {user.stores && user.stores.length > 0 && <p className="title">{AppContext.r["your-space"]}</p>}

                        {user.stores && user.stores.map(store => {
                            return (
                                <MenuItem component={Link} to={"/" + store.subdomain} key={"store-" + store.name}>
                                    <Avatar className="glow"
                                        src={Settings.apiUrl + "/stores/" + store.id + "/image/thumb?t=" + Date.now()}>
                                        {store.name && store.name.substr(0, 1)}</Avatar>
                                    {store.name}
                                </MenuItem>);
                        })}

                        {gameBookmarks && gameBookmarks.length > 0 && <p className="title">{AppContext.r["current-games"]}</p>}

                        {gameBookmarks && gameBookmarks.map(gameBookmark => {
                            return (
                                <MenuItem component={Link} to={"/event/" + gameBookmark.event_id} key={"gameBookmark-" + gameBookmark.event_id}>
                                    <Avatar className="glow" variant="square"
                                        src={Settings.apiUrl + "/events/" + gameBookmark.event_id + "/image/thumb?t=" + Date.now()}> </Avatar>
                                    {gameBookmark.title}
                                </MenuItem>);
                        })}

                        <Divider />

                        <MenuItem onClick={onSignOut}>
                            <ListItemIcon>
                                <LogoutIcon fontSize="small" />
                            </ListItemIcon>
                            {AppContext.r["logout"]}
                        </MenuItem>
                    </Menu>}
            </div>
        </React.Fragment>
    );
}

export default AccountMenu;