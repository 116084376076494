import React from 'react';
import ReactDOM from 'react-dom/client';
import store, { persistor } from "./redux/store";

import './i18n';
import App from './app/App';
import reportWebVitals from './reportWebVitals';

import "@fortawesome/fontawesome-free/css/all.min.css";
import './app/assets/bootstrap.css';
import './app/assets/scss/index.scss';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <App store={store} persistor={persistor} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
