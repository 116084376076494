import { Link, To } from "react-router-dom";

import Settings from "../../app/settings";

export interface LogoProps {
  to?: To;
  dark?: boolean;
  mini?: boolean;
  content?: React.ReactNode;
  className?: string;
  marginTop?: string;
  onClick?: () => void;
}

const Logo = ({ to, mini, dark, className, content, onClick, marginTop }: LogoProps) => {
  const { logo } = Settings.project;

  const img = dark ? <img src={mini ? logo?.miniDark : logo?.originalDark} alt="Logo" />
    : <img src={mini ? logo?.mini : logo?.original} alt="Logo" />;

  return !to ?
    <button className={"logo " + className} onClick={onClick} style={{ marginTop: marginTop ?? 0 }}>{img}</button> :
    <Link className={"logo " + className} to="/" onClick={onClick} style={{ marginTop: marginTop ?? 0 }}>
      {img}
      {content && content}
    </Link>;
}

export default Logo;