import React, { useEffect, useState } from 'react';

import IqSpaceApi from '../../api/IqSpace';
import Color from '../../models/Color';
import { DataLoader } from '@uderly/react-uderly-ui';
import { useTranslation } from "react-i18next";

export interface ColorsProps {
  colorId: number | null;
  onColorChanged?: (color: Color) => void;
}

const Colors = (props: ColorsProps) => {
  const { t } = useTranslation("translation");

  const [selectedColor, setSelectedColor] = useState<Color | undefined>();
  const [colors, setColors] = useState<Color[]>([]);

  useEffect(() => {
    const fetchColors = async () => {
      const response = await IqSpaceApi.Colors();

      if (response && response.status === 200) {
        setColors(response.data.data);

        const colorsMatch = response.data.data.filter(c => c.id === props.colorId);

        if (colorsMatch && colorsMatch.length > 0)
          setSelectedColor(colorsMatch[0]);
      }
    }

    fetchColors();
  }, [props.colorId]);

  const onSelectColor = (color: Color) => {
    setSelectedColor(color);

    if (props.onColorChanged)
      props.onColorChanged(color);
  }

  return !colors ? <DataLoader /> : (
    <div className="colors">
      <h2>{t('pick-color')}</h2>

      <div className="palette">
        {colors.map(color => <button onClick={(e) => {
          e.preventDefault();
          onSelectColor(color);
        }}
          key={"palette-color" + color.hex}
          className={selectedColor && color.id === selectedColor.id ? "selected color" : "color"}
          style={{ backgroundColor: color.hex }}></button>)}
      </div>
    </div>);
}

export default Colors;