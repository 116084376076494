import { createSlice } from '@reduxjs/toolkit'
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import Cookie from 'react-cookies';

// import AppContext from "../app/AppContext";
// // TODO: Switch to redux-persist
// const selectedLanguage = Cookie.load("language");
// const browserLanguage = navigator.language ? navigator.language : (navigator.userLanguage ? navigator.userLanguage : "");
// console.log("Selected language: " + selectedLanguage, "Browser language: " + browserLanguage)

const initialState = {
    alert: {
        open: false,
        autoHideDuration: 6000,
        message: "",
        severity: "success",
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        }
    },
    // language: selectedLanguage ? selectedLanguage : ((browserLanguage.substr(0, 2) === "it") ? "it" : "en"),
    footer: {
        visible: true
    }
};

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setAlert(state, action) {
            Object.assign(state.alert, action.payload);
        },
        hideAlert(state) {
            state.alert.open = false;
        },
        // setLanguage(state, action) {
        //     state.language = action.payload;

        //     console.log("SetLanguage:" + state.language);

        //     // TODO: Switch to redux-persist
        //     Cookie.save('language', action.payload, { path: '/' });
        // },
        hideFooter(state) {
            state.footer.visible = false;
        },
        showFooter(state) {
            state.footer.visible = true;
        }
    },
});

export const reducer = persistReducer(
    { storage, key: "layout", whitelist: ["language"] },
    layoutSlice.reducer);

export const { setAlert, hideAlert } = layoutSlice.actions;
export const { setLanguage } = layoutSlice.actions;
export const { hideFooter } = layoutSlice.actions;
export const { showFooter } = layoutSlice.actions;

export default layoutSlice;