import React, { useState, useEffect, useRef } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { ConfirmCardPaymentData } from "@stripe/stripe-js";
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";

import { DataLoader } from '@uderly/react-uderly-ui';

export interface CheckoutFormProps {
  paymentMethod: any;
  request: any;
  email: any;
  amount: string; // Just displayed
  onCreatePaymentIntent: (saveCard: boolean) => Promise<AxiosResponse<any, any>>;
  onStartedProcessing: () => void;
  onSucceeded: () => void;
}

function CheckoutForm(props: CheckoutFormProps) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<React.ReactNode>(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [saveCard, setSaveCard] = useState(true);
  const [agreed, setAgreed] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const processedRequest = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.paymentMethod)
      setDisabled(false);

    const init = async () => {
      // Create PaymentIntent as soon as the page loads
      if (!clientSecret && !processedRequest.current) {
        processedRequest.current = props.request;

        const response = await props.onCreatePaymentIntent(saveCard);

        if (response && response.status === 200) {
          setClientSecret(response.data.client_secret);
        }
      }
    }

    init();
  }, [clientSecret, processedRequest, props, props.paymentMethod, props.request, saveCard]);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async ev => {
    ev.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setProcessing(true);

    if (props.onStartedProcessing)
      props.onStartedProcessing();

    const data = {
      receipt_email: props.email,
      payment_method: {
        card: elements.getElement(CardElement)
      }
    } as ConfirmCardPaymentData;

    if (props.paymentMethod)
      data.payment_method = props.paymentMethod;

    const payload = await stripe.confirmCardPayment(clientSecret, data);

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);

      if (props.onSucceeded)
        props.onSucceeded();
    }
  };

  return (
    <form id="payment-form" className="payment-form" onSubmit={handleSubmit}>
      {props.paymentMethod === undefined &&
        <>
          <p>{t("pay-by-card")}</p>
          <CardElement id="card-element" options={cardStyle} onChange={handleChange} />

          <FormControlLabel label={t('save-card-desc')} className="agree-button"
            control={
              <Checkbox
                checked={saveCard}
                onChange={async (event) => {
                  setSaveCard(event.target.checked);
                  await props.onCreatePaymentIntent(event.target.checked);
                }}
                name="saveCard" color="primary" />
            } />
        </>}

      <div className="accept-conditions">
        <p>{t('i-accept')} <Link to="/terms" target="_blank">{t('terms-and-conditions')}</Link> {t('and')} <Link to="/refunds" target="_blank">{t('refunds-policy')}</Link></p>

        <FormControlLabel label="I agree" className="agree-checkbox"
          control={
            <Checkbox checked={agreed} name="agreed" color="primary"
              onChange={(event) => {
                setAgreed(event.target.checked);
              }} />
          } />
      </div>

      <Button type="submit" id="submit" className="checkout-button buyticket-button" color="primary" variant="contained"
        disabled={processing || disabled || succeeded || !stripe || !agreed}>
        {t('pay') + " " + props.amount}
      </Button>

      {processing &&
        <div className="overlay">
          <DataLoader />
        </div>}

      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>)}
    </form>
  );
}

export default CheckoutForm;