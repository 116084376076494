import React from "react";
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import { formatInTimeZone } from 'date-fns-tz';
import ReactQuill from 'react-quill';
import { t } from 'i18next';
import * as yup from 'yup';

import { updateItemRequest } from '@uderly/react-uderly-ui/api';
import withUderlyStore from "../../zustand/withUderlyStore";
import AppContext from 'app/AppContext';
import IqSpaceApi from 'api/IqSpace';
import Module from 'framework/Module';
import { withModal } from 'framework/withModal';
import { setAlert } from '../../redux/layoutSlice';
import MediaStockImage from '../../framework/MediaStockImage';
import PlaylistQuestions from '../components/PlaylistQuestions';
import Separator from "app/components/Separator";
import Settings from '../../app/settings';
import { hasPermission } from '../utils';
import getPlaylistColumns from "../components/getPlaylistColumns";

class Playlists extends React.Component {
    state = {
        store: this.props.zustand
    };

    apiPath = "/playlists";

    get initialValues() {
        return {
            name: ""
        };
    }

    get schema() {
        const v = {
            name: yup.string().required(AppContext.r["required-field"])
        };

        return yup.object(v);
    }

    async componentDidMount() {
        if (this.props.updateLayout)
            this.props.updateLayout();

        const response = await IqSpaceApi.Store(this.props.storeName);

        if (response && response.status === 200) {
            const store = response.data.data;

            this.setState({
                store: store
            });
        }
    }

    onFetchedItem = (item) => {
        this.setState({
            item: item
        });
    }

    onImageFileChanged = async (imageFile) => {
        const { item } = this.state;

        const m = [
            {
                name: imageFile.name,
                size: imageFile.size,
                mime_type: imageFile.type
            }
        ];

        this.setState({
            media: m
        });

        if (item.id)
            await updateItemRequest(Settings.apiUrl + this.apiPath + "/" + item.id, { ...item, media: m });
    }

    get imageUrl() {
        return Settings.apiUrl + this.apiPath + "/" + this.state.item.id + "/image";
    }

    updateDataAdapter = (values) => {
        values["media"] = null;
        return values;
    }

    view = ({ values, setFieldValue }) => {
        const { item } = this.state;
        const { user } = this.props.zustand;

        return (
            <>
                <Grid container>
                    <Grid container item spacing={1} sm md lg xl>
                        <Grid item xs={12} xl={12}>
                            <Field component={TextField} name="name" type="text"
                                label={AppContext.r["name"] + "*"} />
                        </Grid>

                        {hasPermission(user, "content_management") &&
                            <Grid item xs={12}>
                                <h3>{t('description')}</h3>
                                <ReactQuill value={values.description}
                                    modules={{
                                        clipboard: {
                                            matchVisual: false
                                        }
                                    }}
                                    onChange={(value) => setFieldValue('description', value)} />
                            </Grid>}

                        {values.created_at &&
                            <Grid item xs={12} sm={4} xl={4}>
                                <div className="outlined-with-label disabled">
                                    {formatInTimeZone(values.created_at, values.timezone, "dd/MM/yyyy H:mm:ss")}
                                    <label>{AppContext.r["created-at"]}</label>
                                </div>
                            </Grid>}
                    </Grid>

                    <Grid container spacing={1} className="image-col small">
                        <MediaStockImage sx={{ width: 56, height: 56 }}
                            apiPath={this.apiPath}
                            imageUrl={(values.id && (item.media && item.media[0])) ? this.imageUrl : null}
                            item={values}
                            onImageFileChanged={this.onImageFileChanged} />
                    </Grid>
                </Grid>

                <div className="questions-view">
                    {(values && values.questions) &&
                        <>
                            <h3 className="text-center">{values.questions_count} {t("questions")}</h3>
                            <Separator />

                            <PlaylistQuestions playlist={values}
                                onQuestionsChanged={p => setFieldValue("questions_count", p.length)}
                                onQuestionRemoved={(q) => setFieldValue("questions_count", values.questions_count - 1)}
                                onQuestionInserted={(q) => setFieldValue("questions_count", values.questions_count + 1)} />
                        </>}
                </div>
            </>);
    }

    onDeleteItem = (response) => {
        if (response && response.status === 406)
            this.props.setAlert({ open: true, message: AppContext.r["cant-delete-default-playlist"], severity: "error" });
    }

    render() {
        const { store } = this.state;

        const columns = getPlaylistColumns(this.apiPath);

        return (
            <div className="page module playlists">
                {store &&
                    <Module
                        paginated
                        modal={this.props.modal} setAlert={this.props.setAlert}
                        path={"/playlists"} apiPath={this.apiPath}
                        apiUrl={Settings.apiUrl}
                        updateDataAdapter={this.updateDataAdapter}
                        store={store.store} id={this.props.id} tableHead={columns}
                        view={this.view} viewClassName="playlist-mask mask"
                        onFetchedItem={this.onFetchedItem}
                        initialValues={this.initialValues}
                        authActions={this.props.authActions}
                        onDeleteItem={this.onDeleteItem} />}
            </div>
        );
    }
}

const mapDispatchToProps = () => ({
    setAlert
});

export default connect(null, mapDispatchToProps())(withModal(withUderlyStore(Playlists)));