import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { LinearProgress, MenuItem } from '@mui/material';
import { TextField } from 'formik-mui';
import { Button } from '@mui/material';

import { TimezoneField } from '@uderly/react-uderly-ui';
import { updateItemRequest, getCountries, getLanguages } from '@uderly/react-uderly-ui/api';
import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import { withModal } from 'framework/withModal';
import Settings from '../../../app/settings';

class _LocalizationOptions extends React.Component {
    state = {
        timezones: null,
        countries: null,
        locales: null,
        languages: null,
    }

    async componentDidMount() {
        // const response = await CoreApi.Localization();
        const rCountries = await getCountries();
        const rLocales = await CoreApi.Locales();
        const rLanguages = await getLanguages();

        if (rCountries && rLocales && rLanguages) {
            this.setState({
                store: this.props.store,
                countries: rCountries.data.data,
                locales: rLocales.data.data,
                languages: rLanguages.data.data
            });
        }
    }

    onUpdate = async (values) => {
        console.log(values);
        const response = await updateItemRequest(Settings.apiUrl + '/stores/' + this.state.store.id, values);

        if (response && response.status === 202)
            if (this.props.onUpdated)
                this.props.onUpdated(values);
    }

    render() {
        const { store, countries, locales, languages } = this.state;

        if (!store || !countries || !locales || !languages)
            return AppContext.r["preloader"];

        return (
            <Formik
                initialValues={{
                    "timezone": store.timezone,
                    "language_id": store.language_id,
                    "country_id": store.country_id,
                    "locale_id": store.locale_id
                }}
                onSubmit={(values) => this.onUpdate(values)}>

                {({ isSubmitting, values, setFieldValue }) => (
                    <Form className="form store-widget-localization">
                        <Row className="justify-content-center">
                            <Col md={3} sm={4} xs={6}>
                                <Field className="field country-field" component={TextField} select variant="outlined" margin="normal"
                                    type="text" name="country_id" label={AppContext.r["country"]} value={values.country_id}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}>
                                    {countries.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Col>

                            <Col md={3} sm={4} xs={6}>
                                <Field className="field game-language-field" component={TextField} select variant="outlined" margin="normal"
                                    type="text" name="language_id" label={AppContext.r["game-language"]} value={values.language_id}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}>
                                    {languages.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Col>

                            <Col md={2} sm={4} xs={6}>
                                <Field className="field locale-field" component={TextField} select variant="outlined" margin="normal"
                                    type="text" name="locale_id" label={AppContext.r["currency"]} value={values.locale_id}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}>
                                    {locales.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.currency_name} ({option.currency_code})
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Col>
                        </Row>
                        
                        <Row className="justify-content-center">
                            <Col md={8} sm={12} xs={12}>
                            <TimezoneField value={values.timezone} onChange={(e) => {
                                console.log(e);
                                setFieldValue("timezone", e.value)
                            }} />
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Button type="submit" variant="outlined" disabled={isSubmitting}>{AppContext.r["save"]}</Button>
                        </Row>

                        {isSubmitting && <LinearProgress />}
                    </Form>)}
            </Formik>);
    }
}

export default withModal(_LocalizationOptions);