import React from "react";

import { SocialLink } from "@uderly/react-uderly-ui/models";

import Settings from '../settings';

export enum BorderShapes {
	none,
	exagon
}

export interface SocialsProps {
	socials?: SocialLink[];
	borderShape?: BorderShapes;
	className?: string;
}

const Socials = (props: SocialsProps) => {
	const { socials } = props.socials ? props : Settings.provider;

	const socialLinkCard = (socialLink: SocialLink) =>
		<a className="navigation" href={socialLink.url} target="_blank"
			key={`social-${socialLink.url}`}>
			{(props.borderShape === undefined || props.borderShape === BorderShapes.exagon) &&
				<div className="hexagon-border-alt"></div>}

			{socialLink.icon}
		</a>;

	return <span className={`${!props.socials && 'uderly'} socials ${props.className ?? ''}`}>
		{socials?.map(social => socialLinkCard(social))}
	</span>;
}

export default Socials;