// withOverlay v1.1

import React from 'react';
import { Button } from 'react-bootstrap';

import AppContext from 'app/AppContext';

export default function withOverlay(OriginalComponent) {
    return class extends React.Component {
        state = {
            className: "",
            busy: false,
            show: false,
            view: "",
            showHideButton: false
        }

        setBusy = (busy = true) => {
            this.setState({
                busy: busy,
            });
        }
            
        overlay = () => {
            return (this.state.show || this.state.busy) ? (
                <div className="with-overlay">
                    {this.state.busy === true && AppContext.r['preloader']}

                    <div className="centered">
                        {this.state.view}

                        {this.state.showHideButton &&
                            <Button onClick={this.hide}>Okay</Button>}
                    </div>
                </div>) : "";
        }

        setView = (view, show = undefined, showHideButton = false, className = undefined) => {
            this.setState({
                view: view,
                show: show ? show : this.state.show,
                className: className ? className : this.state.className,
                showHideButton: showHideButton,
                busy: false
            })
        }

        show = (show = true) => {
            this.setState({
                show: show,
                busy: !show ? false : this.state.busy
            })
        }

        hide = () => {
            this.show(false);
        }

        showBusy = () => {
            this.setState({
                show: true,
                busy: true
            })
        }

        render() {
            const newProps = {
                overlay: {
                    setBusy: this.setBusy,
                    show: this.show,
                    hide: this.hide,
                    setView: this.setView,
                    showBusy: this.showBusy
                }
            }

            // Return original component with additional props
            return (
                <>
                    <OriginalComponent {...this.props} {...newProps} />

                    <div className={this.state.className}>
                        {this.overlay()}
                    </div>
                </>);
        }
    }
}