import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { Button, FormControlLabel, LinearProgress, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { TimezoneField } from '@uderly/react-uderly-ui';
import { getCountries, getLanguages } from '@uderly/react-uderly-ui/api';
import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import IqSpaceApi from 'api/IqSpace';
import withOverlay from 'framework/withOverlay';
import withUderlyStore from "../../zustand/withUderlyStore";
import { useUderlyStore } from "@uderly/react-uderly-ui/zustand";
import withRouter from './withRouter';

const yup = require('yup');

AppContext.yupAddPhoneTest(yup);

const theme = createTheme({
    palette: {
        type: "light"
    }
});

class ApplicationForm extends React.Component {
    initialValues = {
        store: {
            name: "",
            subdomain: "",
            country_id: this.props.zustand.language === "en" ? 228 : 102, // GB or IT
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            locale_id: this.props.zustand.language === "en" ? 2 : 1, // GBP or Eur
            language_id: this.props.zustand.language === "it" ? 2 : 1, // It or Eng
            allow_discoverable: true,
            promo_code: ""
        },
    };

    state = {
        countries: null,
        locales: null,
        languages: null,
        isSubmitting: false,
    };

    async componentDidMount() {
        const rCountries = await getCountries();
        const rLocales = await CoreApi.Locales();
        const rLanguages = await getLanguages();

        if (rCountries && rCountries.data && rLocales && rLocales.data) {
            this.setState({
                user: this.props.zustand.user,
                countries: rCountries.data.data,
                locales: rLocales.data.data,
                languages: rLanguages.data.data
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.zustand.user !== this.state.user) {
            this.setState({
                user: nextProps.zustand.user
            });
        }
    }

    hideOverlay = () => {
        this.props.overlay.hide();
    }

    closeModal = () => {
        this.props.overlay.hide();
        this.props.modal.hide();
    }

    sendRequest = async (values) => {
        console.log("sendRequest", values);

        const request = {
            store: values,
            promo_code: values.promo_code
        };

        console.log(request);

        this.setState({
            isSubmitting: true
        });

        const axiosResponse = await IqSpaceApi.Apply(request);
        const response = axiosResponse.response ? axiosResponse.response : axiosResponse;
        console.log(response);

        if (response && response.status) {
            if (response.status === 201) {
                const view = (
                    <>
                        <i className="far fa-thumbs-up icon"></i>
                        <h3>{AppContext.r["joined-congrats"]}</h3>
                        <p>{AppContext.r["joined-description"]}</p>

                        <Button onClick={() => {
                            this.closeModal();

                            console.log(("/" + values.subdomain))

                            window.location.href = "/" + values.subdomain;
                            // this.props.navigate({ to: ("/" + values.subdomain) })
                        }}>{AppContext.r["close"]}</Button>
                    </>
                );
                this.props.overlay.setView(view, true);

                useUderlyStore.getState().fetchUserInfo();

                this.setState({
                    isSubmitting: false
                });
            }

            if (response.status >= 400) {
                let message = <p>{AppContext.r["joined-failed-description"]}</p>;

                if (response.status === 403) message = AppContext.r["subdomain-taken"];
                if (response.status === 406) message = AppContext.r["invalid-promo-code"]; // HTTP_NOT_ACCEPTABLE

                const view = (
                    <>
                        <i className="far fa-frown icon"></i>
                        <h3>{AppContext.r["joined-failed"]}</h3>

                        {message}

                        <Button onClick={this.hideOverlay}>{AppContext.r["close"]}</Button>
                    </>
                );
                this.props.overlay.setView(view, true);
            }

            this.setState({
                isSubmitting: false
            });
        }
    }

    render() {
        const { user } = this.state;

        let view = "";

        if (!user || !this.state.countries || !this.state.locales || !this.state.languages)
            view = AppContext.r["preloader"];
        else {

            view =
                <div className="entertainer-application">
                    {(user.stores && user.stores[0]) ?
                        <>{AppContext.r["already-joined"]}</>
                        : <Formik
                            initialValues={this.initialValues.store}
                            validationSchema={yup.object({
                                name: yup.string()
                                    .required(AppContext.r["required-field"]),
                                subdomain: yup.string()
                                    .required(AppContext.r["required-field"])
                                    .matches(/^[a-z0-9-]+$/, AppContext.r["nickname-validation"])
                                    .min(5, AppContext.r["nickname-validation"])
                            })}
                            onSubmit={async (values) => {
                                await this.sendRequest(values);
                            }}>

                            {({ handleSubmit, handleReset, values, isValid, errors, setFieldValue }) => (
                                <Form className="form form-label-right" onSubmit={handleSubmit} onReset={handleReset}>
                                    <p className="text-center">{AppContext.r["fill-the-form-to-join"]}</p>

                                    <Row className="justify-content-center">
                                        <Col sm={10}>
                                            <Field className="field" component={TextField} name="name" type="text"
                                                value={values.name} label={AppContext.r["organization-name"]} />
                                            <p><small>{AppContext.r["can-be-creative"]}</small></p>
                                        </Col>

                                        <Col sm={10}>
                                            <Field className="field" component={TextField} name="subdomain" type="text"
                                                value={values.subdomain} label={AppContext.r["your-space"]} />
                                            <p>{AppContext.r["your-space-desc"]}</p>

                                            {values.subdomain && !errors["subdomain"] &&
                                                <p>{AppContext.r["your-events-will-be"]} <b>{AppContext.s["project-website"] + "/" + values.subdomain}</b>.</p>}
                                        </Col>

                                        <p className="glamour-info-box">{AppContext.r["use-promo-code"]}</p>

                                        <Col sm={7}>
                                            <Field className="field" component={TextField} name="promo_code" type="text"
                                                value={values.promo_code} label={AppContext.r["promo-code"]} />
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-center localization">
                                        <Col xs="12">
                                            <h2>{AppContext.r["localization-options"]}</h2>
                                        </Col>

                                        <Col sm={4} md={3} xs={12}>
                                            <Field className="field country-field" component={TextField} select variant="standard" margin="normal"
                                                type="text" name="country_id" label={AppContext.r["country"]} value={values.country_id}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}>
                                                {this.state.countries.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Col>

                                        <Col sm={4} md={3} xs={12}>
                                            <Field className="field game-language-field" component={TextField} select variant="standard" margin="normal"
                                                type="text" name="language_id" label={AppContext.r["game-language"]} value={values.language_id}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}>
                                                {this.state.languages.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Col>

                                        <Col sm={4} md={3} xs={12}>
                                            <Field className="field locale-field" component={TextField} select variant="standard" margin="normal"
                                                type="text" name="locale_id" label={AppContext.r["currency"]} value={values.locale_id}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}>
                                                {this.state.locales.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.currency_name} ({option.currency_code})
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Col>

                                        <Col md={8} sm={12} xs={12}>
                                            <TimezoneField className="timezone-field" value={values.timezone} onChange={(e) => {
                                                console.log(e);
                                                setFieldValue("timezone", e.value)
                                            }} />
                                        </Col>
                                    </Row>

                                    <br />

                                    <FormControlLabel className="allow-discoverable"
                                        control={<Field component={Switch} type="checkbox" name="allow_discoverable" />}
                                        label={AppContext.r['allow-discoverable']} />

                                    <Button type="submit" variant="contained" color="primary"
                                        disabled={this.state.isSubmitting || !isValid}>{AppContext.r["submit-request"]}</Button>
                                </Form>
                            )}
                        </Formik>
                    }

                    {this.state.isSubmitting && <LinearProgress className="progress-bar" />}
                </div>;
        }

        return (
            <ThemeProvider theme={theme}>
                <div className="work-with-us">
                    {view}
                </div>
            </ThemeProvider>
        );
    }
}


export default withOverlay(withUderlyStore(withRouter(ApplicationForm)));