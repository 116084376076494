import CheckIcon from '@mui/icons-material/Check';

import { useUderlyStore } from '@uderly/react-uderly-ui/zustand';
import AppContext from 'app/AppContext';

export default function _CheckList(props) {
    const { language } = useUderlyStore((state) => {
        return {
            language: state.language
        };
    });

    let l = (
        <>
            {props.business &&
                <div className="item">
                    <CheckIcon />
                    <p>Entirely <strong>customizable</strong> upon your request.
                        <br />Reflect your brand and impress with custom solutions.</p>
                </div>}

            <div className="item">
                <CheckIcon />
                <p>Create your <strong>space</strong> on IQ Space!
                    <br /><small>Display your events, team members or presenters, socials and more.</small></p>
            </div>

            {!props.business &&
                <div className="item">
                    <CheckIcon />
                    <p>Seek new work <b>opportunities</b>.</p>
                </div>}

            {props.business &&
                <>
                    <div className="item">
                        <CheckIcon />
                        <p>Your <strong>logo</strong> always on!</p>
                    </div>

                    <div className="item">
                        <CheckIcon />
                        <p>Max <strong>90 players</strong>.<br /><small>Do you need more? <a href="#contactUs">Let's have a chat</a>!</small></p>
                    </div>

                    <div className="item">
                        <CheckIcon />
                        <p>Full followers <strong>report</strong>.</p>
                    </div>
                </>}

            <div className="item">
                <CheckIcon />
                <p>Chat, chat, <strong>chat</strong>! Bring to life the virtual room.</p>
            </div>

            {/* <div className="item">
                <CheckIcon />
                <p><strong>Zoom</strong> embedded in-app.</p>
            </div> */}

            <div className="item">
                <CheckIcon />
                <p>All you can eat questions.
                    <br /><small>Create, manage and display interactive <b>promotional content</b>!</small></p>
            </div>

            <div className="item">
                <CheckIcon />
                <p>Enrich your quizzes with <b>images</b> and <b>videos</b>.</p>
            </div>

            <div className="item">
                <CheckIcon />
                <p><b>Categorized</b> questions.</p>
            </div>

            <div className="item">
                <CheckIcon />
                <p>True/False, fixed scores, adjustable timer.</p>
            </div>

            <div className="item">
                <CheckIcon />
                <p><strong>Cloud Hosting</strong> on our secured servers.</p>
            </div>

            {props.business &&
                <div className="item">
                    <CheckIcon />
                    <p><strong>Assistance 24/7 & International support</strong>.
                        <br /><small>We speak your language and we can help you with international clients.</small></p>
                </div>}
        </>);

    if (language === "it")
        l = (
            <>
                {props.business &&
                    <div className="item">
                        <CheckIcon />
                        <p>Interamente <strong>personalizzabile</strong> a tua richiesta.
                            <br /><small>Rifletti il Tuo brand ed impressiona con soluzioni custom.</small></p>
                    </div>}

                <div className="item">
                    <CheckIcon />
                    <p>Crea il tuo <strong>space</strong> su IQ Space!
                        <br /><small>Mostra i tuoi eventi, il tuo team, i tuoi social ed altre informazioni sul tuo business.</small></p>
                </div>

                {!props.business &&
                    <div className="item">
                        <CheckIcon />
                        <p>Trova nuove <b>opportunità</b> di lavoro.</p>
                    </div>}

                {props.business &&
                    <div className="item">
                        <CheckIcon />
                        <p>Il tuo <strong>logo</strong> sempre in mostra!</p>
                    </div>}

                {props.business &&
                    <div className="item">
                        <CheckIcon />
                        <p>Max <strong>90 giocatori</strong>.<br />
                            <small>Te ne servono di più? <a href="#contactUs">{AppContext.r["contact-us"]}</a>!</small></p>
                    </div>}

                {props.business &&
                    <div className="item">
                        <CheckIcon />
                        <p><strong>Report</strong> completo sui tuoi follower.</p>
                    </div>}

                <div className="item">
                    <CheckIcon />
                    <p>Chat, chat, <strong>chat</strong>! Rendi reale la tua virtual room.</p>
                </div>

                {/* <div className="item">
                    <CheckIcon />
                    <p><strong>Zoom</strong> integrato in-app.</p>
                </div> */}

                <div className="item">
                    <CheckIcon />
                    <p>Contenuti illimitati
                        <br /><small>Crea, gestisci e gioca contenuti interattivi e <b>promozionali</b>!</small></p>
                </div>

                <div className="item">
                    <CheckIcon />
                    <p>Arricchisci i tuoi quiz con <b>immagini</b> e <b>video</b>.</p>
                </div>

                <div className="item">
                    <CheckIcon />
                    <p>Domande <b>categorizzate</b>.</p>
                </div>

                <div className="item">
                    <CheckIcon />
                    <p>Vero/Falso, punteggi fissi, timer configurabile.</p>
                </div>

                <div className="item">
                    <CheckIcon />
                    <p><strong>Cloud Hosting</strong> sicuro sui nostri server.</p>
                </div>

                {props.business &&
                    <div className="item">
                        <CheckIcon />
                        <p><strong>Assistenza 24/7 & Supporto Internazionale</strong>.
                            <br /><small>Parliamo la tua lingua e possiamo aiutarti con i tuoi clienti internazionali.</small></p>
                    </div>}
            </>
        );

    return (
        <div className="check-list">
            {l}
        </div>);
}