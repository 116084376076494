import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField } from 'formik-mui';
import { Field, Form, FormikProps } from 'formik';
import { Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { DataLoader, QuickModule } from "@uderly/react-uderly-ui";
import { IndexedRow } from "@uderly/react-uderly-ui/models";
import { createItemRequest, getRecordsRequest } from '@uderly/react-uderly-ui/api';
import RemoteSelect from '../../framework/RemoteSelect';
import Settings from '../settings';
import { GridColDef } from "@mui/x-data-grid";
import AppContext from '../AppContext';

export interface PlaylistsSelectProps {
  exclude?: IndexedRow;
  compactMode?: boolean; // Display it as a dropdown
  playlistId?: number;
  onSelect?: (item: any) => void;
  quickCreatePlaylist?: () => void; // TODO: Move in this file
  onPlaylistChanged?: (e: any, playlistId: number) => void; // TODO: Move in this file
}

const PlaylistsSelect = (props: PlaylistsSelectProps) => {
  const { exclude, compactMode, onSelect, quickCreatePlaylist, onPlaylistChanged } = props;
  const { t } = useTranslation("translation");

  const [playlists, setPlaylists] = useState<[] | null>(null);
  const [playlistId, setPlaylistId] = useState<number | undefined>(props.playlistId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showInsertPlaylist, setShowInsertPlaylist] = useState<boolean>(false);
  const [newPlaylistName, setNewPlaylistName] = useState<string>("");

  const apiPath = "/playlists";

  const columns: GridColDef[] = [{
    field: 'name',
    headerName: t('name') as string,
    minWidth: 100,
    flex: 1,
    renderCell: params => {
      const { row } = params;

      return (
        <div className="td-v-center">
          <div className="row-thumb">
            {(row.media && row.media[0]) && <>
              <img src={((row.media[0].disk === "net") ? Settings.apiUrl : AppContext.s["api-url"]) + apiPath + "/" + row.id + "/image/thumb"} alt="Thumb" />
            </>}
            {(!row.media || !row.media[0]) && <img src={AppContext.s["placeholder"]} className="placeholder-thumb" alt="Thumb" />}
          </div>

          {row.name}
        </div>
      );
    }
  },
  {
    field: 'questions-count',
    headerName: "",
    minWidth: 40,
    renderCell: params => {
      const { row } = params;

      return <div className="td-v-center">{`${row.questions_count} ${t('questions')}`}</div>;
    }
  }];

  const fetchRecords = useCallback(async (newFilters: any = {}) => {
    const _defaultFilters = {
      page: 1,
      page_size: 25,
    };

    const updatedFilters = Object.assign(
      { resolve_questions: true },
      _defaultFilters,
      newFilters
    );

    const url = Settings.apiUrl + "/playlists";
    const response = await getRecordsRequest(
      url,
      updatedFilters,
      // fetchRecordsAbortController.current
    );

    console.log(response)
    if (response && response.status === 200) {
      const { data } = response.data;

      setPlaylists(exclude ? data.filter(x => x.id !== exclude.id) : data);
    }
  }, []);

  useEffect(() => {
    fetchRecords();
  }, [exclude]);

  const onInsertPlaylist = () => {
    setShowInsertPlaylist(true);
  }

  const onInsertToNewPlaylist = async () => {
    const response = await createItemRequest(Settings.apiUrl + "/playlists", {
      name: newPlaylistName
    });

    if (response && response.status > 200 && onSelect)
      onSelect(response.data.data);
  }

  const onSelected = (o) => {
    setIsLoading(true);

    if (onSelect)
      onSelect(o);

    setIsLoading(false);
  }

  const filtersForm = (_props: FormikProps<any>) => (
    <Form onChange={_props.handleChange}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Field
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            component={TextField}
            label={t('search')}
            size="small"
            name="search"
            type="text" />
        </Grid>
      </Grid>
    </Form>
  );

  const onFiltersChanged = async (values: any) => {
    console.log(values);
    fetchRecords(values);
  };

  if (!playlists || isLoading)
    return <DataLoader />;

  let view: ReactNode | null = null;

  if (compactMode) {
    view = (playlists && playlists.length > 0) ?
      <>
        <RemoteSelect
          name="playlist_id"
          className="compact-playlists-select"
          label={t("select-playlist")}
          nullOption={false}
          options={playlists}
          value={playlistId}
          onChange={(e, playlistId) => {
            setPlaylistId(playlistId);

            if (onPlaylistChanged)
              onPlaylistChanged(e, playlistId);
          }}
          variant="standard" />
      </>
      : <div className="no-playlists">
        <p>{t("no-playlists")}</p>
        <Button className="link-button" onClick={quickCreatePlaylist}>{t("create-playlist")}</Button>
      </div>;
  } else {
    view = <>
      <QuickModule
        columns={columns}
        records={playlists}
        onSelected={onSelected}
        initialFilters={{ search: '' }}
        filtersForm={filtersForm}
        onFiltersChanged={onFiltersChanged}
        disableView />

      {!showInsertPlaylist ?
        <Button onClick={onInsertPlaylist}>{AppContext.r["new-playlist"]}</Button>
        : <div className="quick-new-playlist">
          <MuiTextField variant="outlined" label={t("name")}
            value={newPlaylistName} onChange={(event) => setNewPlaylistName(event.target.value)} />

          <Box marginLeft={1}>
            <Button onClick={onInsertToNewPlaylist}>{t("insert")}</Button>
          </Box>
        </div>}
    </>
  }

  return <div className="playlists-select">
    {view}
  </div>;
}

export default PlaylistsSelect;