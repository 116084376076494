import { useTranslation } from "react-i18next";
import { intervalToDuration } from 'date-fns';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { IHasNotifications } from "../models/Notifications";

export interface EntityNotificationsProps {
  values: IHasNotifications;
}

const EntityNotifications = ({ values }: EntityNotificationsProps) => {
  const { t } = useTranslation();

  const { entityNotifications } = values;

  const timeDiffSecsToReadableFormat = (timeDiffSecs: number, triggerMotiveId: number) => {
    const duration = intervalToDuration({ start: 0, end: timeDiffSecs * 1000 });
    let postfix = "";

    if (triggerMotiveId === 1)
      postfix = timeDiffSecs < 0 ? t('prior-start') : t('after-end');

    if (triggerMotiveId === 2 && timeDiffSecs > 0)
      postfix = t('after-creation');

    if (triggerMotiveId === 3 && timeDiffSecs === 0)
      postfix = t('as-soon-as-possible');

    let readable = (Math.abs(timeDiffSecs) > (24 * 60 * 60)) ? `${duration.days} d ` : '';

    if ((duration.hours && duration.hours > 0) || (duration.minutes && duration.minutes > 0))
      readable += `${duration.hours} h `;

    if (duration.minutes && duration.minutes > 0)
      readable += `${duration.minutes} m `;

    return (readable + `${postfix}`);
  }

  const displayId = false;

  return !entityNotifications ? null : <>
    <h3>{t('notifications')}</h3>

    {entityNotifications.length === 0 &&
      <p>{t('no-notifications')}</p>}

    {entityNotifications.length > 0 &&
      <table className="notifications">
        <thead>
          <tr>
            {displayId && <th>{t('id')}</th>}
            <th>{t('title')}</th>
            <th>{t('timing')}</th>
            <th>{t('status')}</th>
          </tr>
        </thead>

        {entityNotifications.map(en => <tr key={`notification-${en.id}`}>
          {displayId && <td>{en.id}</td>}
          <td><strong>{t(en.template.title)}</strong></td>
          <td>{timeDiffSecsToReadableFormat(en.template.time_diff_secs, en.template.trigger_motive_id)}</td>
          <td>{en.triggered ? <><ForwardToInboxIcon /> {t('sent')}</> : <><AccessTimeIcon /> {t('scheduled')}</>}</td>
        </tr>)}
      </table>}

    <br />
  </>;
}

export default EntityNotifications;