import { createContext } from "react";
import Cookie from 'react-cookies';

export const themes = {
    dark: "dark-theme",
    light: "light-theme"
};

export const ThemeContext = createContext({
    theme: Cookie.load("dark-theme") === "false" ? themes.dark : themes.light,
    changeTheme: () => {},
});