import React, { Component } from 'react';

export class RankingStatistic extends Component {
    render() {
        let icon = "";

        if (this.props.className === "exact") icon = <i className="fas fa-check"></i>;
        if (this.props.className === "wrong") icon = <i className="fas fa-times"></i>;
        if (this.props.className === "absteined") icon = <i className="far fa-thumbs-down"></i>;

        return (
            <div className={"ranking-statistic " + this.props.className}>
                {icon}
                <span className="label">{this.props.label}</span>
                <span className="value">{this.props.value}</span>
            </div>
        );
    }
}