import { StateCreator } from 'zustand';

import { CombinedSlices } from '.';

export interface GameBookmark {
  title: string;
  event_id: number;
  accessed_at: Date;
}

export interface GameSlice {
  gameBookmarks: GameBookmark[];
  addGameBookmark: (s: GameBookmark) => void;
  setGameBookmarks: (s: GameBookmark []) => void;
}

export const createGameSlice: StateCreator<
  CombinedSlices,
  [],
  [],
  GameSlice
> = (set) => ({
  gameBookmarks: [],
  addGameBookmark: (gameBookmark: GameBookmark) => set((state) => {
    const exists = state.gameBookmarks.filter(g => g.event_id === gameBookmark.event_id).length > 0;
    return exists ? {} : { gameBookmarks: [...state.gameBookmarks, gameBookmark] };
  }),
  setGameBookmarks: (gameBookmarks: GameBookmark[]) => set((state) => ({ gameBookmarks: gameBookmarks }))
});
