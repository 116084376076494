import React, { useEffect, useRef } from 'react';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ThemeContextWrapper from '../framework/ThemeContextWrapper';
import { positions, transitions, Provider as AlertProvider } from 'react-alert';
import Axios from 'axios';
import { differenceInMinutes } from "date-fns";

import { uderlyInitConfig } from "@uderly/react-uderly-ui";

import BasePage from './BasePage';
import Settings from './settings';
import SplashScreen from '../uderly/components/SplashScreen';
import AlertTemplate from '../react-alert-template';
import { useIqSpaceStore } from '../zustand';

export default function App({ store, persistor, basename }) {
  const mergedSettings = uderlyInitConfig({
    externalSettings: Settings,
    disableSyncSettings: false,
    Axios: Axios // TODEL (From API lib, too, as a peer dep)
  });

  Object.assign(Settings, mergedSettings);

  const initialized = useRef(false);

  // Alert cofiguration
  const options = {
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: '10px',
    transition: transitions.SCALE
  };

  const { gameBookmarks, setGameBookmarks } = useIqSpaceStore((state) => ({
    gameBookmarks: state.gameBookmarks,
    setGameBookmarks: state.setGameBookmarks
  }));

  useEffect(() => {
    const checkBookmarks = async () => {
      // Remove old game bookmarks
      const espiration = 18 * 60; // minutes
      const bookmarks = [...gameBookmarks];

      for (let i = 0; i < gameBookmarks.length; i++) {
        const gameBookmark = gameBookmarks[i];

        const minutesElapsed = differenceInMinutes(new Date(gameBookmark.accessed_at), new Date());

        if (minutesElapsed <= -espiration) {
          bookmarks.splice(i, 1);
          setGameBookmarks(bookmarks);
        }
      }
    }

    if (!initialized.current) {
      checkBookmarks();

      initialized.current = true;
    }
  }, [gameBookmarks, initialized, setGameBookmarks]);

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<SplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<SplashScreen />}>
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          {/* Provide `react-intl` context synchronized with Redux state.  */}
          {/* Render routes with provided `Layout`. */}
          <ThemeContextWrapper>
            <AlertProvider template={AlertTemplate} {...options}>
              <BrowserRouter>
                <BasePage />
              </BrowserRouter>
            </AlertProvider>
          </ThemeContextWrapper>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
