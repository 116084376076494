import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { Field, FormikProps, FormikProvider } from 'formik';
import { TextField } from 'formik-mui';
import { Container } from '@mui/material';

import { Account } from "@uderly/react-uderly-ui";
import { User } from '@uderly/react-uderly-ui/models';
import { useUderlyStore } from '@uderly/react-uderly-ui/zustand';
import Separator from '../components/Separator';
import AppContext from '../AppContext';
import Colors from '../components/Colors';

export interface GameNameFormProps {
  formikProps: FormikProps<any>;
  fullWidth?: boolean;
  hideColorsPalette?: boolean;
}

export const GameNameForm = (props: GameNameFormProps) => {
  const { t } = useTranslation();

  const { formikProps, fullWidth, hideColorsPalette } = props;

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid item xs={12}>
        <h2>{t('game-name')}</h2>
      </Grid>

      {(formikProps && formikProps.values) &&
        <>
          <Grid item xs={12} md={fullWidth ? 12 : 5}>
            <Field component={TextField} value={formikProps.values.gamename} name='gamename' type='text'
              label={AppContext.r['game-name']} />
            <p className="text-center"><small>{AppContext.r["game-name-desc"]}</small></p>
          </Grid>

          {!hideColorsPalette &&
            <Colors colorId={formikProps.values.color_id}
              onColorChanged={(c) => formikProps.setFieldValue("color_id", c.id)} />}
        </>}
    </Grid>
  )
};

export const FormikProvidedGameNameForm = (props: GameNameFormProps) => {
  const { formikProps } = props;

  return (
    <FormikProvider value={formikProps}>
      <GameNameForm {...props} />
    </FormikProvider>
  )
};

const AccountPage = () => {
  const { t } = useTranslation();

  const { setLanguage } = useUderlyStore((state) => {
    return { setLanguage: state.setLanguage };
  });

  const onUpdated = (user: User, hasChangedLanguage: boolean) => {
    if (hasChangedLanguage) {
      const languageCode = user.language_id === 2 ? "it" : "en";

      setLanguage(languageCode);
      window.location.reload();
    }
  };

  return (
    <Container className="page">
      <h1 className='text-center'>{t("your-account")}</h1>
      <Separator />

      <Account PreFormView={FormikProvidedGameNameForm} onUpdated={onUpdated} />
    </Container>);
};

export default AccountPage;
