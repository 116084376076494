import React from 'react';
import { Link } from "react-router-dom";
import { Formik, Form } from 'formik';
import Avatar from '@mui/material/Avatar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SaveIcon from '@mui/icons-material/Save';
import queryString from 'query-string';

import { MustBeLogged, QuickModule } from '@uderly/react-uderly-ui';
import { createItemRequest, updateItemRequest, deleteItemRequest } from '@uderly/react-uderly-ui/api';
import { useUderlyStore } from '@uderly/react-uderly-ui/zustand';
import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import ActionButton from 'framework/ActionButton';
import withRouter from '../app/components/withRouter';
import Settings from '../app/settings';

export function StoreBreadcrumbs(props) {
    const { store, item, path, onClick, friendlyPath } = props;

    return <div className="breadcrumbs">
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
            <Link to={"/" + store.subdomain}>
                <Avatar className="avatar"
                    src={Settings.apiUrl + "/stores/" + store.id + "/image/thumb?t=" + Date.now()}>{store.name && store.name.substr(0, 1)}</Avatar>
                {store.subdomain}
            </Link>

            <Link to={"/" + store.subdomain + path} onClick={onClick}>
                {friendlyPath ? friendlyPath : path.replace("/", "")}
            </Link>

            {item && item.id && <span>{item.id}</span>}
        </Breadcrumbs>
    </div>;
}

const _defaultSettings = {
    pageSize: 15,
    pageSizeOptions: [15, 25, 50, 100],
};

class Module extends React.Component {
    state = {
        records: null,
        item: null,
        store: this.props.store,
        lastRecordsRefresh: Date.now(),
        pageSize: _defaultSettings.pageSize,
        page: 1,
        rowCount: 0,
        filters: this.props.initialFilters ? this.props.initialFilters : {}
    };

    get path() {
        return "/" + this.state.store.subdomain + this.props.apiPath;
    }

    apiUrl = this.props.apiUrl ? this.props.apiUrl : AppContext.s["api-url"];
    accessApiUrl = this.props.accessApiUrl ? this.props.accessApiUrl : this.apiUrl;
    createApiUrl = this.props.createApiUrl ? this.props.createApiUrl : this.apiUrl;
    editApiUrl = this.props.editApiUrl ? this.props.editApiUrl : this.apiUrl;
    deleteApiUrl = this.props.deleteApiUrl ? this.props.deleteApiUrl : this.apiUrl;

    apiPath = this.props.apiPath;
    accessApiPath = this.props.accessApiPath ? this.props.accessApiPath : this.apiPath;
    showApiPath = this.props.showApiPath ? this.props.showApiPath : this.apiPath;
    createApiPath = this.props.createApiPath ? this.props.createApiPath : this.apiPath;
    editApiPath = this.props.editApiPath ? this.props.editApiPath : this.apiPath;
    deleteApiPath = this.props.deleteApiPath ? this.props.deleteApiPath : this.apiPath;

    tableHead = !this.props.enablePublishedQuickToggle ? this.props.tableHead : [
        ...this.props.tableHead,
        {
            field: "published",
            headerName: AppContext.r["published"],
            sortable: false,
            renderCell: params => (
                <div className="td-v-center">
                    <ActionButton selected={params.row.published}
                        action={async () => {
                            await this.togglePublished(params.row);
                        }
                        }>{params.row.published ? AppContext.r["yes"] : AppContext.r["no"]}</ActionButton>
                </div>)
        }
    ];

    async componentDidMount() {
        if (this.props.id === "insert") {
            this.onInsert();
        } else if (this.props.id) {
            await this.fetchRecord(this.props.id);
        } else {
            await this.fetchRecords(this.state.store);
        }
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.lastRecordsRefresh !== this.props.lastRecordsRefresh) {
            // Trick to fetch the records
            await this.fetchRecords();

            this.setState({
                lastRecordsRefresh: nextProps.lastRecordsRefresh
            })
        }
    }

    fetchRecords = async (store, pushPath = false, newFilters = {}) => {
        const url = this.accessApiUrl + this.accessApiPath;

        const _defaultFilters = {
            page: 1,
            page_size: this.state.pageSize,
        };

        const updatedFilters = Object.assign(
            {},
            _defaultFilters,
            this.state.filters,
            newFilters
        );

        const urlWithParams = url + `${url.indexOf("?") > 0 ? '&' : '?'}${queryString.stringify(updatedFilters)}`;

        const eResponse = await CoreApi.fetchAsync(urlWithParams, null, true);
        console.log(urlWithParams)
        if (eResponse && eResponse.status === 200) {
            const data = (eResponse.data.data) ? eResponse.data.data : eResponse.data;
            console.log(eResponse, eResponse.data.records_count)
            this.setState({
                records: data,
                item: null,
                page: data.current_page ? data.current_page - 1 : 0,
                rowCount: eResponse.data.records_count
            });

            if (pushPath)
                this.props.navigate({ to: this.path })
            // AppContext.history.push(this.path, data);
        }
    }

    fetchRecord = async (id, pushPath = false) => {
        const url = this.apiUrl + this.showApiPath + "/" + id;
        const eResponse = await CoreApi.fetchItemAsync(url);

        if (eResponse && eResponse.status === 200) {
            const { data } = eResponse.data.data ? eResponse.data : eResponse;

            if (data["published"] === 1)
                data["published"] = true;

            this.selectedItem = data;
            this.mask = this.generateView();

            if (this.props.onFetchedItem)
                this.props.onFetchedItem(data);

            this.setState({
                records: null,
                item: data
            });

            if (pushPath)
                this.props.navigate({ to: "/" + this.state.store.subdomain + this.props.apiPath + "/" + data.id })
        }
    }

    onTogglePublished = async (o) => {
        const item = {
            id: o.id,
            published: !o.published ? 1 : 0
        };

        return await this.update(item);
    }

    togglePublished = async (o) => {
        const responseObj = await this.onTogglePublished(o);

        if (responseObj) {
            const records = [...this.state.records];

            for (let item of records) {
                if (item && item.id === responseObj.id) {
                    item.published = responseObj.published;
                }
            }

            this.setState({
                records: records
            });
        }
    }

    insert = async (o) => {
        if (this.props.insertDataAdapter)
            o = this.props.insertDataAdapter(o);

        const response = await createItemRequest(this.createApiUrl + this.createApiPath, o);

        if (response && response.data) {
            const { data } = response.data;

            await this.fetchRecord(data.id, true);
        }
    }

    update = async (o) => {
        if (this.props.updateDataAdapter)
            o = this.props.updateDataAdapter(o);

        if (o.image_name === null || o.image_name === undefined)
            delete o["image"];

        await updateItemRequest(this.editApiUrl + this.editApiPath + "/" + o.id, o);
        await this.fetchRecords(this.state.store, true);
    }

    delete = async () => {
        if (this.state.item !== null) {
            const url = this.deleteApiUrl + this.deleteApiPath + "/" + this.state.item.id;
            const response = await deleteItemRequest(url);

            if (this.props.onDeleteItem) this.props.onDeleteItem(response.response ? response.response : response);

            this.props.modal.hide();
            this.goBack();
        }
    }

    confirmDelete = async () => {
        this.props.modal.confirm(AppContext.r["delete"], AppContext.r["confirm-delete"],
            async () => await this.delete());
    }

    generateView = () => {
        const { disableEdit } = this.props;

        const view = (
            <Formik
                enableReinitialize={true}
                initialValues={this.selectedItem}
                validationSchema={this.props.schema}
                onSubmit={async (values) => {
                    console.log("SUBMIT", values);
                    // Data adapter: remove image field when not specified as a new value
                    // if(values.image && typeof values.image !== "string" && values.image !== -1)
                    //     delete values.image;

                    if (values.id)
                        this.update(values);
                    else
                        this.insert(values);
                }}>

                {({ handleSubmit, handleReset, handleChange, values, touched, errors, setFieldValue, isSubmitting, submitForm }) => (
                    <Form className={this.props.viewClassName}
                        onSubmit={handleSubmit} onReset={handleReset}>

                        {this.props.view({
                            handleSubmit: handleSubmit,
                            handleChange: handleChange,
                            setFieldValue: setFieldValue,
                            values: values,
                            touched: touched,
                            errors: errors
                        })}

                        <div className="module-footer">
                            <Box margin={1}>
                                <Button onClick={this.goBack}>
                                    {AppContext.r["back"]}
                                </Button>
                            </Box>

                            <div className="right">
                                {(!this.props.disableDelete && this.selectedItem.id) &&
                                    <Box margin={1}>
                                        <Button variant="outlined" className="danger-button"
                                            onClick={this.confirmDelete}>
                                            {AppContext.r["delete"]}
                                        </Button>
                                    </Box>}

                                {!disableEdit &&
                                    <Box margin={1} className="submit-box">
                                        <Button variant="outlined" endIcon={<SaveIcon />}
                                            disabled={isSubmitting} className="success-button"
                                            onClick={submitForm}>{this.selectedItem.id ? AppContext.r["save"] : AppContext.r["create"]}</Button>
                                    </Box>}
                            </div>
                        </div>

                        {isSubmitting && <LinearProgress />}
                    </Form>
                )}
            </Formik>
        );

        return view;
    }

    goBack = () => {
        this.fetchRecords(this.state.store, true);

        this.props.navigate({ to: this.path });

        // AppContext.history.push(this.path);
    }

    onRowClick = async (o) => {
        this.setState({
            isLoading: true
        });

        await this.fetchRecord(o.id, true);

        this.setState({
            isLoading: false
        });
    }

    onInsert = () => {
        const data = { ...this.props.initialValues };

        this.selectedItem = data;

        this.mask = this.generateView();

        this.setState({
            records: null,
            item: data
        });

        if (this.props.onInsertItem)
            this.props.onInsertItem(data);

        this.props.navigate({ to: "/" + this.state.store.subdomain + this.props.apiPath + "/insert" });
    }

    render() {
        const { records, item, store, isLoading } = this.state;
        const { disableView, disableInsert } = this.props;

        let view;

        // Detect logout
        const user = useUderlyStore.getState().user;

        if (!user) {
            view = <MustBeLogged {...this.props} />;
        } else if (isLoading) {
            view = AppContext.r["preloader"]
        } else {
            view = <>
                {(!records && !item) && AppContext.r["preloader"]}

                {(records && !item) &&
                    <QuickModule
                        columns={this.tableHead}
                        records={records}
                        onSelected={disableView ? null : this.onRowClick}
                        disableView
                        className={`${this.props.paginated && 'paginated'}`}
                        noItemsMessage={this.props.noItemsMessage}

                        dataGridProps={{
                            initialState: {
                                pagination: {
                                    paginationModel: {
                                        pageSize: this.state.pageSize,
                                        page: this.state.page,
                                    },
                                },
                            },
                            pageSizeOptions: _defaultSettings.pageSizeOptions,
                            disableRowSelectionOnClick: true,
                            pagination: true,
                            paginationMode: "server",
                            rowCount: this.state.rowCount,
                            onPaginationModelChange: (model, details) => {
                                console.log(model)
                                this.setState({
                                    pageSize: model.pageSize,
                                });

                                this.fetchRecords(null, true, {
                                    page: model.page + 1,
                                    page_size: model.pageSize,
                                });
                            }
                        }} />}

                {(!records && item) && <div className="view">{this.mask}</div>}
            </>;
        }

        return <>
            {(!item && !disableInsert) &&
                <Button variant="outlined" className="insert-button success-button"
                    onClick={this.onInsert}>
                    {AppContext.r["create"]}
                </Button>}

            <StoreBreadcrumbs store={store} item={item}
                path={this.props.path} friendlyPath={this.props.friendlyPath}
                onClick={async () => this.fetchRecords(store)} />

            {view}
        </>;
    }
}

// const mapStateToProps = state => ({
//     layout: state.layout,
//     auth: state.auth
// });

// const mapDispatchToProps = () => ({ });

// export default connect(mapStateToProps, mapDispatchToProps())(withRouter(Module));
export default (withRouter(Module));