import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GridColDef } from "@mui/x-data-grid";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { format } from "date-fns";
import { t } from "i18next";

import { DataLoader, QuickModule, Store, createItemRequest, isMobile } from "@uderly/react-uderly-ui";
import { useUderlyStore } from '@uderly/react-uderly-ui/zustand';
import IqSpaceApi from '../../../api/IqSpace';
import { IqEvent } from '../../../models/Event';
import Settings from '../../../app/settings';
import AppContext from '../../AppContext';
import Separator from "../Separator";
import ActionButton from '../../../framework/ActionButton';
import QuizConsole from '../QuizConsole';
import { hasPermission } from "../../utils";
import PlaylistsSelect from "../PlaylistsSelect";

export interface QuizWidgetProps {
  store: Store;
  overlay: any;
}

const QuizWidget = ({ store, overlay }: QuizWidgetProps) => {
  const [columns, setColumns] = useState<GridColDef[] | undefined>();
  const [event, setEvent] = useState<IqEvent | undefined>();
  const [events, setEvents] = useState<IqEvent[] | undefined>();
  // const [playlistId, setPlaylistId] = useState<number | undefined>(undefined);
  const playlistId = useRef<number | undefined>(undefined);

  const user = useUderlyStore.getState().user;

  const fetchEvents = async () => {
    const response = await IqSpaceApi.GetStoreEventsToday(store.id);

    if (response && response.status === 200) {
      const filteredEvents = response.data.data.filter(e => !e.games[0] || !e.games[0].expired);
      setEvents(filteredEvents);

      // Search for active games and dislay the first one if any
      const startedEvents = response.data.data.filter(e => e.games[0] && !e.games[0].expired && !e.games[0].completed);

      if (startedEvents[0])
        setEvent(startedEvents[0]);
    }
  }

  useEffect(() => {
    const init = async () => {
      const columns: GridColDef[] = [{
        field: 'title',
        headerName: t('title') as string,
        minWidth: 180,
        flex: 1,
        renderCell: params => {
          const { row } = params;
          const date = new Date(params.row.date_time.substr(0, 19));

          return (
            <div className="td-v-center">
              <div className="row-thumb">
                {params.row.media && params.row.media[0] && <>
                  <img src={Settings.apiUrl + "/events/" + params.row.id + "/image/thumb"} alt="Thumb" />
                </>}
                {(!row.media || !row.media[0]) && <img src={AppContext.s["placeholder"]} className="placeholder-thumb" alt="Thumb" />}
              </div>

              <span>{params.row.title} ({format(date, "H:mm")})</span>
            </div>
          );
        }
      }];

      if (!isMobile()) {
        columns.push({
          field: 'status',
          headerName: t('status') as string,
          maxWidth: 100,
          renderCell: params => {
            const game = params.row.games[0];

            if (!game)
              return null;

            return (
              <div className="td-v-center">
                {game.expired && <span className="expired">{t('expired')}</span>}
                {!game.expired && game.completed && <span className="completed">{t('completed')}</span>}
              </div>);
          }
        });

        columns.push({
          field: 'price',
          headerName: t('price') as string,
          maxWidth: 60,
          renderCell: params => {
            if (isMobile()) return null;

            const p = store.locale.currency_symbol + " " + params.row.price.toFixed(2);

            return (
              <div className="td-v-center">
                {params.row.is_free ? t('free') : p}
              </div>);
          }
        });
      }

      columns.push({
        field: 'action',
        headerName: '',
        minWidth: isMobile() ? 150 : 230,
        renderCell: params => {
          return <>
            <div className="td-v-center">
              {!params.row.games}
              <ActionButton className="start-game outline-icon"
                action={async () => await startGame(params.row, false)}>
                <PlayCircleOutlineIcon />&nbsp;
                <div className="mobile-hidden">{t("start-game")}</div>
                <div className="mobile-only">START</div>
              </ActionButton>
            </div>
          </>;
        }
      });

      if (hasPermission(user, "game_automation")) {
        columns.push({
          field: 'action2',
          headerName: '',
          minWidth: isMobile() ? 150 : 260,
          renderCell: params => {
            return <>
              <div className="td-v-center">
                <PlaylistsSelect compactMode onPlaylistChanged={(e, id) => { console.log(`SELECTED playlist ${id}`); playlistId.current = id; }} />

                <ActionButton className="start-game outline-icon"
                  action={async () => await startGame(params.row, true)}>
                  <PhotoCameraIcon />
                </ActionButton>
              </div>
            </>;
          }
        });
      }

      setColumns(columns);

      fetchEvents();
    }

    init();
  }, []);

  const startNewGame = async () => {
    const date = new Date();

    const event = {
      title: "IQ Space " + format(date, "d/MM/Y - H:mm"),
      date_time: format(date, "Y-MM-dd HH:mm:00"),
      price: 0,
      published: true,
      store_id: store.id,
      is_free: true,
      timezone: store.timezone
    };

    const url = Settings.apiUrl + "/events";

    const response = await createItemRequest(url, event);

    if (response && response.data.data) {
      const event = response.data.data;
      const detailedEventResponse = await IqSpaceApi.Event(event.id);

      if (detailedEventResponse && detailedEventResponse.data.data) {
        const detailedEvent = detailedEventResponse.data.data;

        // Actually start the game
        await IqSpaceApi.StartGame(event.id);

        // Retrieve event details
        setEvent(detailedEvent);
      }
    }
  }

  const startGame = async (event: IqEvent, isRecording: boolean) => {
    // Retrieve detailed information about the event
    const response = await IqSpaceApi.Event(event.id);

    // Start the game
    const params = isRecording ? {
      enablePartialRanking: false,
      enableCategory: false,
      playlistId: playlistId.current
    } : {};

    console.log("Request startgame", params)
    const responseStartGame = await IqSpaceApi.StartGame(event.id, params);
    console.log(responseStartGame)

    if (responseStartGame && responseStartGame.status === 200) {
      // Select the event
      setEvent(response.data.data);
    }
  }

  return <>
    {!events && <DataLoader />}

    <div className="quiz-widget">
      {events && <>
        {!event &&
          <>
            <h2>{t("events-today")}</h2>
            <Separator />
          </>}

        {event && <>
          <QuizConsole event={event} overlay={overlay}
            onGameDeleted={async () => {
              setEvent(undefined);
              await fetchEvents();
            }} />
        </>}

        {!event && events.length > 0 && columns &&
          <QuickModule
            columns={columns}
            records={events}
            disableView />}

        {!event && events.length === 0 &&
          <div className="no-events">
            <p>{t("no-events-today")}</p>

            <ActionButton className="start-game outline-icon" asDiv
              action={startNewGame}>
              <PlayCircleOutlineIcon />&nbsp;{t("start-new-game")}
            </ActionButton>

            <p className='text-center'>{t("or").toLowerCase()} <Link to={"/" + store.subdomain + "/events/insert"}>{t("plan-an-event")}</Link>.</p>
          </div>
        }
      </>}
    </div>
  </>;
}

export default QuizWidget;