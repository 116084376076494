import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';

import Settings from '../../settingsOld';

export default function loadClientResources (resources) {
    resources["home"] = "Home";
    resources["about-us"] = "About Us";
    resources["mission"] = "Mission";
    resources["technologies"] = "Technologies";
    resources["friends"] = "Friends";
    resources["board"] = "Board";
    resources["contact"] = "Contact";
    resources["help-and-support"] = "Help & Support";
    resources["cms"] = "CMS";
    resources["content-management-systems"] = "Content Management Systems";
    resources["innovation"] = "Innovation";
    resources["gaming"] = "Gaming";
    resources["itservices"] = "IT Services";
    resources["creative-solutions"] = "Creative Solutions";
    resources["designer-websites"] = "Designer Websites";
    resources["who-we-are"] = "Who we are";
    resources["work-with-us"] = "Work with us";
    resources["learn-more"] = "Learn More";
    resources["tell-your-idea"] = "Tell us about your idea and we will get back to you.";
    resources["socials-tagline"] = "Providing innovative services and solutions that will improve your overall image and performance.";
    resources["connect-with-us-on"] = "Connect with us on";
    resources["ready-to-start"] = "Ready to start?";
    resources["members"] = "Members";
    resources["invoices"] = "Invoices";
    resources["not-affiliated"] = <>It does <b>not</b> look like you are affiliated with us or any of our partners (yet).</>;
    resources["view"] = "View";
    resources["paid"] = "Paid";
    resources["not-paid"] = "Not Paid";
    resources["manage-your-memberships"] = "Manage your memberships";

    resources["home-iqspace-desc"] = <>
        <p>IQ Space is the ultimate live quiz game for your entertainment. </p>
        <p>Play with your friends or colleagues and host your game for free. </p>
        <p>Enjoy the power of a <b>real time</b> technology using your smartphone only!  </p>
    </>;
    resources["home-playwithfriends-desc"] = <>
        <h3>Play with friends</h3>
        <p>Play IQ Space with your friends whenever and wherever you like to!</p>
        <p>IQSpace is <b>free to use</b>, you’re going to need your smartphone only!</p>
        <p><b>Challenge your friends</b> with tons of different questions and trivia quizzes.</p>
    </>;
    resources["home-fun-desc"] = <>
        <h3>Looking for some extra fun?</h3>
        <p>You can sell your tickets to the events and set prizes to challenge your players!</p>

        <div className="tickets-col">
            <h5>Monetize from your events</h5>

            <div className="check-list">
                <div className="item">
                    <CheckIcon />
                    <p>Give them a price! You decide how much your events are worth (min £ 0.50).</p>
                </div>

                <div className="item">
                    <CheckIcon />
                    <p>Up to <strong>90 players</strong>.<br /><small>Do you need more? Let's have a chat!</small></p>
                </div>

                <div className="item">
                    <CheckIcon />
                    <p>No contract. No ties. No hassle.</p>
                </div>

                <div className="item">
                    <CheckIcon />
                    <p>You just pay a card transaction for tickets you sell.</p>
                </div>
{/* 
                <div className="item">
                    <CheckIcon />
                    <p>You just pay a <b>{Settings["pricing"].perc}% + {Settings["pricing"].fixed} £</b> card transaction for tickets you sell.</p>
                </div> */}

                <div className="item">
                    <CheckIcon />
                </div>

                <div className="item">
                    <CheckIcon />
                    <p>Your players will pay a booking fee for every ticket they purchase.</p>
                </div>

                <div className="item">
                    <CheckIcon />
                    <Link to="pricing">Pricing</Link>
                </div>
{/* 
                <div className="item">
                    <CheckIcon />
                    <p>Your players will pay a booking fee<br/>starting from £ {Settings["pricing"].bookingStartsFrom} for every ticket they purchase.</p>
                </div> */}
            </div>
        </div>
    </>;
    resources["home-business-desc"] = <>
        <p>Need a boost for your business?</p>
        <p>Are you looking for something fun to create more team building in your company?</p>
        <p>IQ Space is the live quiz to make it possible.</p>
    </>;

    resources["become-quizmaster"] = "Become a quizmaster";

    resources["iqspace-tagline-0"] = "Let's get together";
    resources["iqspace-tagline-1"] = "The ultimate smartphone quiz for your entertainment.";
    resources["iqspace-tagline-2"] = "The new live quiz that will skyrocket your entertainment business. 🚀";

    resources["start-now"] = "Start Now";
    resources["play-now"] = "Play Now";
    resources["play"] = "Play";
    resources["start-now-for-free"] = "Start now for free";

    resources["iqspace-features"] = [{
        title: "Play with your friends",
        desc: "Try now IQ Space now for free and create your own virtual room: have fun answering tons of quizzes from your phone!",
        icon: "/assets/images/carousel/play-with-friends-iq-space.png",
        to: ""
    },{
        title: "Chat",
        desc: "Chat, Chat Chat! Social and instant interaction will bring to life your virtual moments.",
        icon: "/assets/images/carousel/chat-friends-game-iq-space.png",
        to: ""
    },{
        title: "Team building",
        desc: "The ultimate team-bulding tool. Get your team together, let them chat, enjoy and feel the competition. Connect people through the web.",
        icon: "/assets/images/carousel/competition-iq-space.png",
        to: ""
    },
    // {
    //     title: "Live stream with Zoom",
    //     desc: "Zoom embedded \"in-app\" for real-time face-to-face engagement with your players.",
    //     icon: "/assets/images/carousel/zoom-embedded-app-iq-space.png",
    //     to: ""
    // },
    {
        title: "Tickets",
        desc: "Monetize from your events, selling tickets directly from the IQ Space ticketing system.",
        icon: "/assets/images/carousel/ticket-sell-iq-space.png",
        to: ""
    },{
        title: "Promote your business",
        desc: "The ultimate customizable promotional tool. Play custom multimedia content to question. Promote and inform about your business.",
        icon: "/assets/images/carousel/promote-your-business-iq-space.png",
        to: ""
    }];
    resources['prices-exclude-vat'] = "All prices exclude VAT.";
}