import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import Settings from '../app/settings';
import { GameSlice, createGameSlice } from './gameSlice';

export type CombinedSlices = GameSlice;

export const useIqSpaceStore = create<CombinedSlices, [["zustand/persist", CombinedSlices]]>(
  persist(
    (...a) => ({
      ...createGameSlice(...a),
    }),
    { name: `${Settings.project.id}-game` }
  )
);
