// withNotification v1.1

import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function withNotification(OriginalComponent) {
    return class extends React.Component {
        state = {
            className: "",
            notification: null
        }

        show = (message, title = null, severity = "success", duration = 6000) => {
            const notification = {
                severity: severity,
                message: message,
                title: title,
                duration: duration
            };

            this.setState({
                notification: notification
            });
        }

        hide = () => {
            this.setState({
                notification: null
            });
        }

        handleCloseNotification = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            this.setState({
                notification: null
            })
        };

        render() {
            const { notification } = this.state;

            const newProps = {
                notification: {
                    show: this.show,
                    hide: this.hide
                }
            }

            return (
                <>
                    <OriginalComponent {...this.props} {...newProps} />
 
                    { notification && 
                        <Snackbar className={this.state.className} open={notification} autoHideDuration={notification.duration} 
                            onClose={this.handleCloseNotification}>
                            <Alert onClose={this.handleCloseNotification} severity={notification.severity}>
                                {notification.title && 
                                    <AlertTitle>{notification.title}</AlertTitle>}
                                {notification.message}
                            </Alert>
                        </Snackbar> }
                </>);
        }
    }
}