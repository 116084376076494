import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import { useUderlyStore } from '@uderly/react-uderly-ui/zustand';
import AppContext from "app/AppContext";
import IqSpaceApi from 'api/IqSpace';
import { hideFooter, showFooter } from '../../redux/layoutSlice';
import { withModal } from '../../framework/withModal';
import withOverlay from '../../framework/withOverlay';
import StoreWidget from "../components/StoreWidget";
import QuizWidget from "../components/QuizPanel/QuizWidget";
import Events from "../components/Events";
import StoreToolBar from "../components/QuizPanel/StoreToolBar";
import withUderlyStore from '../../zustand/withUderlyStore';

class Store extends React.Component {
    state = {
        store: null,
        viewAsOwner: false,
        upcomingEvents: null,
        pastEvents: null,
        error: null,
        tabValue: 0,
        user: this.props.zustand.user
    };

    async fetchStoreData(user, entertainer) {
        const response = await IqSpaceApi.Store(entertainer);

        const store = (response && response.status && response.data) ? response.data.data : null;
        let upcomingEvents = null, pastEvents = null, viewAsOwner = false;

        if (store) {
            // Retrieve upcoming events
            const r0 = await IqSpaceApi.Events(store.id, 1);

            console.log(r0);

            if (r0 && r0.status === 200)
                upcomingEvents = r0.data.data;

            // Retrieve past events
            const r1 = await IqSpaceApi.Events(store.id, -1);

            if (r1 && r1.status === 200)
                pastEvents = r1.data.data;

            if (user && user.stores && user.stores.filter(s => s.id === store.id).length > 0) {
                viewAsOwner = true;
                this.props.hideFooter();
            } else {
                this.props.showFooter();
            }
        } else {
            this.setState({
                error: response.status
            })
        }

        this.setState({
            store: store,
            upcomingEvents: upcomingEvents,
            pastEvents: pastEvents,
            viewAsOwner: viewAsOwner
        });
    }

    async componentDidMount() {
        // Start with hidden footer
        this.props.hideFooter();

        if (this.props.updateLayout)
            this.props.updateLayout();

        window.addEventListener("resize", this.onResize.bind(this));

        this.onResize();

        await this.fetchStoreData(this.state.user, this.props.entertainer);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize.bind(this));
    }

    onResize() {
        this.setState({
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight
        });
    }

    async componentWillReceiveProps(nextProps) {
        const { store } = this.state;

        if (nextProps.zustand.user !== this.state.user) {
            this.setState({
                user: nextProps.zustand.user
            });
        }

        if (store && nextProps.entertainer !== store.domain) {
            await this.fetchStoreData(nextProps.zustand.user/*nextProps.auth.user*/, nextProps.entertainer);
        }

        // if (nextProps.auth.user !== this.props.auth.user) {
        //     await this.fetchStoreData(nextProps.auth.user, nextProps.entertainer);
        // }
    }

    menuCard = (menu) => {
        return (
            <Button component={Link} to={menu.to} variant="outlined" startIcon={menu.icon}
                key={"card-" + menu.title}>
                {menu.title}
            </Button>
        );
    }

    render() {
        const { error, store, viewAsOwner } = this.state;
        const user = useUderlyStore.getState() ? useUderlyStore.getState().user : null;

        if (error)
            return <div className="store-not-found">
                <p>{AppContext.r["not-found"]}</p>
            </div>;

        if (!store)
            return <div className="page iq-dashboard loading">{AppContext.r["preloader"]}</div>;

        return (
            <div className={"page iq-dashboard " + (viewAsOwner ? "as-owner" : "as-viewer")}
                style={{ height: this.state.innerHeight }}>
                <div className="header">
                    {!viewAsOwner && <StoreWidget store={store} viewAsOwner={false} authActions={this.props.authActions} />}
                    {viewAsOwner && <StoreToolBar store={store} user={user} />}
                </div>

                {viewAsOwner &&
                    <div className="full-height">
                        <QuizWidget store={store} overlay={this.props.overlay} />
                    </div>}

                {!viewAsOwner &&
                    <Events
                        store={store}
                        upcomingEvents={this.state.upcomingEvents} pastEvents={this.state.pastEvents} authActions={this.props.authActions} />}

                {viewAsOwner &&
                    <div className="footer">
                        {new Date().getFullYear()} © <a href="https://uderly.com" target="_blank" rel="noopener noreferrer">Uderly</a>
                    </div>}
            </div>);
    }
}

const mapStateToProps = state => ({
    layout: state.layout,
});

const mapDispatchToProps = () => ({
    hideFooter,
    showFooter
});

export default connect(mapStateToProps, mapDispatchToProps())(withModal(withOverlay(withUderlyStore(Store))));